import React, { useState, useEffect } from "react";
import { Container, TextField, Button, Paper} from "@mui/material";
import Axios from "axios";
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { getBaseURL } from "../../baseURL";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSelector } from "react-redux";
import CheckIcon from '@mui/icons-material/Check';
import {green, red} from '@mui/material/colors';
import Fab from '@mui/material/Fab';
import ClearIcon from '@mui/icons-material/Clear';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorIcon from '@mui/icons-material/Error';
import { selectRowOnContextOpen } from "@syncfusion/ej2-react-grids";
import { EmailTemplate } from "../miscellaneous/EmailTemplate";
import { EmailFiles } from "../miscellaneous/EmailFiles";

export const ManageScanRequests = () => {
    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(3, 2),
        },
        paperSpacing: {
            paddingTop: "30px",
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -5,
            marginLeft: -12,
          },
          wrapper: {
            margin: theme.spacing(1),
            position: 'relative',
          },
          buttonGreen: {
            backgroundColor: green[500],
            '&:hover': {
              backgroundColor: green[700],
            },
          },
          buttonRed: {
            backgroundColor: red[500],
            '&:hover': {
              backgroundColor: red[700],
            },
          },
          appBar: {
            position: 'relative'
        },
    }));

    const classes = useStyles();
    const currentUser = useSelector(state => state.auth);
    const config = {
        headers: {'Authorization': "Bearer " + currentUser.jwtIdToken.idToken}
    };

    const[isAdmin, setIsAdmin] = useState(null);
    const [scanRequests, setScanRequests] = useState([]);
    const [checkedRequests, setCheckedRequests] = useState(false);
    //user to whom email will be sent to
    const [email, setEmail] = useState([]);
    //the email message
    const [editor, setEditor] = useState();
    const [sent, setSent] = useState(false);
    const [open, setOpen] = useState(false);
    const [requestID, setRequestID] = useState();
    const [userEmail, setUserEmail] = useState("");
    const [drawings, setDrawings] = useState([]);
    const [drg, setdrg] = useState([]);
    const [emailError, setEmailError] = useState({ error: false, text: "" });
    const [sendError, setSendError] = useState({ error: false, text: "" });
    //subject of the email
    const [subjectApproved, setSubjectApproved] = useState("SCAN REQUEST HAS BEEN ACCEPTED");
 
    //added by bh

    let userId;

    const handleOpen = (ID, drawingNumber) =>{        
        var request = [...scanRequests];
        var info = request.find(x => x.id === ID);
        setUserEmail(info.user.email);
        console.log(info);
        setRequestID(ID);
        setOpen(true);
        //setDrawings(drawingNumber);
        setDrawings(info.drawingNumber);

    }
    
//end added by bh

    useEffect(() => { 
        if(scanRequests.length < 1 && !checkedRequests) {
            Axios.get(`${getBaseURL()}/admin/scanrequests`, config)
            .then((response) => {
                var requests = [...response.data];
                requests.forEach(x => x.submitted = {Submitted: false, Error: false, Message: ""});
                setScanRequests(requests);
                setCheckedRequests(true);
            })
            .catch((error) => {
                // setLoading(false);
                // setSendError({error: true, text: error.response.data.message})
            });
        }

        if(isAdmin === null) {
            try {
                if(currentUser.user.permissions.find(p => p.permissionName === "System Admin").value === true) {
                    setIsAdmin(true);
                }
                else {
                    setIsAdmin(false);
                }
            }
            catch(error) {
                setIsAdmin(false);
            }
    
            if(!isAdmin) {
                try {
                    if(currentUser.user.permissions.find(p => p.permissionName === "Scan Admin").value === true) {
                        setIsAdmin(true);
                    }
                    else {
                        setIsAdmin(false);
                    }
                }
                catch(error) {
                    setIsAdmin(false);
                }
            }
        }
    }, []);

    const onApprove = (userEmail, drawingNumber) => {
        console.log(drawingNumber);
        var toEmails = [userEmail];
        var isError = false;

        if (!isError) { 
            
            //setLoading(true);
            setEmailError({ error: false, text: "" });
            setSent(false);
            setSendError({ error: false, text: "" });
            //drawings=useState([]);
            const Message = {
                toEmails: toEmails,
                Email: userEmail,
                CustomMessage: `Your scan request for ${drawingNumber} has been accepted.`,
                Subject: subjectApproved,
                CurrentUserEmail: currentUser.user.email,
                Drawings: drg,
            }
            console.log(Message);
            Axios.post(`${getBaseURL()}/admin/managescanrequests/emailUser`, Message, config)
                .then((response) => { 
                    setSent(true);
                    //setLoading(false);
                    
                })
                .catch((error) => { 
                    setSendError({ error: true, text: "Error while sending emails!" });
                    console.log("the Error is: " + error);
                    // if(error.response != undefined)
                    //     setSendError({ error: true, text: error.response.data.message })
                });
                
        }
    }
    
    const approveDeny = (ID, approve) => {
        
        const request = {
            id: ID,
            completed: approve,
        }

        var requests = [...scanRequests];

        if(approve) {
            requests.find(x => x.id === ID).SendingApprove = true;
        }
        else {
            requests.find(x => x.id === ID).SendingReject = true;            
        }
                
        setScanRequests(requests);

        Axios.post(`${getBaseURL()}/admin/scanrequests/update`, request, config)
        .then((response) => {
            requests = [...scanRequests];
            var message = "";
            if(approve) {
                message = "APPROVED"
            }
            else {
                message = "IGNORED"
            }
            requests.find(x => x.id === ID).submitted = {Submitted: true, Error: false, Message: message}
            requests.find(x => x.id === ID).SendingApprove = false;
            requests.find(x => x.id === ID).SendingReject = false;

            setScanRequests(requests);
        })
    }

    return (
        <Container maxWidth={false} disableGutters={true}>
            {isAdmin ?
                <Container>
                    <Typography variant="h4" gutterBottom>Manage Scan Requests</Typography>
                    {scanRequests.length > 0 ?
                        <Paper className={classes.root}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>User</TableCell>
                                        <TableCell>Drawing</TableCell>
                                        <TableCell>Request Date</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {scanRequests.map((item, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>{item.user.email}</TableCell>
                                                <TableCell>{item.drawingNumber}</TableCell>
                                                <TableCell>{new Date(item.requestDate).toLocaleDateString()}</TableCell>
                                                <TableCell>
                                                    {!item.submitted.Submitted ?
                                                        <span>
                                                            <span className={classes.wrapper}>
                                                                { <Button
                                                                    id="btnIgnore"
                                                                    color="primary"
                                                                    onClick={() => handleOpen(item.id, item.drawings)}
                                                                    disabled={item.SendingReject || item.SendingApprove}
                                                                >
                                                                    Ignore
                                                                </Button> } 
                                                                {item.SendingReject && <CircularProgress size={24} className={classes.buttonProgress} />}
                                                                <EmailTemplate open={open} setOpen={setOpen} setDrawings={drawings} onClick={approveDeny} toUser = {userEmail} ID = {requestID}/>

                                                            </span>
                                                            
                                                            
                                                            <span className={classes.wrapper}>
                                                                <Button
                                                                    id="btnComplete"
                                                                    color="primary"
                                                                    variant="contained"
                                                                    onClick={() => {approveDeny(item.id, true); onApprove(item.user.email, item.drawingNumber)}}
                                                                    disabled={item.SendingReject || item.SendingApprove}
                                                                >
                                                                    Complete
                                                                </Button>
                                                                {item.SendingApprove && <CircularProgress size={24} className={classes.buttonProgress} />}
                                                            </span>
                                                        </span>
                                                        : <Typography variant="button">{item.submitted.Message}</Typography>}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Paper>
                        :
                        <Paper className={classes.root}>
                            <Typography variant="h6">
                                No Requests Found
                            </Typography>
                        </Paper>
                    }
                </Container>
                :
                <Paper className={classes.root}>
                    <Typography variant="h6">
                        <ErrorIcon />&nbsp;Access Denied
                    </Typography>
                </Paper>
             } 
        </Container>
    )
}
import React, { useState } from "react";
import { Container, TextField, Button, Icon } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import {green, red} from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper'
import { useSelector } from "react-redux";
import Axios from "axios";
import { getBaseURL } from "../../baseURL";
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

export const BOMAccessRequest = () => {
    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(2, 2),
        },
        paperSpacing: {
            paddingTop: "30px",
        },
        wrapper: {
            position: 'relative',
        },
        buttonSuccess: {
            backgroundColor: green[500],
            '&:hover': {
            backgroundColor: green[700],
            },
        },
        buttonError: {
            backgroundColor: red[500],
            '&:hover': {
                backgroundColor: red[700],
            },
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -5,
            marginLeft: -12,
        },
        paperSpacing: {
            paddingTop: "30px",
        },
        helperText: {
            marginLeft: 0,
        },
        }));
    
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [submitError, setSubmitError] = useState({Error: false, Text: ""});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const currentUser = useSelector(state => state.auth);
    const config = {
        headers: {'Authorization': "Bearer " + currentUser.jwtIdToken.idToken}
    };

    const onSubmit = () => {
        Axios.get(`${getBaseURL()}/users/bomaccessrequest/new`, config)
        .then(response => {
            setIsSubmitted(true);
        }) 
        .catch(error => {
            setSubmitError({Error: true, Text: error.response.data.message});
        })
    }

    return(
    <Container>
        <Typography variant="h4" gutterBottom>BOM Access Request</Typography>
        <Paper className={classes.root}>
            {!isSubmitted &&
                <span className={classes.wrapper}>
                    <Button
                        id="confirmBOMAccess"
                        color="primary"
                        variant="contained"
                        onClick={onSubmit}
                    >
                        Request BOM Access
                    </Button>
                    {submitError.Error &&
                        <Typography variant="subtitle1" style={{color: "red", marginTop: "10px"}}>{submitError.Text}</Typography>
                    }
                </span>
            }
            {isSubmitted &&
                <Typography variant="h6">
                    <CheckIcon />&nbsp;Your request has been submitted and a confirmation email has been sent
                </Typography>
            }
        </Paper>
    </Container>
    );
}
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import './material.css'
import { registerLicense } from '@syncfusion/ej2-base';
import { createRoot } from 'react-dom/client';
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from './authProvider';
import store from "./store";

registerLicense("ORg4AjUWIQA/Gnt2VVhhQlFac15JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkBjW31ec3dXR2VcU0E=");
export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((message) => {
    console.log("event", message.eventType);
    store.dispatch({ type: message.eventType, payload: message.payload });
});

console.log("index init");

ReactDOM.render(
    <React.StrictMode>
        <App pca={msalInstance} />
    </React.StrictMode>,
    document.getElementById('root')
);

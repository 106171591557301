import React, { useState, useEffect } from "react";
import { Container, TextField, Button, Icon, Snackbar, TablePagination } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import { green, red } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper'
import { useSelector } from "react-redux";
import Axios from "axios";
import { getBaseURL } from "../../baseURL";
import SendIcon from '@mui/icons-material/Send';
import SearchIcon from '@mui/icons-material/Search';
import MuiAlert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { TablePaginationActions } from '../../actions/TablePaginationActions';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import UpdateIcon from '@mui/icons-material/Update';
import ErrorIcon from '@mui/icons-material/Error';
export const ManageFaq = () => {
    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(2, 2),
        },
        paperSpacing: {
            paddingTop: "30px",
        },
        wrapper: {
            position: 'relative',
        },
        buttonSuccess: {
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[700],
            },
        },
        buttonError: {
            backgroundColor: red[500],
            '&:hover': {
                backgroundColor: red[700],
            },
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -5,
            marginLeft: -12,
        },
        paperSpacing: {
            paddingTop: "30px",
        },
        helperText: {
            marginLeft: 0,
        },
    }));

    const classes = useStyles();
    const [faq, setFaq] = useState([]);
    const [faqError, setFaqError] = useState({ Error: false, Text: "" });
    const [IsEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, faq.length - page * rowsPerPage);
    const [Question, setQuestion] = useState("");
    const [Answer, setAnswer] = useState("");
    const [FaqId, setFaqId] = useState("");
    const [AlertColor, setAlertColor] = useState("");
    const[isAdmin, setIsAdmin] = useState(null);
    const [questionError, setQuestionError] = useState({ Error: false, Text: "" });
    const [answerError, setAnswerError] = useState({ Error: false, Text: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submitError, setSubmitError] = useState({ Error: false, Text: "" });
    const [SnackBarOpen, setSnackBarOpen] = useState(false);
    const currentUser = useSelector(state => state.auth);
    const [searchText, setSearchText] = useState("");
    const config = {
        headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken }
    };
    const [open, setOpen] = React.useState(false);
    const [SnackBarText, setSnackBarText] = useState("");

    const buttonClassname = clsx({
        [classes.buttonSuccess]: submitted,
        [classes.buttonError]: submitError.Error,
    });
    useEffect(() => {         
        GetFaqData();
        
        if(isAdmin === null) {
            try {
                if(currentUser.user.permissions.find(p => p.permissionName === "System Admin").value === true) {
                    setIsAdmin(true);
                }
                else {
                    setIsAdmin(false);
                }
            }
            catch(error) {
                setIsAdmin(false);
            }
    
            if(!isAdmin) {
                try {
                    if(currentUser.user.permissions.find(p => p.permissionName === "User Admin").value === true) {
                        setIsAdmin(true);
                    }
                    else {
                        setIsAdmin(false);
                    }
                }
                catch(error) {
                    setIsAdmin(false);
                }
            }
        }
    }, []);
      
    const GetFaqData = () => {
        Axios.get(`${getBaseURL()}/faq/getFaqData`, config)
            .then(response => {
                setFaq(response.data);
                setPage(0);
            })
            .catch(error => {
                console.log(error)
            })
    }
    const onQuestionChange = event => {
        setQuestion(event.target.value);
        setSubmitted(false);
        setSubmitError({ Error: false, Text: "" });

        if (event.target.value) {
            setQuestionError({ Error: false, Text: "" });
        }
    }
    const onAnswerChange = event => {
        setAnswer(event.target.value);
        setSubmitted(false);
        setSubmitError({ Error: false, Text: "" });

        if (event.target.value) {
            setAnswerError({ Error: false, Text: "" });
        }
    }
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarOpen(false);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    const Alert = (props) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClickOpen = (id) => {
        setFaqId(id)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const getFaqDataById = id => {      
        Axios.get(`${getBaseURL()}/faq/GetFaqDataById?FaqId=${id}`, config)
            .then(response => {                
                if (response.data.message == "Ok") {
                    setFaqId(id)
                    setIsEdit(true)
                    setQuestion(response.data.faqData.question)
                    setAnswer(response.data.faqData.answer)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }
    const DeleteFaq = () => {
        const faqdata = {
            Id: FaqId
        }
        Axios.post(`${getBaseURL()}/faq/Delete`, faqdata, config)
            .then((response) => {
                if (response.data.message === "Deleted Successfully") {
                    const newFaqList = faq.filter((item) => item.id !== FaqId);
                    setFaq(newFaqList);
                    setPage(0);
                    setOpen(false);
                }
            })
            .catch((error) => {
                console.log(error)
            })

    }
    const onSearchTextChange = event => {
        setSearchText(event.target.value);

        if (searchText) {
            setFaqError({ Error: false, Text: "" });
        }
    }
    const onSubmit = () => {
        if (Question && Answer) {
            const Faqdata = {
                ID: FaqId,
                Question: Question,
                Answer: Answer
            }
            setIsLoading(true);
            if (IsEdit === true) {               
                
                Axios.post(`${getBaseURL()}/faq/Update`, Faqdata, config)
                    .then((response) => {
                        if (response.data.message === "Updated Successfully") {
                            GetFaqData();
                            setQuestion("");
                            setAnswer("");
                            setIsLoading(false);
                            setSnackBarText("FAQ updated successfully!");
                            setSnackBarOpen(true);
                            setAlertColor("success")                      
                          
                            setIsEdit(false)
                        } else {
                            setIsLoading(false);
                            setSnackBarText("Error Occurred Updating FAQ");
                            setSnackBarOpen(true);
                            setAlertColor("error")
                            setSubmitted(true);
                            setSubmitError({ Error: true, Text: "" })
                        }

                    })
                    .catch((error) => {
                        setIsLoading(false);
                        setSnackBarText("Error Occurred Updating FAQ");
                        setSnackBarOpen(true);
                        setAlertColor("error")
                        setSubmitted(true);
                        setSubmitError({ Error: true, Text: "" })
                    })
            } else {
                Axios.post(`${getBaseURL()}/faq/AddQuestionAndAnswer`, Faqdata, config)
                    .then((response) => {
                        if (response.data.message === "Added Successfully") {
                            GetFaqData();
                            setQuestion("");
                            setAnswer("");
                            setIsLoading(false);
                            setSnackBarText("FAQ updated successfully!");
                            setSnackBarOpen(true);
                            setAlertColor("success")
                          
                        } else {
                            setIsLoading(false);
                            setSnackBarText("Error Occurred Updating FAQ");
                            setSnackBarOpen(true);
                            setAlertColor("error")
                            setSubmitted(true);
                            setSubmitError({ Error: true, Text: "" })
                        }
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        setSnackBarText("Error Occurred Updating FAQ");
                        setSnackBarOpen(true);
                        setAlertColor("error")
                        setSubmitted(true);
                        setSubmitError({ Error: true, Text: "" })
                    })
            }

        }
        if (Question === "") {
            setIsLoading(false);
            setSubmitted(true);
            setSubmitError({ Error: true, Text: "" })
            setQuestionError({ Error: true, Text: "Please fill Question Field" });
        }
        if (Answer === "") {
            setIsLoading(false);
            setSubmitted(true);
            setSubmitError({ Error: true, Text: "" })
            setAnswerError({ Error: true, Text: "Please fill Answer Field" });
        }
    }
    const handleEnter = (event) => {
        if (event.key === 'Enter') {
            onSubmit();
        }
    }
    const OnSearch = () => {
        if (searchText) {
            const search = {
                SearchString: searchText.trim()
            }
            setLoading(true);
            Axios.post(`${getBaseURL()}/faq/faqsearch`, search, config)
                .then((response) => {
                    setFaq(response.data);
                    setLoading(false);
                    setPage(0);
                })
                .catch((error) => {
                    setFaqError({ Error: true, Text: error.response.data.message });
                    setLoading(false);
                    setPage(0);
                })
        }
        else {
            setFaqError({ Error: true, Text: "Enter a question, answer" });

        }
    }
    return (
        <Container maxWidth={false} disableGutters={true}>
            {isAdmin ?
                <Container maxWidth={false} disableGutters={true}>
                    <Typography variant="h4" gutterBottom>{IsEdit === true ? "Update" : "Add"} Question and Answer</Typography>
                    <Paper className={classes.root}>
                        <TextField
                            id="question"
                            label="Question"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            value={Question}
                            onChange={onQuestionChange}
                            error={questionError.Error}
                            helperText={questionError.Error ? questionError.Text : null}
                            FormHelperTextProps={{ classes: { root: classes.helperText } }}
                        />
                        <br />
                        <br />
                        <TextField
                            id="answer"
                            label="Answer"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            value={Answer}
                            onChange={onAnswerChange}
                            error={answerError.Error}
                            helperText={answerError.Error ? answerError.Text : null}
                            FormHelperTextProps={{ classes: { root: classes.helperText } }}
                        />
                        <span className={classes.wrapper}>

                            {(IsEdit === false) ? <Button
                                variant="contained"
                                color="primary"
                                style={{ marginTop: "15px" }}
                                onClick={onSubmit}
                                className={buttonClassname}
                            >
                                <SendIcon />
                                &nbsp;Add
                            </Button> : <Button
                                variant="contained"
                                color="primary"
                                style={{ marginTop: "15px" }}
                                onClick={onSubmit}
                                className={buttonClassname}
                            >

                                <UpdateIcon />
                                &nbsp;Update
                            </Button>}

                            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </span>

                        <Snackbar
                            open={SnackBarOpen}
                            autoHideDuration={5000}
                            onClose={handleSnackbarClose}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        >
                            <Alert onClose={handleSnackbarClose} severity={AlertColor === "success" ? "success" : "error"}>
                                {SnackBarText}
                            </Alert>
                        </Snackbar>
                    </Paper>
                    <br />
                    <br />

                    {(faq.length) > 0 ? <Paper className={classes.root}>
                        <Typography variant="h4" gutterBottom>Search Questions And Answers</Typography>
                        <TextField
                            id="Faq"
                            label="Search Questions,Answers"
                            value={searchText}
                            onChange={onSearchTextChange}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            helperText={faqError.Error ? faqError.Text : null}
                            error={faqError.Error}
                            FormHelperTextProps={{ classes: { root: classes.helperText } }}
                            onKeyPress={handleEnter}
                        />

                        <span className={classes.wrapper}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={OnSearch}
                                disabled={loading}
                            >
                                <SearchIcon />&nbsp;Search
                            </Button>
                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </span>
                    </Paper> :
                        <Typography variant="subtitle1"></Typography>


                    }
                    {(faq.length && !loading && !faqError.Error) > 0 ?
                        <Paper className={classes.paperSpacing} p={5}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Questions</TableCell>
                                        <TableCell>Answers</TableCell>
                                        <TableCell>Actions</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {faq.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {

                                        return (
                                            <TableRow key={item.id}>
                                                <TableCell>{item.question}</TableCell>
                                                <TableCell>{item.answer}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        id="btnedir"
                                                        color="primary"
                                                        onClick={() => getFaqDataById(item.id)}
                                                    >
                                                        <EditIcon />
                                                    </Button>

                                                    <Button
                                                        id="btndelete"
                                                        color="primary"
                                                        onClick={() => handleClickOpen(item.id)}
                                                    >
                                                        <DeleteIcon />
                                                    </Button>

                                                </TableCell>

                                            </TableRow>
                                        );
                                    })}

                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 20]}
                                component="div"
                                count={faq.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    'aria-label': 'previous page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'next page',
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </Paper>
                        : null


                    }

                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete this question?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">

                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                No
                            </Button>
                            <Button onClick={DeleteFaq} color="primary" autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Container>
                :
                <Paper className={classes.root}>
                    <Typography variant="h6">
                        <ErrorIcon />&nbsp;Access Denied
                    </Typography>
                </Paper>
            }
        </Container>
    );
}
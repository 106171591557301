import React, { useState, useEffect } from "react";
import { Container, TextField, Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import { green, red } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper'
import { useSelector } from "react-redux";
import Axios from "axios";
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import clsx from 'clsx';
import { getBaseURL } from "../../baseURL";
import { RichTextEditorComponent, Toolbar as syncfusionToolbar, Inject, Link, HtmlEditor, Count, QuickToolbar, Table } from '@syncfusion/ej2-react-richtexteditor';

export const BugReport = () => {
  const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(2, 2),
    },
    paperSpacing: {
      paddingTop: "30px",
    },
    wrapper: {
      position: 'relative',
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    buttonError: {
        backgroundColor: red[500],
        '&:hover': {
          backgroundColor: red[700],
        },
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -5,
      marginLeft: -12,
    },
    paperSpacing: {
      paddingTop: "30px",
    },
    helperText: {
      marginLeft: 0,
    },
  }));

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState({ Error: false, Text: "" });
  const [bugTitle, setBugTitle] = useState("");
  const [bugTitleError, setBugTitleError] = useState({Error: false, Text: ""});
  const [bugReproSteps, setBugReproSteps] = useState("");
  const [bugReproStepsError, setBugReproStepsError] = useState({Error: false, Text: ""});
  const [bugAcceptanceCriteria, setBugAcceptanceCriteria] = useState("");
  const [bugAcceptanceCriteriaError, setBugAcceptanceCriteriaError] = useState({Error: false, Text: ""});
  const [customMessage, setCustomMessage] = useState("");
  const [editor, setEditor] = useState();
  const [AzureDevopsBugUrl, setAzureDevopsBugUrl] = useState("");
  const [AzureDevopsSecretkey, setAzureDevopsSecretkey] = useState("");
  const [IsbugReportFeatureEnable, setIsbugReportFeatureEnable] = useState("");
  const buttonClassname = clsx({
    [classes.buttonError]: submitError.Error,
  });

  const currentUser = useSelector(state => state.auth);
  
  const toolbarItems = ['Bold', 'Italic', 'Underline', 'StrikeThrough',
  'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
  'LowerCase', 'UpperCase', '|',
  'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
  'Outdent', 'Indent', 'SuperScript', 'SubScript', '|',
  'CreateTable', 'CreateLink', '|', 'ClearFormat',
   '|', 'Undo', 'Redo'];

  const toolbarSettings={items: toolbarItems}
  
  const onBugTitleChange = event => {
    setBugTitle(event.target.value);

    if(event.target.value) {
      setBugTitleError({Error: false, Text: ""});
    }
  }

  const onBugAcceptanceCriteriaChange = event => {
    setBugAcceptanceCriteria(event.target.value);

    if(event.target.value) {
      setBugAcceptanceCriteriaError({Error: false, Text: ""});
    }
  }

  const onBugReproStepsChange = event => {
    setBugReproSteps(editor.value.replace(/"/g, "\\\"").replace(/\//g, "\\/"));

    if(editor.value) {
      setBugReproStepsError({Error: false, Text: ""});
    }
}
const config = {
  headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken }
};


useEffect(() => {         
  getAzureSecretkey();
  setIsbugReportFeatureEnable(currentUser.featurecontrolList.find(x => x.name == "Report Bug").grantByAdmin)
       
}, []);

const getAzureSecretkey = () => {
  Axios.get(`${getBaseURL()}/admin/AzuredevopsApiUrl`, config)
      .then(response => {
         setAzureDevopsBugUrl(response.data.message.azureDevopsBugUrl)
         setAzureDevopsSecretkey(response.data.message.secretkey)
      })
      .catch(error => {
          console.log(error)
      })
}
  const onSubmit = () => {
    if (bugReproSteps.length>=200 && bugTitle.length>=55 && bugAcceptanceCriteria.length>=50) {

      
      const bugPatchJson = `[
        {
          "op": "add",
          "path": "/fields/System.Title",
          "from": null,
          "value": "${bugTitle}"
        },
        {
          "op": "add",
          "path": "/fields/Microsoft.VSTS.TCM.ReproSteps",
          "from": null,
          "value": "${bugReproSteps}"
        },
        {
          "op": "add",
          "path": "/fields/System.Description",
          "from": null,
          "value": "${bugReproSteps}"
        },
        {
          "op": "add",
          "path": "/fields/System.History",
          "from": null,
          "value": "Submitted by ${currentUser.aadResponse.account.name}, email address ${currentUser.aadResponse.account.userName}"
        },
        {
          "op": "add",
          "path": "/fields/Microsoft.VSTS.Common.AcceptanceCriteria",
          "from": null,
          "value": "${bugAcceptanceCriteria}"
        }
      ]`

      const devOpsAxiosConfig = {
        headers: {
          'Authorization':'Basic ' + AzureDevopsSecretkey,
          'Content-Type': 'application/json-patch+json',
        }
      }

      //prod
      const prodDevOpsApiBugUrl = AzureDevopsBugUrl;    
      setIsLoading(true);
      Axios.post(prodDevOpsApiBugUrl, bugPatchJson, devOpsAxiosConfig)
        .then(() => {
          setIsLoading(false);
          setIsSubmitted(true);
        })
        .catch((error) => {
          console.log(error.response.data.message);
          setSubmitError({ Error: true, Text: error.response.data.message });
          setIsLoading(false);
        });
    }
    else {
      if (!bugTitle) {
        setBugTitleError({ Error: true, Text: "Please enter a summary of the bug" });
      }
      if (bugTitle.length <=55) {
        setBugTitleError({ Error: true, Text: "Summary must have at least 55 characters" });
      }
      if (bugReproSteps.length<=200) {
        setBugReproStepsError({ Error: true, Text: "Description must have at least 200 characters." });
      }
      if (!bugAcceptanceCriteria) {
        setBugAcceptanceCriteriaError({ Error: true, Text: "Please describe the expected outcome" });
      }
      if (bugAcceptanceCriteria.length<=50) {
        setBugAcceptanceCriteriaError({ Error: true, Text: "Expected outcome must have at least 50 characters" });
      }     
     
    }
  }

  return (
    <Container>
  {IsbugReportFeatureEnable?
<React.Fragment>
      <Typography variant="h4">Report a Bug</Typography>
      {!isSubmitted ?
        <React.Fragment>
          <Paper className={classes.root}>
            <TextField
              id="bugTitle"
              value={bugTitle}
              onChange={onBugTitleChange}
              variant="outlined"
              label="Summary of the bug"
              fullWidth
              helperText={bugTitleError.Error ? bugTitleError.Text : null}
              error={bugTitleError.Error}
              FormHelperTextProps={{ classes: { root: classes.helperText } }}
              style={{ marginTop: '10px' }}
            />
            <br />
            <br />
            <span className="h4">Please describe the steps needed to reproduce the bug:</span>
            <RichTextEditorComponent
            style={{ marginTop: '10px' }}
              id="bugReproSteps"
                showCharCount={false}
                toolbarSettings={toolbarSettings}
                height="400"
                // style={{overflow: "hidden"}}
                ref={editor => setEditor(editor)}
                change={onBugReproStepsChange}
            >
                <Inject services={[syncfusionToolbar, Link, HtmlEditor, Count, QuickToolbar, Table]}/>
            </RichTextEditorComponent>
            {bugReproStepsError.Error?<span style={{color:"red"}}>{bugReproStepsError.Text}</span>:null}
            <TextField
              id="bugAcceptanceCriteria"
              variant="outlined"
              label="Expected outcome"
              fullWidth
              helperText={bugAcceptanceCriteriaError.Error?bugAcceptanceCriteriaError.Text:<span>Please describe the expected outcome</span>}
              value={bugAcceptanceCriteria}
              onChange={onBugAcceptanceCriteriaChange}
              FormHelperTextProps={{ classes: { root: classes.helperText } }}
              error={bugAcceptanceCriteriaError.Error}
              style={{ marginTop: '10px' }}
            />
            <br />
            <br />
            <span className={classes.wrapper}>
              <Button
                id="submit"
                color="primary"
                variant="contained"
                onClick={onSubmit}
                className={buttonClassname}
                disabled={isLoading}
              >
                {(!isLoading && !submitError.Error) && <SendIcon />}
                {(!isLoading && submitError.Error) &&
                  <ErrorIcon />
                }
              &nbsp;Submit
            </Button>
              {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </span>

            {submitError.Error &&
              <Typography variant="subtitle1" style={{ color: "red", marginTop: 10 }}>{submitError.Text}</Typography>
            }
          </Paper>
        </React.Fragment>
        :
        <Paper className={classes.root}>
          <Typography variant="h6">
            <CheckIcon />&nbsp;Your bug has been reported to the developers. 
            </Typography>
        </Paper>

      }
</React.Fragment>
:


<Paper>
<Typography variant="h6">
    <ErrorIcon />&nbsp;Access Denied
</Typography>
</Paper>
}
    </Container>
  )
}
import {
    Fab,
    Table,
    Select,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    TableHead,
    TextField,
    Typography,
    CircularProgress,
    Tooltip
} from '@mui/material';
import { useSelector } from "react-redux";
import { getBaseURL } from "../../baseURL";
import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import React, { useState, useEffect } from "react";
import Axios from "axios";
import { green, red } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { Persona } from '@fluentui/react/lib/Persona';
import { Container, Paper, Snackbar } from "@mui/material";
import { WindowSharp } from "@mui/icons-material";

export const ManageAccessRequests = () => {
    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(3, 2),
        },
        paperSpacing: {
            paddingTop: "30px",
        },
        fabProgress: {
            color: green[500],
            position: 'absolute',
            top: -18,
            left: -3,
            zIndex: 1,
        },
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative',
        },
        buttonGreen: {
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[700],
            },
        },
        buttonRed: {
            backgroundColor: red[500],
            '&:hover': {
                backgroundColor: red[700],
            },
            smallCell: {
                maxWidth: "50px",
            },
        },
    }));

    initializeIcons();
    const classes = useStyles();
    const currentUser = useSelector(state => state.auth);
    const config = {
        headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken }
    };
    const [isAdmin, setIsAdmin] = useState(null);
    const [accessRequests, setAccessRequests] = useState([]);
    const [classifications, setClassifications] = useState([]);
    const [checkedRequests, setCheckedRequests] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    function loadData() {
        if (accessRequests.length < 1 && !checkedRequests) {
            Axios.get(`${getBaseURL()}/admin/accessrequests`, config)
                .then((response) => {
                    var requests = [...response.data];
                    requests.forEach(x => x.submitted = { Submitted: false, Error: false, Message: "" });
                    setAccessRequests(requests);
                    setCheckedRequests(true);
                })
                .catch((error) => {
                    // setLoading(false);
                    // setSendError({error: true, text: error.response.data.message})
                });
        }

        if (classifications.length < 1) {
            Axios.get(`${getBaseURL()}/admin/getclassificationlevels`, config)
                .then((response) => {
                    setClassifications(response.data);
                })
        }

        if (isAdmin === null) {
            try {
                if (currentUser.user.permissions.find(p => p.permissionName === "System Admin").value === true) {
                    setIsAdmin(true);
                }
                else {
                    setIsAdmin(false);
                }
            }
            catch (error) {
                setIsAdmin(false);
            }

            if (!isAdmin) {
                try {
                    if (currentUser.user.permissions.find(p => p.permissionName === "User Admin").value === true) {
                        setIsAdmin(true);
                    }
                    else {
                        setIsAdmin(false);
                    }
                }
                catch (error) {
                    setIsAdmin(false);
                }
            }
        }

    }
    const onPrintChange = event => {
        var requests = [...accessRequests];
        requests.find(x => x.userID === event.target.id).requestPrint = event.target.checked;

        setAccessRequests(requests);
    }

    const onAccessLevelChange = (userID, event) => {
        var requests = [...accessRequests];
        requests.find(x => x.userID === userID).classificationLevel = classifications.find(x => x.id === event.target.value);

        setAccessRequests(requests);
    }

    const adminComments = (requestID, event) => {
        var requests = [...accessRequests];
        requests.find(x => x.id === requestID).adminComments = event.target.value;

        setAccessRequests(requests);
    }

    const commentsToUser = (requestID, event) => {
        var requests = [...accessRequests];
        requests.find(x => x.id === requestID).commentsToUser = event.target.value;

        setAccessRequests(requests);
    }

    const approveDeny = (userID, approve) => {
        const userRequest = accessRequests.find(x => x.userID === userID);

        const request = {
            guid: userRequest.user.guid,
            classLevel: userRequest.classificationLevel.id,
            requestPrint: userRequest.requestPrint,
            state: approve,
            adminComments: userRequest.adminComments,
            commentsToUser: userRequest.commentsToUser
        }

        var requests = [...accessRequests];

        if (approve) {
            requests.find(x => x.userID === userID).SendingApprove = true;
        }
        else {
            requests.find(x => x.userID === userID).SendingReject = true;
        }

        setAccessRequests(requests);

        Axios.post(`${getBaseURL()}/admin/accessrequests/update`, request, config)
            .then((response) => {
                requests = [...accessRequests];
                var message = "";

                if (approve) {
                    message = "APPROVED"
                }
                else {
                    message = "REJECTED"
                }
                requests.find(x => x.userID === userID).submitted = { Submitted: true, Error: false, Message: message }
                requests.find(x => x.userID === userID).SendingApprove = false;
                requests.find(x => x.userID === userID).SendingReject = false;
                setAccessRequests(requests);
                loadData();

            })
            .catch((error) => {
                message = "An error occurred";
            });
    }

    const adminCommentsHelp = () => {
        setAdminHelpOpen(!adminHelpOpen);
    }

    const commentsToUserHelp = () => {
        setCommentsToUserHelpOpen(!commentsToUserHelpOpen);
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    }

    const getUserPersonaText = (user) => {
        if (user.firstName && user.lastName) {
            return (user.firstName + " " + user.lastName);
        }

        return user.email
    }

    const CustomPersonaTableCell = withStyles(theme => ({
        head: {
            maxWidth: '20em'
        },
        body: {
            maxWidth: '20em'
        }
    }))(TableCell);

    const CustomJustificationTableCell = withStyles(theme => ({
        head: {
            maxWidth: '5em'
        },
        body: {
            maxWidth: '5em'
        }
    }))(TableCell);

    return (
        <Container maxWidth={false} disableGutters={true}>
            {isAdmin ?
                <Container maxWidth={false} disableGutters={true}>
                    <Typography variant="h4" gutterBottom>Manage Access Requests</Typography>
                    {checkedRequests ?
                        (accessRequests.length > 0) ?
                            <Paper className={classes.root}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>User</TableCell>
                                            <TableCell>Existing Access Level</TableCell>
                                            <TableCell>Requested Access Level</TableCell>
                                            <TableCell>Print</TableCell>
                                            <TableCell>Justification</TableCell>
                                            <TableCell>Other Access Requested</TableCell>
                                            {/* <TableCell>
                                                Admin Comments&nbsp;
                                                <Tooltip title="These comments are stored only for review and reporting purposes and are not sent to the requestor.">
                                                    <QuestionMarkIcon 
                                                        color="primary"
                                                        fontSize="small"
                                                    />
                                                </Tooltip>
                                            </TableCell> */}
                                            <TableCell>
                                                Comments to User&nbsp;
                                                <Tooltip title="These comments are sent directly to the user, and are stored for review and reporting.">
                                                    <QuestionMarkIcon
                                                        color="primary"
                                                        fontSize="small"
                                                    />
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {accessRequests.map((item, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <CustomPersonaTableCell>
                                                        <Persona
                                                            text={getUserPersonaText(item.user)}
                                                            secondaryText={item.user.jobTitle}
                                                            imageUrl={"data:image/png;base64," + item.user.photo}
                                                        />
                                                    </CustomPersonaTableCell>
                                                    <TableCell>
                                                        {item.accessReason === 'NA' ? null : item.existingClassLevelDesc}
                                                    </TableCell>

                                                    <TableCell>
                                                        <Select
                                                            id={item.userID}
                                                            value={item.classificationLevel.id}
                                                            variant="standard"
                                                            onChange={(e) => onAccessLevelChange(item.userID.toString(), e)}
                                                        >
                                                            {classifications.map((item, index) => {
                                                                return (
                                                                    <MenuItem key={index} value={item.id}>{item.description}</MenuItem>
                                                                );
                                                            })}
                                                        </Select>
                                                    </TableCell>

                                                    <TableCell>
                                                        <Checkbox
                                                            id={item.userID}
                                                            color="primary"
                                                            checked={item.requestPrint}
                                                            onChange={onPrintChange}
                                                        />
                                                    </TableCell>
                                                    <CustomJustificationTableCell>{item.justification}</CustomJustificationTableCell>
                                                    <CustomJustificationTableCell>{item.accessReason}</CustomJustificationTableCell>
                                                    {/* <TableCell>
                                                        <TextField
                                                            id="adminComment"
                                                            variant="standard"
                                                            multiline
                                                            value={item.adminComments}
                                                            onChange={(e) => adminComments(item.id, e)}
                                                        />
                                                    </TableCell> */}
                                                    <TableCell>
                                                        <TextField
                                                            id="commentsToUser"
                                                            variant="standard"
                                                            multiline
                                                            value={item.commentsToUser}
                                                            onChange={(e) => commentsToUser(item.id, e)}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        {!item.submitted.Submitted ?
                                                            <span>
                                                                <span className={classes.wrapper}>
                                                                    <Fab
                                                                        id={item.userID}
                                                                        size="medium"
                                                                        color="primary"
                                                                        onClick={() => approveDeny(item.userID, false)}
                                                                        disabled={item.SendingReject || item.SendingApprove}
                                                                    >
                                                                        <ClearIcon />
                                                                    </Fab>
                                                                    {item.SendingReject && <CircularProgress size={52} className={classes.fabProgress} />}
                                                                </span>
                                                                <span className={classes.wrapper}>
                                                                    <Fab
                                                                        size="medium"
                                                                        color="primary"
                                                                        onClick={() => approveDeny(item.userID, true)}
                                                                        disabled={item.SendingReject || item.SendingApprove}
                                                                    >
                                                                        <CheckIcon />
                                                                    </Fab>
                                                                    {item.SendingApprove && <CircularProgress size={52} className={classes.fabProgress} />}
                                                                </span>
                                                            </span>
                                                            : <Typography variant="subtitle1">{item.submitted.Message}</Typography>}


                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </Paper>
                            :
                            <Paper className={classes.root}>
                                <Typography variant="h6">
                                    No Requests Found
                                </Typography>
                            </Paper>
                        :
                        <CircularProgress color="primary" />
                    }
                </Container>
                :
                <Paper className={classes.root}>
                    <Typography variant="h6">
                        <ErrorIcon />&nbsp;Access Denied
                    </Typography>
                </Paper>
            }
        </Container>
    );
}
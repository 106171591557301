import React, { useState, useEffect } from "react";
import { Container, TextField, Button, Icon } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import {green, red} from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper'
import { useSelector } from "react-redux";
import Axios from "axios";
import { getBaseURL } from "../../baseURL";
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import SendIcon from '@mui/icons-material/Send';
import { useParams } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useHistory } from "react-router-dom";

export const ScanRequest = () => {
  const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(2, 2),
    },
    paperSpacing: {
      paddingTop: "30px",
    },
    wrapper: {
      position: 'relative',
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    buttonError: {
        backgroundColor: red[500],
        '&:hover': {
          backgroundColor: red[700],
        },
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -5,
      marginLeft: -12,
    },
    paperSpacing: {
      paddingTop: "30px",
    },
    helperText: {
      marginLeft: 0,
    },
  }));

  const classes = useStyles();
  const [requestedDrawing, setRequestedDrawing] = useState("");
  const [drawingError, setDrawingError] = useState({Error: false, Text: ""});
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState({Error: false, Text: ""});
  const [scanRequestConfirmation, setScanRequestConfirmation] = useState(false);
  const [open,setOpen] = useState(false);


  let { documentID } = useParams();
  let history = useHistory();

  const currentUser = useSelector(state => state.auth);
  const config = {
    headers: {'Authorization': "Bearer " + currentUser.jwtIdToken.idToken}
  };

  const buttonClassname = clsx({
    [classes.buttonSuccess]: submitted,
    [classes.buttonError]: submitError.Error,
  });

  const handleOpen = () =>{
    setOpen(true);
  }

  const handleClose = () =>{
    setOpen(false);
    clearRequestedDrawing();
  }

  const clearRequestedDrawing = () => {
    setRequestedDrawing("");
  }
  

  const onDrawingNumberChange = event => {
    setRequestedDrawing(event.target.value);
    setSubmitted(false);
    setSubmitError({Error: false, Text: ""});

    if(event.target.value) {
      setDrawingError({Error: false, Text: ""});
    }
  }

  const onSubmit = () => {
    if(requestedDrawing) {
      setIsLoading(true);
      Axios.get(`${getBaseURL()}/requests/scanrequest/new/${requestedDrawing}`, config)
      .then((response) => {
        setIsLoading(false);
        setSubmitted(true);
        setSubmitError({Error: false, Text: ""});
      })
      .catch((error) => {
        setIsLoading(false);
        setSubmitted(true);
        setSubmitError({Error: true, Text: error.response.data.message})
      })
      handleOpen();
    }    
  }

  useEffect(() => {
    if(!requestedDrawing && documentID) {
      setRequestedDrawing(documentID);
    }
  }, [])

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Scan Request</Typography>
      <Paper className={classes.root}>
        <TextField
          id="drawingNumber"
          label="Drawing Number"
          variant="outlined"
          color="primary"
          fullWidth
          value={requestedDrawing}
          onChange={onDrawingNumberChange}
          error={drawingError.Error}
          helperText={drawingError.Error ? drawingError.Text : null}
          FormHelperTextProps={{ classes: { root: classes.helperText }}}
        />
        <span className={classes.wrapper}>
          <Button 
            variant="contained" 
            color="primary" 
            style={{marginTop: "15px"}} 
            onClick={onSubmit}
            className={buttonClassname}
          >
            {(submitted && !submitError.Error) && <CheckIcon />}
            {(submitted && submitError.Error) && <ErrorIcon />}
            {!submitted && <SendIcon />}
            &nbsp;Submit
          </Button>
          {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </span>
        <Dialog open={open} onClose={handleClose}>
                        <DialogTitle id="ScanRequest Confirmation Alert">Your scan request has been received.</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Please check your email for the confirmation.
                            </DialogContentText>
                            <DialogActions>
                                <Button onClick = {handleClose} color="primary">OK</Button>
                            </DialogActions>
                        </DialogContent>
        </Dialog>
      </Paper>
    </Container>
  )
}
import React, { useState, useEffect } from "react";
import { Container, TextField, Button, Icon, Snackbar, TablePagination } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { green, red } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper'
import { useSelector } from "react-redux";
import Axios from "axios";
import { getBaseURL } from "../../baseURL";
import Checkbox from '@mui/material/Checkbox';
import MuiAlert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import FormControlLabel from '@mui/material/FormControlLabel';
import ErrorIcon from '@mui/icons-material/Error';
export const ManageFeatureControl = () => {
    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(2, 2),
        },
        paperSpacing: {
            paddingTop: "30px",
        },
        wrapper: {
            position: 'relative',
        },
        buttonSuccess: {
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[700],
            },
        },
        buttonError: {
            backgroundColor: red[500],
            '&:hover': {
                backgroundColor: red[700],
            },
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -5,
            marginLeft: -12,
        },
        paperSpacing: {
            paddingTop: "30px",
        },
        helperText: {
            marginLeft: 0,
        },
    }));

    const classes = useStyles();    
   
    const[isAdmin, setIsAdmin] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [submitError, setSubmitError] = useState({ Error: false, Text: "" });
    const [SnackBarOpen, setSnackBarOpen] = useState(false);
    const currentUser = useSelector(state => state.auth);
    const [searchText, setSearchText] = useState("");
    const config = {
        headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken }
    };
    const [open, setOpen] = React.useState(false);
    const [SnackBarText, setSnackBarText] = useState("");

    const buttonClassname = clsx({
        [classes.buttonSuccess]: submitted,
        [classes.buttonError]: submitError.Error,
    });

    const [featureControl, setfeatureControl] = useState([]);
    useEffect(() => {
        getfeaturecontrol();

        if(isAdmin === null) {
            try {
                if(currentUser.user.permissions.find(p => p.permissionName === "System Admin").value === true) {
                    setIsAdmin(true);
                }
                else {
                    setIsAdmin(false);
                }
            }
            catch(error) {
                setIsAdmin(false);
            }
    
            if(!isAdmin) {
                try {
                    if(currentUser.user.permissions.find(p => p.permissionName === "User Admin").value === true) {
                        setIsAdmin(true);
                    }
                    else {
                        setIsAdmin(false);
                    }
                }
                catch(error) {
                    setIsAdmin(false);
                }
            }
        }
    }, []);

    const getfeaturecontrol = () => {
        Axios.get(`${getBaseURL()}/admin/getfeaturecontrol`, config)
            .then(response => {                
                setfeatureControl(response.data)

            })
            .catch(error => {
                console.log(error)
            })
    }

    const onCheckChange = (event) => {
        var newfeature = [...featureControl];
        newfeature.find(x => x.id == parseInt(event.target.id)).grantByAdmin = event.target.checked;
        var Updatefeaturecontrol = {
            ID: parseInt(event.target.id),
            grantByAdmin: event.target.checked
        }
        Axios.post(`${getBaseURL()}/admin/Updatefeaturecontrol`, Updatefeaturecontrol, config)
            .then(res => {
                setfeatureControl(newfeature);
            })
            .catch(err => {
                console.log(err)
            });

    }

    return (
        <Container maxWidth={false} disableGutters={true}>
            {isAdmin ?
                <Container maxWidth={false} disableGutters={true}>
                    <Typography variant="h4"> Manage Feature Control</Typography>
                    <Paper>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Enabled</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {featureControl.map((item, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            id={item.id.toString()}
                                                            checked={item.grantByAdmin}
                                                            onChange={onCheckChange}
                                                            value={item.permission} color="primary"
                                                        />
                                                    }
                                                />
                                                &nbsp;
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Paper>
                </Container>
                :
                <Paper className={classes.root}>
                    <Typography variant="h6">
                        <ErrorIcon />&nbsp;Access Denied
                    </Typography>
                </Paper>
            }
        </Container>
    )
}
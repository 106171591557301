import '../../index.css';
import Axios from "axios";
import { useSelector } from "react-redux";
import { getBaseURL } from "../../baseURL";
import Paper from '@mui/material/Paper'
import { useHistory } from "react-router-dom";
import { AlertTitle } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ErrorIcon from '@mui/icons-material/Error';
import React, { useState, useEffect } from "react";
import { green, red } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { Container, Button, Snackbar } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const KtipLoader = ({ FileUploadApInProgress }) => {
    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(2, 2),
        },
        paperSpacing: {
            paddingTop: "30px",
        },
        wrapper: {
            position: 'relative',
        },
        Fieldsspace: {
            marginRight: '200px',
        },
        formControl: {
            minWidth: 219,
            marginRight: '200px',
            marginTop: '20px',
        },
        FieldsspaceForButton: {
            marginRight: '376px',
        },
        buttonSuccess: {
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[700],
            },
        },
        buttonError: {
            backgroundColor: red[500],
            '&:hover': {
                backgroundColor: red[700],
            },
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -16,
            marginLeft: 229,
        },
        buttonProgressSearch: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -11,
            marginLeft: -205
        },
        paperSpacing: {
            paddingTop: "30px",
        },
        helperText: {
            marginLeft: 0,
        },
        chunkFileUpload: {
            border: '0.25em dashed #140a9a',
            marginTop: 16
        },
        dialog :{
            width: 350,
            height: 80,
            marginTop: '10%',
            justifyContent: 'center',
            textAlign: 'center',
            fontSize: 15,
            fontWeight: 'bold',
        },
        dialogProgress:{
            color: green[500],
            justifyContent: 'center',
            textAlign: 'center',
            marginRight: 85,
            marginTop: 20,
        }
    }));

    const classes = useStyles();
    const [Edit, SetEdit] = useState(false);
    const [Filename, setFilename] = useState("");
    const [loading, setLoading] = useState(false);
    const [AlertColor, setAlertColor] = useState("");
    const [DrawingFile, setDrawingFile] = useState([]);
    const [pdfFilename, setPdfFilename] = useState("");
    const [pdfloading, setPdfLoading] = useState(false);
    const currentUser = useSelector(state => state.auth);
    const [SnackBarText, setSnackBarText] = useState("");
    const [PdfFilelength, setPdfFilelength] = useState(0);
    const [SnackBarOpen, setSnackBarOpen] = useState(false);
    const [PdfDrawingFile, setPdfDrawingFile] = useState([]);
    const [excelUploading, setExcelUploading] = useState(false);
    const [FileUploadError, setFileUploadError] = useState(false);
    const [drawingsUploading, setDrawingsUploading] = useState(false);
    const [FileUploadPdfError, setFileUploadPdfError] = useState(false);
    const [LoaderFeatureEnable, setLoaderFeatureEnable] = useState(false);
    const [open, setOpen] = useState(false);
    const config = {
        headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken, 'Content-Type': "multipart/form-data" }
    };

    let history = useHistory();

    const path = {
        chunkSize: 10485760,
        // set chunk size for enable the chunk upload
        saveUrl: `${getBaseURL()}/Files/ChunkFileSave`
    }

    
    const handleClickOpen = () => {
        setOpen(true);
    };
      

    // const onSelectingDrawings = (event) => {
    //   const filesData =  event.filesData;
    //   if (filesData.length > 1000) {
    //     event.filesData.splice(1000, event.filesData.length);
    //     event.modifiedFilesData = event.filesData;
    //   }
    // }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarOpen(false);
    }

    const Alert = (props) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    useEffect(() => {
        try {
            if (currentUser.user.permissions.find(p => p.permissionName === "KTIP Loader").value === true) {
                setLoaderFeatureEnable(true);
            }
            else {
                setLoaderFeatureEnable(false);
            }
        }
        catch (error) {
            setLoaderFeatureEnable(false);
        }
    }, []);

    const getFileContext = async (e) => {
        if(e.target.files.length == 1){
            setDrawingFile(e.target.files[0]);
            setFilename(e.target.files[0].name)
            setFileUploadError(false)
        }
        else{
            setDrawingFile([]);
            setFilename("");
        }
    }

    const getFileContextForPdf = async (e) => {
        setPdfDrawingFile(e.target.files);
        setPdfFilelength(e.target.files.length)
        setFileUploadPdfError(false)
    }

    const OnDrawingsUploading = () => {
        setDrawingsUploading(true);
        FileUploadApInProgress('Started');
    }

    const afterUploadingDrawings = () => {
        setDrawingsUploading(false);
        FileUploadApInProgress('complete');
    }

    const onSubmit = () => {
        handleClickOpen()
        if (DrawingFile.length !== 0) {
            setExcelUploading(true);
            const body = new FormData();
            body.append('ImportDrawing', DrawingFile);
            setLoading(true);
            FileUploadApInProgress('Started');
            Axios.post(`${getBaseURL()}/documents/ImportDrawings`, body, config)
                .then((response) => {
                   setOpen(false);
                    FileUploadApInProgress('complete');
                    setExcelUploading(false);                        
                    if (response.data.message.includes("records imported successfully out of") && response.data.bytes === null) {
                        setLoading(false)
                        setAlertColor("success")
                        setSnackBarText(response.data.message);
                        setSnackBarOpen(true);
                        OnClear();
                    }
                    else if (response.data.bytes != null) {
                        setLoading(false)
                        setSnackBarText(response.data.message);
                        if (!response.data.message.includes("0 records imported successfully out of"))
                            setAlertColor("success")
                        else
                            setAlertColor("")

                        setSnackBarOpen(true);
                        OnClear();
                        var reportArray = base64ToArrayBuffer(response.data.bytes);

                        const blob = new Blob([reportArray], { type: "text/csv" });
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(blob, "InvalidDocumentInfo.csv");
                        }
                        else {
                            const link = document.createElement('a');
                            link.href = URL.createObjectURL(blob);
                            link.download = "InvalidDocumentInfo.csv";
                            link.click();
                            link.remove();

                            window.addEventListener('focus', e => URL.revokeObjectURL(link.href), { once: true });
                        }
                    }
                    else {
                        setLoading(false)
                        setSnackBarOpen(true);
                        setSnackBarText(response.data.message);
                    }
                })
                .catch((error) => {
                    setOpen(false);
                    setAlertColor("error")
                    setExcelUploading(false);
                    setLoading(false);
                    FileUploadApInProgress('complete');
                    setSnackBarOpen(true);
                    setSnackBarText("Error, unable to import drawing!");
                })
        }
        if (DrawingFile.length === 0) {
            setFileUploadError(true)
            setOpen(false);
        }
    }

    const onPdfSubmit = () => {
        handleClickOpen()
        if (PdfDrawingFile.length !== 0) {
            setDrawingsUploading(true);
            const body = new FormData();

            FileUploadApInProgress('Started');
            PdfDrawingFile.forEach(file => {
                body.append('ImportPdfDrawing', file);
            });

            setPdfLoading(true);

            Axios.post(`${getBaseURL()}/documents/ImportPdfDrawings`, body, config)
                .then((response) => {
                    setOpen(false);
                    FileUploadApInProgress('completed');
                    setDrawingsUploading(false);
                    if (response.data.message.includes("files uploaded out of") && response.data.bytes === null) {
                        setPdfLoading(false)
                        setAlertColor("success")
                        setSnackBarText(response.data.message);
                        setSnackBarOpen(true);
                        OnClear();
                    }
                    else if (response.data.bytes !== null && response.data.bytes != undefined) {
                        setPdfLoading(false)

                        if (!response.data.message.includes("0 files uploaded out of"))
                            setAlertColor("success")
                        else
                            setAlertColor("")

                        setSnackBarText(response.data.message);
                        setSnackBarOpen(true);
                        OnClear();
                        var reportArray = base64ToArrayBuffer(response.data.bytes);

                        const blob = new Blob([reportArray], { type: "text/csv" });
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(blob, "InvalidDocumentInfo.csv");
                        }
                        else {
                            const link = document.createElement('a');
                            link.href = URL.createObjectURL(blob);
                            link.download = "InvalidDocumentInfo.csv";
                            link.click();
                            link.remove();

                            window.addEventListener('focus', e => URL.revokeObjectURL(link.href), { once: true });
                        }
                    }
                    else {
                        setPdfLoading(false)
                        setSnackBarOpen(true);
                        setSnackBarText(response.data.message);
                    }
                })
                .catch((error) => {
                    setOpen(false);
                    setAlertColor("error")
                    FileUploadApInProgress('error');
                    setDrawingsUploading(false);
                    setPdfLoading(false)
                    setSnackBarOpen(true);
                    setSnackBarText("Error, unable to upload drawings!");
                })
        }
        if (PdfDrawingFile.length === 0) {
            setFileUploadPdfError(true)
            setOpen(false);
        }
    }

    const base64ToArrayBuffer = (base64) => {
        const binaryString = window.atob(base64);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    const OnClear = () => {
        setDrawingFile([]);
        setPdfDrawingFile([]);
        setFilename("")
        setPdfFilelength(0)
    }

    return (
        <Container maxWidth={false} disableGutters={true}>
            {LoaderFeatureEnable ?
                <div>
                    <Typography variant="h4" gutterBottom>Drawing Import Excel</Typography>
                    <Paper className={classes.root}>
                        <br />
                        <br />
                        <React.Fragment>
                            <input type="file" id="inputFile" className="form-control-file inputfile" onChange={getFileContext} data-title="Import excel or Drag drop a excel" />
                            {
                                FileUploadError ? <Alert severity="error" >
                                    <AlertTitle>Please Import Atleast one drawing  excel or csv file</AlertTitle>
                                </Alert> : null
                            }
                        </React.Fragment>
                        {
                            Filename ? <Alert severity="success" >
                                <AlertTitle>{Filename} is selected </AlertTitle>
                            </Alert> : null
                        }
                        <br />
                        <span className={classes.wrapper}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading || drawingsUploading}
                                onClick={onSubmit}
                                className={classes.FieldsspaceForButton}
                            >
                                Upload Excel
              </Button>
                            {/* {loading && <CircularProgress size={24} className={classes.buttonProgressSearch} />} */}
                            {Edit ?
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                    onClick={OnClear}
                                    className={classes.FieldsspaceForButton}
                                >
                                    Clear
                </Button>
                                : null
                            }
                        </span>
                    </Paper>
                    <br />

                    <Typography variant="h4" gutterBottom>Drawing Files Upload (Allowable file formats – TIFF, PDF) </Typography>
                    <Paper className={classes.root}>
                        <br />
                        <React.Fragment>
                            <input type="file" multiple id="inputFile" accept="application/pdf" className="form-control-file inputfile" onChange={getFileContextForPdf} data-title="Select or Drop Drawing(s) Here" />
                            {
                                FileUploadPdfError ? <Alert severity="error" >
                                    <AlertTitle>Please Select Atleast one drawing pdf file</AlertTitle>
                                </Alert> : null
                            }
                        </React.Fragment>
                        {
                            PdfFilelength > 0 ? <Alert severity="success" >
                                <AlertTitle>{PdfFilelength} Files are selected </AlertTitle>
                            </Alert> : null
                        }
                        <br />
                        <span className={classes.wrapper}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={pdfloading || excelUploading}
                                onClick={onPdfSubmit}
                                className={classes.FieldsspaceForButton}
                            >
                                Upload
              </Button>
                            {/* {pdfloading && <CircularProgress size={24} className={classes.buttonProgressSearch} />} */}
                        </span>

                        {/* <div className={classes.chunkFileUpload} style={excelUploading ? {pointerEvents: 'none', opacity: 0.7} : {}}>
          <UploaderComponent autoUpload={false} sequentialUpload={true} allowedExtensions={'.pdf, .tiff'}
          uploading={OnDrawingsUploading} actionComplete={afterUploadingDrawings}
          asyncSettings={path} buttons={{browse: 'Select'}} maxFileSize={2147483648} />
        </div> */}
        

                        <Snackbar
                            open={SnackBarOpen}
                            onClose={handleSnackbarClose}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        >
                            <Alert onClose={handleSnackbarClose} severity={AlertColor === "success" ? "success" : "error"}>
                                {SnackBarText}
                            </Alert>
                        </Snackbar>
                        
                    </Paper>
                    <br /></div> :
                <Paper>
                    <Typography variant="h6">
                        <ErrorIcon />&nbsp;Access Denied
                    </Typography>
                </Paper>
            }

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogActions>
                    <CircularProgress size={150} className={classes.dialogProgress} />
                </DialogActions>
                <DialogContent className={classes.dialog}>
                    {pdfloading ? 'Please wait...while your drawings are uploading.' : 'Please wait...while your excel file is uploading.'}
                </DialogContent>
            </Dialog>
        </Container>
    )
}
import Axios from "axios";
import { useSelector } from "react-redux";
import { getBaseURL } from "../../baseURL";
import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import React, { useState, useEffect } from "react";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { green, red } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { Container, Paper, Snackbar, CircularProgress, Table, Fab, fabClasses } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import moment from "moment/moment";

export const ManageSpecialAccessRequests = () => {
  const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(3, 2),
    },
    paperSpacing: {
      paddingTop: "30px",
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: -18,
      left: -3,
      zIndex: 1,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonGreen: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    buttonRed: {
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[700],
      },
    }
  }));

  const classes = useStyles();

  const currentUser = useSelector(state => state.auth);
  const config = {
    headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken }
  };

  const [isAdmin, setIsAdmin] = useState(null);
  const [AlertColor, setAlertColor] = useState("");
  const [SnackBarText, setSnackBarText] = useState("");
  const [SnackBarOpen, setSnackBarOpen] = useState(false);
  const [checkedRequests, setCheckedRequests] = useState(false);
  const [specialAccessRequests, setSpecialAccessRequests] = useState([]);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    if (specialAccessRequests.length < 1 && !checkedRequests) {

      Axios.get(`${getBaseURL()}/admin/specialaccessrequests/get`, config)
        .then((response) => {
          var requests = [...response.data];
          requests = requests.filter(x => x.document != null);
          requests.forEach(x => x.submitted = { Submitted: false, Error: false, Message: "" });
          setSpecialAccessRequests(requests);
          setCheckedRequests(true);
        })
    }

    if (isAdmin === null) {
      try {
        if (currentUser.user.permissions.find(p => p.permissionName === "System Admin").value === true) {
          setIsAdmin(true);
        }
        else {
          setIsAdmin(false);
        }
      }
      catch (error) {
        setIsAdmin(false);
      }

      if (!isAdmin) {
        try {
          if (currentUser.user.permissions.find(p => p.permissionName === "Special Access Admin").value === true) {
            setIsAdmin(true);
          }
          else {
            setIsAdmin(false);
          }
        }
        catch (error) {
          setIsAdmin(false);
        }
      }
    }
  }, []);

  const onPrintChange = (event) => {
    var requests = [...specialAccessRequests];
    requests.find(x => x.id == event.target.id).printRequested = event.target.checked;

    var days = 3;
    if (event.target.checked) {
      days = 3;
    }

    if (event.target.checked === false) {
      days = 5;
    }

    requests.find(x => x.id == event.target.id).effectiveThrough = moment(requests.find(x => x.id == event.target.id).effectiveDate).add(days, 'days');

    setSpecialAccessRequests(requests);
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
  }
  const generateFutureDated = (daysCount) => {
    const today = new Date();
    today.setDate(today.getDate() + daysCount);
    return today;
  }
  const allowFutureApproval = (daysCount, getEffecDate) => {
    const today = new Date(getEffecDate);
    today.setDate(today.getDate() + daysCount);
    return today;
  }
  const formatDate = (date) => {
    return date.toISOString().slice(0, 19);
  };

  const approveDeny = (id, approve) => {
    debugger;
    const userRequest = specialAccessRequests.find(x => x.id === id);
    let actionDate;

    const today = moment();
    const futureDate = moment(userRequest.effectiveDate, 'YYYY-MM-DD');
    console.log(today.format('YYYY-MM-DD'));
    console.log(futureDate.format('YYYY-MM-DD'));

    if (!futureDate.isValid()) {
      console.log("Invalid", userRequest.effectiveDate);
    }
    console.log(today.isAfter(futureDate));

    if (today.isAfter(futureDate) === true) {
      if (userRequest.printRequested) {
        actionDate = formatDate(generateFutureDated(2));
      } else {
        actionDate = formatDate(generateFutureDated(5));
      }
    } else {
      if (userRequest.printRequested) {
        actionDate = formatDate(allowFutureApproval(2, userRequest.effectiveDate));
      } else {
        actionDate = formatDate(allowFutureApproval(5, userRequest.effectiveDate));
      }
    }


    const request = {
      id: id,
      printApproved: userRequest.printRequested,
      state: approve,
      effectiveDate: userRequest.effectiveDate,
      effectiveThrough: actionDate
    }

    let requests = [...specialAccessRequests];

    if (approve) {
      requests.find(x => x.id === id).SendingApprove = true;
    }
    else {
      requests.find(x => x.id === id).SendingReject = true;
    }

    setSpecialAccessRequests(requests);

    Axios.post(`${getBaseURL()}/admin/specialaccessrequests/update`, request, config)
      .then((response) => {
        requests = [...specialAccessRequests];
        var message = "";
        setAlertColor("success")
        if (approve) {
          message = "APPROVED"
          setSnackBarText("The request for special access has been granted");
          setSnackBarOpen(true);
        }
        else {
          message = "REJECTED"
          setSnackBarText("The request for special access has been denied");
          setSnackBarOpen(true);
        }
        requests.find(x => x.id === id).submitted = { Submitted: true, Error: false, Message: message }
        requests.find(x => x.id === id).SendingApprove = false;
        requests.find(x => x.id === id).SendingReject = false;

        try {
          setSpecialAccessRequests(requests);
        }
        catch (error) {
          console.log(error);
        }
      })
      .catch((error) => {
        console.log("Error processing request. Error: " + error);
      });

    console.log("set array");
  }

  return (
    <Container maxWidth={false} disableGutters={true}>
      {isAdmin ?
        <Container maxWidth={false} disableGutters={true}>
          <Typography variant="h4" gutterBottom>Manage Special Access Requests</Typography>
          {specialAccessRequests.length > 0 ?
            <Paper className={classes.root}>
              <Table>
                <TableHead>
                  <TableCell>User</TableCell>
                  <TableCell>Drawing Name</TableCell>
                  <TableCell>Class Level</TableCell>
                  <TableCell>Version</TableCell>
                  <TableCell>Business Need</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Part Description</TableCell>

                  <TableCell>Justification</TableCell>
                  <TableCell>Effective Date <br />{'(Begins midnight UTC)'}</TableCell>
                  <TableCell>Expiration Date <br />{'(Ends midnight next day UTC)'}</TableCell>
                  <TableCell>Print</TableCell>
                  <TableCell></TableCell>
                </TableHead>
                <TableBody>
                  {specialAccessRequests.map((item, index) => {
                    return (
                      <TableRow key={index.toString()}>
                        <TableCell>{item.user.email}</TableCell>
                        <TableCell>{item.document.drawingNumber}</TableCell>
                        <TableCell>{item.description}</TableCell>
                        <TableCell>{item.document.revision}</TableCell>
                        <TableCell>{item.businessNeed}</TableCell>
                        <TableCell>{item.businessNeedDescription}</TableCell>
                        <TableCell>{item.partDescription}</TableCell>
                        <TableCell>{item.businessNeedJustification}</TableCell>
                        <TableCell>{new Date(item.effectiveDate).toLocaleDateString()}</TableCell>
                        <TableCell>{new Date(item.effectiveThrough).toLocaleDateString()}</TableCell>

                        <TableCell>
                          <Checkbox
                            id={item.id}
                            color="primary"
                            checked={item.printRequested}
                            onChange={onPrintChange}
                          />
                        </TableCell>
                        <TableCell>
                          {!item.submitted.Submitted ?
                            <span>
                              <span className={classes.wrapper}>
                                <Fab
                                  id={item.userID}
                                  size="medium"
                                  color="primary"
                                  onClick={() => approveDeny(item.id, false)}
                                  disabled={item.SendingReject || item.SendingApprove}
                                >
                                  <ClearIcon />
                                </Fab>
                                {item.SendingReject && <CircularProgress size={52} className={classes.fabProgress} />}
                              </span>
                              <span className={classes.wrapper}>
                                <Fab
                                  size="medium"
                                  color="primary"
                                  onClick={() => approveDeny(item.id, true)}
                                  disabled={item.SendingReject || item.SendingApprove}
                                >
                                  <CheckIcon />
                                </Fab>
                                {item.SendingApprove && <CircularProgress size={52} className={classes.fabProgress} />}
                              </span>
                            </span>
                            : <Typography variant="button">{item.submitted.Message}</Typography>}
                        </TableCell>
                      </TableRow>
                    );

                  })}
                </TableBody>
              </Table>
            </Paper>
            :
            <Typography variant="subtitle1">No Requests Found</Typography>
          }
          <Snackbar
            open={SnackBarOpen}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert onClose={handleSnackbarClose} severity={AlertColor === "success" ? "success" : "error"}>
              {SnackBarText}
            </Alert>
          </Snackbar>
        </Container>
        :
        <Paper className={classes.root}>
          <Typography variant="h6">
            <ErrorIcon />&nbsp;Access Denied
          </Typography>
        </Paper>
      }
    </Container>
  )
}
import React, { useState, useEffect, Component } from "react";
import { Container, TextField, Button, Icon, TablePagination } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import { green, red } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper'
import { useSelector } from "react-redux";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Axios from "axios";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { TablePaginationActions } from '../../actions/TablePaginationActions';
import { getPdfFiles } from '../../actions/actions';
import { getBaseURL } from "../../baseURL";
import { useHistory } from "react-router-dom";
import Alert from '@mui/material/Alert';
export const Faq = () => {
    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(2, 2),
        },
        paperSpacing: {
            paddingTop: "30px",
        },
        wrapper: {
            position: 'relative',
        },
        buttonSuccess: {
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[700],
            },
        },
        buttonError: {
            backgroundColor: red[500],
            '&:hover': {
                backgroundColor: red[700],
            },
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -10,
            marginLeft: -12,
        },
        InfoMessage: {
            textalign: 'center',
            display: 'flex',
            justifyContent: 'center',
            padding: '90px 15px',
            fontSize: '17px',
        },
        paperSpacing: {
            paddingTop: "30px",
        },
        helperText: {
            marginLeft: 0,
        },
        buttonProgressDownload: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -10,
            marginLeft: -12,
        },
    }));


    const classes = useStyles();
    const [faq, setFaq] = useState([]);
    const [faqError, setFaqError] = useState({ Error: false, Text: "" });
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const currentUser = useSelector(state => state.auth);
    const [latest, setLatest] = useState(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, faq.length - page * rowsPerPage);

    const config = {
        headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken }
    };

    let history = useHistory();

    useEffect(() => {
        GetFaqData();
    }, []);
    const GetFaqData = () => {
        Axios.get(`${getBaseURL()}/faq/getFaqData`, config)
            .then(response => {

                setFaq(response.data);
                setPage(0);
            })
            .catch(error => {
                console.log(error)
            })
    }
    const onSearchTextChange = event => {
        setSearchText(event.target.value);

        if (searchText) {
            setFaqError({ Error: false, Text: "" });
        }
    }

    const onLatestChanged = event => {
        setLatest(event.target.checked);
    }

    const onSubmit = () => {


        if (searchText) {
            const search = {
                SearchString: searchText.trim()
            }

            setLoading(true);

            Axios.post(`${getBaseURL()}/faq/faqsearch`, search, config)
                .then((response) => {
                    setFaq(response.data);
                    setLoading(false);
                    setPage(0);
                })
                .catch((error) => {
                    setFaqError({ Error: true, Text: error.response.data.message });
                    setLoading(false);
                    setPage(0);
                })
        }
        else {
            setFaqError({ Error: true, Text: "Enter a question, answer" });

        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const handleEnter = (event) => {
        if (event.key === 'Enter') {
            onSubmit();
        }
    }

    return (
        <Container maxWidth={false} disableGutters={true}>
            <Typography variant="h4" gutterBottom>Frequently Asked Questions</Typography>
            {(faq.length) > 0 ? <Paper className={classes.root}>
                <TextField
                    id="Faq"
                    label="Search Questions,Answers"
                    value={searchText}
                    onChange={onSearchTextChange}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    helperText={faqError.Error ? faqError.Text : null}
                    error={faqError.Error}
                    FormHelperTextProps={{ classes: { root: classes.helperText } }}
                    onKeyPress={handleEnter}
                />

                <span className={classes.wrapper}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                        disabled={loading}
                    >
                        <SearchIcon />&nbsp;Search
                  </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </span>
            </Paper> :
                <Alert severity="info" className={classes.InfoMessage}>Content Coming Soon</Alert>

            }

            <br />
            <br />
            {(faq.length && !loading && !faqError.Error) > 0 ?
                <Paper className={classes.paperSpacing} p={5}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Questions</TableCell>
                                <TableCell>Answers</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {faq.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {

                                return (
                                    <TableRow key={item.id}>
                                        <TableCell>{item.question}</TableCell>
                                        <TableCell>{item.answer}</TableCell>
                                    </TableRow>
                                );
                            })}

                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        component="div"
                        count={faq.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </Paper>
                : null}
        </Container>
    );
}
import React, { useState, useEffect, Component } from "react";
import { Container, TextField, Button, Icon, TablePagination, Snackbar, FormLabel, FormControl, FormGroup, FormControlLabel, MenuItem, Menu, FormHelperText } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel';
import { green, red } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper'
import { useSelector } from "react-redux";
import Checkbox from '@mui/material/Checkbox';
import Axios from "axios";
import ErrorIcon from '@mui/icons-material/Error';
import { Alert, AlertTitle } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { getBaseURL } from "../../baseURL";
import { useHistory } from "react-router-dom";
import { Date } from "core-js";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


export const DrawingUpload = () => {
  const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(2, 2),
    },
    paperSpacing: {
      paddingTop: "30px",
    },
    wrapper: {
      position: 'relative',
    },
    Fieldsspace: {
      marginRight: '200px',
    },
    formControl: {
      minWidth: 219,
      marginRight: '200px',
      marginTop: '20px',
    },
    FieldsspaceForButton: {
      marginRight: '376px',
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    buttonError: {
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[700],
      },
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -16,
      marginLeft: 229,
    },
    buttonProgressSearch: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -16,
      marginLeft: -211,
    },
    paperSpacing: {
      paddingTop: "30px",
    },
    helperText: {
      marginLeft: 0,
    },
    buttonProgressDownload: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -10,
      marginLeft: -12,
    },
  }));

  const classes = useStyles();
  const [drawings, setDrawings] = useState([]);
  const [drawingError, setDrawingNumberError] = useState({ Error: false, Text: "" });
  const [descError, setdescError] = useState({ Error: false, Text: "" });

  const [sizeError, setSizeError] = useState({ Error: false, Text: "" });
  const [revisionError, setRevisionError] = useState({ Error: false, Text: "" });
  const [NumSheetsError, setNumSheetsError] = useState({ Error: false, Text: "" });
  const [classificationError, setClassificationError] = useState({ Error: false, Text: "" });
  const [ModelError, setModelError] = useState({ Error: false, Text: "" });
  const [Classificationkey, setClassificationkey] = useState({ Name: '', AdditionalInfo: '' });

  const [loading, setLoading] = useState(false);
  
  const [IsDrawingUploadEnable, setIsDrawingUploadEnable] = useState(false);
  const currentUser = useSelector(state => state.auth);

  const [SnackBarOpen, setSnackBarOpen] = useState(false);
  const [SnackBarText, setSnackBarText] = useState("");
  const [DrawingFile, setDrawingFile] = useState([]);


  const [DrawingNumber, setDrawingNumber] = useState("");
  const [desc, setdesc] = useState("");
  const [NumSheets, setNumSheets] = useState("");
  const [Size, setSize] = useState("");
  const [AlertColor, setAlertColor] = useState("");
  const [Revision, setRevision] = useState("");
  const [Classification, setClassification] = useState("");
  const [Model, setModel] = useState("");
  const [effectiveDate, setEffectiveDate] = useState(new Date());
  const [Filename, setFilename] = useState("");
  const [Edit, SetEdit] = useState(false);
  const [FileUploadError, setFileUploadError] = useState(false);
  const [drawingId, setdrawingId] = useState("");
  const [drawingUploadAccess, setDrawingUploadAccess] = useState(false);
  const [ClassficationData, setClassficationData] = useState([]);
  const config = {

    headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken, 'Content-Type': "multipart/form-data" }
  };

  let history = useHistory();


  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarOpen(false);
  }
  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {
    setIsDrawingUploadEnable(currentUser.featurecontrolList.find(x => x.name == "Drawing Upload").grantByAdmin)
     
    GetClassficationData();
  }, []);

  useEffect(() => {
    try {
      if (currentUser.user.permissions.find(p => p.permissionName === "Drawing Upload").value === true) {
        setDrawingUploadAccess(true);
      }
      else {
        setDrawingUploadAccess(false);
      }
    }
    catch (error) {
      setDrawingUploadAccess(false);
    }
  }, []);

  const GetClassficationData = () => {
    Axios.get(`${getBaseURL()}/documents/GetClassiffications`, config)
      .then(response => {        
        setClassficationData(response.data);

      })
      .catch(error => {
        console.log(error)
      })
  }


  const ChangeHandler = event => {

    let names = event.target.id;
    if (names === "DrawingNumber") {
      setDrawingNumber(event.target.value)
    }
    if (names === "Description") {
      setdesc(event.target.value)
    }
    if (names === "Model") {
      setModel(event.target.value)
    }
    if (names === "NumSheets") {
      setNumSheets(event.target.value)
    }
    if (names === "Size") {
      setSize(event.target.value)
    }
    if (names === "Revision") {
      setRevision(event.target.value)
    }
    if (names === "Classification") {
      const re = /^[0-9\b]+$/;
      if (re.test(event.target.value)) {

        setClassification(event.target.value)
      } else {
        setClassificationError({ Error: true, Text: "Accepts number only" });
      }

    }

    if (DrawingNumber) {
      setDrawingNumberError({ Error: false, Text: "" });
    }
    if (desc) {
      setdescError({ Error: false, Text: "" });
    }
    if (Model) {
      setModelError({ Error: false, Text: "" });
    }
    if (NumSheets) {
      setNumSheetsError({ Error: false, Text: "" });
    }
    if (Revision) {
      setRevisionError({ Error: false, Text: "" });
    }
    if (Classification) {
      setClassificationError({ Error: false, Text: "" });
    }

  }
  const getFileContext = async (e) => {
    setDrawingFile(e.target.files[0]);
    setFilename(e.target.files[0].name)
    setDrawingNumber(e.target.files[0].name.split(".")[0])
    setDrawingNumberError({ Error: false, Text: "" });
    setFileUploadError(false)

  }
  const onSubmit = () => {
    if (DrawingNumber && desc && Model && Revision && Classification && NumSheets && effectiveDate) {
      const drawingmModel = {
        DrawingNumber: DrawingNumber,
        Description: desc,
        Model: Model,
        Revision: Revision,
        ClassificationLevel: Classification,
        NumSheet: NumSheets,
        Releasedate: effectiveDate,
        Type: Edit ? "Update" : "Insert",
        DrawingId: drawingId ? drawingId : 0
      }
      const body = new FormData();
      body.append('formFields', JSON.stringify(drawingmModel));

      body.append('uploadFile', DrawingFile);
      setLoading(true);

      Axios.post(`${getBaseURL()}/documents/InsertDrawing`, body, config)
        .then((response) => {

          if (response.data.id) {
            setLoading(false)

            setAlertColor("success")
            if (Edit)
              setSnackBarText("Drawing is Added Successfully");
            else
              setSnackBarText("Drawing is Updated Successfully");
            SetEdit(true)
            setSnackBarOpen(true);
            setdrawingId(response.data.id)
          } else {
            setLoading(false)
            setSnackBarOpen(true);
            setSnackBarText(response.data.message);
          }
        })
        .catch((error) => {
          setLoading(false)
          setSnackBarOpen(true);
          setSnackBarText(error.response.data.message);
          console.log(error.response.data.message);
        })

    }
    if (!DrawingNumber) {
      setDrawingNumberError({ Error: true, Text: "Enter a Drawing Number" });

    }
    if (!desc) {
      setdescError({ Error: true, Text: "Enter a Description" });

    }
    if (!Model) {
      setModelError({ Error: true, Text: "Enter a Model" });

    }
    if (!Size) {
      setSizeError({ Error: true, Text: "Enter a Size" });

    }
    if (!NumSheets) {
      setNumSheetsError({ Error: true, Text: "Enter a NumSheets" });

    }
    if (!Revision) {
      setRevisionError({ Error: true, Text: "Enter a Revision" });

    }
    if (!Classification) {
      setClassificationError({ Error: true, Text: "Enter a ClassiFication" });

    }

    if (DrawingFile.length === 0) {
      setFileUploadError(true)

    }
  }

  const onclassficationChange = event => {

    setClassificationkey({
      Name: event.target.value,
      AdditionalInfo: ClassficationData.find(n => n.id === event.target.value).id
    });

    setClassification(
      ClassficationData.find(n => n.id === event.target.value).id);


    if (event.target.value!==0) {
      
      setClassificationError({ error: false, text: "" });

    }
  };
  const OnClear = () => {
    setDrawingNumber("");
    setdesc("");
    setModel("");
    setRevision("");
    setClassification("");
    setNumSheets("")
    setEffectiveDate(new Date());
    setDrawingFile([]);
    SetEdit(false)
    setFilename("")

  }
  const onEffectiveDateChange = effectiveDate => {
    setEffectiveDate(effectiveDate);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth={false} disableGutters={true}>

    {IsDrawingUploadEnable?
    <div>
    <Typography variant="h4" gutterBottom>Drawing  {Edit ? "Update" : "Upload"} </Typography>


    <Paper className={classes.root}>

      <TextField
        id="DrawingNumber"
        label="Drawing Number"
        value={DrawingNumber}
        onChange={ChangeHandler}
        variant="outlined"
        margin="normal"

        className={classes.Fieldsspace}
        helperText={drawingError.Error ? drawingError.Text : null}
        error={drawingError.Error}
        FormHelperTextProps={{ classes: { root: classes.helperText } }}

      />

      <TextField
        id="Description"
        label="Description"
        value={desc}
        onChange={ChangeHandler}
        variant="outlined"
        margin="normal"
        className={classes.Fieldsspace}
        helperText={descError.Error ? descError.Text : null}
        error={descError.Error}
        FormHelperTextProps={{ classes: { root: classes.helperText } }}

      />

      <TextField
        id="Model"
        label="Model"
        value={Model}
        onChange={ChangeHandler}
        variant="outlined"
        margin="normal"
        className={classes.Fieldsspace}
        helperText={ModelError.Error ? ModelError.Text : null}
        error={ModelError.Error}
        FormHelperTextProps={{ classes: { root: classes.helperText } }}

      />

      <TextField
        id="NumSheets"
        label="NumSheets"
        value={NumSheets}
        className={classes.Fieldsspace}
        onChange={ChangeHandler}
        variant="outlined"
        margin="normal"
        helperText={NumSheetsError.Error ? NumSheetsError.Text : null}
        error={NumSheetsError.Error}
        FormHelperTextProps={{ classes: { root: classes.helperText } }}

      />  <br />

      <TextField
        id="Revision"
        label="Revision"
        value={Revision}
        onChange={ChangeHandler}
        className={classes.Fieldsspace}
        variant="outlined"
        margin="normal"
        helperText={revisionError.Error ? revisionError.Text : null}
        error={revisionError.Error}
        FormHelperTextProps={{ classes: { root: classes.helperText } }}

      />
      <FormControl required className={classes.formControl} error={classificationError.error}>
        <InputLabel htmlFor="classfication">Classfication</InputLabel>
        <Select
          value={Classificationkey.Name}
          onChange={onclassficationChange}
          inputProps={{
            name: 'classfication',
            id: 'classfication'
          }}
          required
        >
          {ClassficationData.map((need, i) =>
            <MenuItem key={i} value={need.id}>{need.description}</MenuItem>
          )}
        </Select>
        {classificationError.Error ? <p style={{ color: "#f44336" }}>{classificationError.Text}</p> : null}
      </FormControl>
      <DatePicker
        label="Release Date"
        value={fromDate}
        onChange={(newValue) => onEffectiveDateChange(newValue)}
        renderInput={(params) => <TextField {...params} />}
      />
      <br />
      <br />
      <React.Fragment>
        <input type="file" id="inputFile" className="form-control-file inputfile" onChange={getFileContext} data-title="Select Drawing or Drag drop a Drawing" />
        {
          FileUploadError ? <Alert severity="error" >
            <AlertTitle>Please select one drawing Pdf file </AlertTitle>

          </Alert> : null
        }
      </React.Fragment>
      {
        Filename ? <Alert severity="success" >
          <AlertTitle>{Filename} is Selected </AlertTitle>

        </Alert> : null
      }
      <br />
      <span className={classes.wrapper}>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={onSubmit}
          className={classes.FieldsspaceForButton}
        >
          {Edit ? "Update" : "Upload"}
        </Button>

        {loading && <CircularProgress size={24} className={classes.buttonProgressSearch} />}
        {Edit ?
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={OnClear}
            className={classes.FieldsspaceForButton}
          >
            Clear
                </Button>

          : null
        }
      </span>

      <Snackbar
        open={SnackBarOpen}
        autoHideDuration={8000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={AlertColor === "success" ? "success" : "error"}>
          {SnackBarText}
        </Alert>
      </Snackbar>
    </Paper>
    <br />
    </div>
    :
    <Paper>
    <Typography variant="h6">
    <ErrorIcon />&nbsp;Access Denied
    </Typography>
    </Paper>
    }


    </Container>

    </LocalizationProvider>
    )
}
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getGraphqlUrl } from "./baseURL";

export const GraphQlClient = (currentUser) => {
  const httpLink = createHttpLink({
    uri: getGraphqlUrl(),
  });
  
  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${currentUser.jwtIdToken.idToken}`,
      }
    }
  });
  
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });

    return client;
}
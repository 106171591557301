import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import {
    Container,
    Typography,
    Snackbar,
    Alert
} from '@mui/material';
import Axios from 'axios';
import { getBaseURL } from '../../baseURL';
import { ColumnDirective, ColumnsDirective, ExcelExport, GridComponent, Inject, Page, Resize, Toolbar } from '@syncfusion/ej2-react-grids';

export const ActiveUsers = () => {
    const [loading, setLoading] = useState(false);
    const currentUser = useSelector(state => state.auth);
    const [isAdmin, setIsAdmin] = useState(null);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        if(isAdmin === null) {
            try {
                if (currentUser.user.permissions.find(p => p.permissionName === "System Admin").value === true) {
                    setIsAdmin(true);
                    loadActiveUser();
                } else {
                    setIsAdmin(false);
                }
            } catch (error) {
                setIsAdmin(false);
            }

            if (!isAdmin) {
                try {
                    if (currentUser.user.permissions.find(p => p.permissionName === "User Admin").value === true) {
                        setIsAdmin(true);
                        loadActiveUser();
                    } else {
                        setIsAdmin(false);
                    }
                } catch (error) {
                    setIsAdmin(false);
                }
            }
        };
    }, []);

    const loadActiveUser = () => {
        setLoading(true);
        setOpen(true);
        Axios.post(`${getBaseURL()}/Files/excelActiveUser`, {
            headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken }
        }).then((response) => {
            if (response.data.length > 0) {
                setData(response.data);
                setLoading(false);
                setOpen(false);
            } else {
                setLoading(false);
                setOpen(false);
            }
        }).catch((error) => {
            console.error('Error Downloading Excel File: ', error);
            setLoading(false);
            setOpen(false);
        })
    }
    let grid;
    const toolbar = ['ExcelExport'];
    const toolbarClick = (args) => {
        const excelExportProperties = {
            fileName: "Active User" + '.xlsx'
        }
        grid.excelExport(excelExportProperties);
    }
    const pageOptions = {
        pageSize: 50, pageSizes: false
    };

    return (
        <Container maxWidth={false} disableGutters={true}>
            <Typography variant='h5'>Active Users Report</Typography>
            {
                <div>
                    <GridComponent dataSource={data} height={550} allowPaging={true} toolbar={toolbar} allowExcelExport={true} toolbarClick={toolbarClick} ref={g => grid = g} pageSettings={pageOptions} allowResizing={true}  >
                        <ColumnsDirective>
                            <ColumnDirective field='firstName' headerText='First Name'></ColumnDirective>
                            <ColumnDirective field='lastName' headerText='Last Name'></ColumnDirective>
                            <ColumnDirective field='emailAddress' headerText='Email Address'></ColumnDirective>
                            <ColumnDirective field='department' headerText='Department'></ColumnDirective>
                            <ColumnDirective field='location' headerText='Location'></ColumnDirective>
                            <ColumnDirective field='jobTitle' headerText='Job Title'></ColumnDirective>
                            <ColumnDirective field='managerEmail' headerText='Manager Email'></ColumnDirective>
                            <ColumnDirective field='classLevel' headerText='Class Level'></ColumnDirective>
                            <ColumnDirective field='isEmailFiles' headerText='Email' ></ColumnDirective>
                            <ColumnDirective field='systemAdm' headerText='Admin' ></ColumnDirective>
                            <ColumnDirective field='isPrt' headerText='Print' ></ColumnDirective>
                            <ColumnDirective field='viewBtn' headerText='View' ></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Page, Toolbar, ExcelExport, Resize]}></Inject>
                    </GridComponent>
                    <Snackbar
                        open={open}
                        autoHideDuration={120000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                        <Alert
                            // onClose={handleClose}
                            severity='success'
                            variant='filled'>
                            Loading Active Users Data..! Just a moment it will be available shortly!
                        </Alert>
                    </Snackbar>
                </div>
            }
        </Container>
    );
}
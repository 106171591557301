import React, { useState, useEffect } from "react";
import { Container, Button, Snackbar, MenuItem, TablePagination } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { green, red } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useSelector } from "react-redux";
import Axios from "axios";
import { getBaseURL } from "../../baseURL";
import MuiAlert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { TablePaginationActions } from '../../actions/TablePaginationActions';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
export const ManageLogs = () => {
    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(2, 2),
        },
        paperSpacing: {
            paddingTop: "30px",
        },
        wrapper: {
            position: 'relative',
        },
        buttonSuccess: {
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[700],
            },
        },
        buttonError: {
            backgroundColor: red[500],
            '&:hover': {
                backgroundColor: red[700],
            },
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -5,
            marginLeft: -12,
        },
        paperSpacing: {
            paddingTop: "30px",
        },
        helperText: {
            marginLeft: 0,
        },
    }));

    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const[isAdmin, setIsAdmin] = useState(null);
    const [logsData, setLogsData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const currentUser = useSelector(state => state.auth);
    const [pageloading, setPageLoading] = useState(false);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, logsData.length - page * rowsPerPage);

    const config = {
        headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken }
    };
   
    useEffect(() => {
        GetApiLogs();
        if(isAdmin === null) {
            try {
                if(currentUser.user.permissions.find(p => p.permissionName === "System Admin").value === true) {
                    setIsAdmin(true);
                }
                else {
                    setIsAdmin(false);
                }
            }
            catch(error) {
                setIsAdmin(false);
            }
    
            if(!isAdmin) {
                try {
                    if(currentUser.user.permissions.find(p => p.permissionName === "User Admin").value === true) {
                        setIsAdmin(true);
                    }
                    else {
                        setIsAdmin(false);
                    }
                }
                catch(error) {
                    setIsAdmin(false);
                }
            }
        }
    }, []);

    const GetApiLogs = () => {
        Axios.get(`${getBaseURL()}/admin/GetApiLogs`, config)
            .then(response => {
                setLogsData(response.data);
                setPage(0);
            })
            .catch(error => {
                console.log(error)
            })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    return (
        <Container maxWidth={false} disableGutters={true}>
            {isAdmin ?
                <Container maxWidth={false} disableGutters={true}>
                    {(logsData.length) > 0 ?
                        <Paper className={classes.paperSpacing} p={5}>
                            <Typography variant="h4" gutterBottom style={{ marginLeft: "10px" }}>Api Logs </Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Message</TableCell>
                                        <TableCell>Logger</TableCell>
                                        <TableCell>Level </TableCell>
                                        <TableCell>Log Date </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {logsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                                        return (
                                            <TableRow key={item.id}>
                                                <TableCell>{item.message}</TableCell>
                                                <TableCell>{item.logger}</TableCell>
                                                <TableCell> {item.level} </TableCell>
                                                <TableCell> {item.logged} </TableCell>
                                            </TableRow>
                                        );
                                    })}

                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 20]}
                                component="div"
                                count={logsData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    'aria-label': 'previous page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'next page',
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                            {pageloading ? <CircularProgress style={{ width: '40px', height: '40px', position: 'fixed', left: '50%', top: '50%' }} />
                                : null}
                        </Paper>
                        : <Typography variant="h4" gutterBottom>No Record Found </Typography>

                    }
                </Container>
                :
                <Paper className={classes.root}>
                    <Typography variant="h6">
                        <ErrorIcon />&nbsp;Access Denied
                    </Typography>
                </Paper>
            }
        </Container>
    );
}
import axios from "axios";
import { useSelector } from "react-redux";
import { getBaseURL } from "../../baseURL";
import Table from '@mui/material/Table';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Select from '@mui/material/Select';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TableRow from '@mui/material/TableRow';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState, useEffect } from "react";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Container, TextField, Button, Paper} from "@mui/material";
import { InPlaceEditorComponent } from '@syncfusion/ej2-react-inplace-editor';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { toast, ToastContainer } from 'react-toastify';

export const ManageUserRights = (props) => {
    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(1, 2),
            paddingBottom: "20px"
        },
        paperSpacing: {
            paddingTop: "30px"
        },
        appBar: {
            position: 'relative'
          },
          title: {
            marginLeft: theme.spacing(2),
            flex: 1
          },
          noLogs: {
            padding: 10,
            fontSize: "34px" //"2.125rem"
          }
    }));

    const classes = useStyles();

    const[user, setUser] = useState("");
    const[logs, setLogs] = useState([]);
    const[open, setOpen] = useState(false);
    const[results, setResults] = useState({});
    const[isAdmin, setIsAdmin] = useState(null);
    const[loading, setLoading] = useState(false);
    const [userEmails, setUserEmails] = useState([]);
    const [classifications, setClassifications] = useState([]);
    const[error, setError] = useState({Error: false, Text: ""});
    const[savingPermissions, setSavingPermissions] = useState({});
    const[userError, setUserError] = useState({Error: false, Text: ""});
    const [userEmail, setUserEmail ] = useState("");

    const currentUser = useSelector(state => state.auth);
    const config = {
        headers: {'Authorization': "Bearer " + currentUser.jwtIdToken.idToken}
    };

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} unmountOnExit />;
    });

    useEffect(() => {
        if(classifications.length === 0) {
            axios.get(`${getBaseURL()}/admin/getclassificationlevels`, config)
            .then((response) => {
                setClassifications(response.data);
            })
        }

        if(isAdmin === null) {
            try {
                if(currentUser.user.permissions.find(p => p.permissionName === "System Admin").value === true) {
                    setIsAdmin(true);
                }
                else {
                    setIsAdmin(false);
                }
            }
            catch(error) {
                setIsAdmin(false);
            }

            if(!isAdmin) {
                try {
                    if(currentUser.user.permissions.find(p => p.permissionName === "User Admin").value === true) {
                        setIsAdmin(true);
                    }
                    else {
                        setIsAdmin(false);
                    }
                }
                catch(error) {
                    setIsAdmin(false);
                }
            }
        }
    }, []);

    useEffect(() => {
        getAllUserEmails();
    }, []);

    const getAllUserEmails = () => {
        axios.get(`${getBaseURL()}/admin/GetAllUserEmails`, config)
            .then(response => {
                setUserEmails(response.data);
            })
            .catch(error => {
                console.log(error)
            })
    }

    const onUserChange = event => {
        setUser(event.target.value);

        if(event.target.value) {
            setUserError({Error: false, Text: ""});
            setError({Error: false, Text: ""});
        }
    }

    const onSearchClick = () => {
        if(user) {
            setResults([]);
            setLoading(true);

            const User = {
                email: user
            }

            axios.post(`${getBaseURL()}/admin/manageuserrights`, User, config)
            .then(res => {
                setResults(res.data);
                setLoading(false);
                setError({Error: false, Text: ""});
            })
            .catch(error => {
                setError({Error: true, Text: error.response.data.message});
                setLoading({Error: false, Text: ""});
            });
        }
        else {
            setResults([]);
            setUserError({Error: true, Text: "Please enter a username"});
        }
    }

    const onCheckChange = (event) => {
        var newResults = results;
        newResults.permissions.find(r => r.permission === parseInt(event.target.id, 10)).value = event.target.checked;

        var UserPermissionArgs = {
            user: newResults.guid,
            permission: parseInt(event.target.id, 10),
            newValue: event.target.checked
        }

        setSavingPermissions({permission: event.target.id, Saving: true, Error: false, Saved: false});

        axios.post(`${getBaseURL()}/admin/manageuserrights/updatepermission`, UserPermissionArgs, config)
            .then(res => {
                setSavingPermissions({permission: UserPermissionArgs.permission.toString(), Saving: false, Error: false, Saved: true}); 
            })
            .catch(err => {
                setSavingPermissions({permission: UserPermissionArgs.permission.toString(), Saving: false, Error: true, Saved: false}); 
            });

        setResults(newResults);
    }

    const onViewLogClick = (permission, userID) => {
        const UserLogsArgs = {
            userID: userID,
            permission: parseInt(permission, 10)
        }

        axios.post(`${getBaseURL()}/admin/userlogs`, UserLogsArgs, config)
            .then(res => {
                setLogs(res.data);
                setOpen(true);
            });
    }

    const handleClose = () => {
        setOpen(false);
        setLogs([]);
    }

    const disableCheckbox = (item) => {
        var disabled = {Disabled: false, Reason: ""};

        if(item.GrantSysAdmin) {
            if(results.find(r => r.permissionName === "System Admin").value === true) {
                if(item.GrantSysAdmin) {
                    disabled = {Disabled: true, Reason: "User is Sys Admin"};
                }
            }
            else {
                if(!currentUser.user.permissions.find(r => r.permissionName === item.permissionName) || !currentUser.user.permissions.find(r => r.permissionName === item.permissionName).value) {

                    disabled = {Disabled: true, Reason: "You must be a system admin to modify this permission"}
                }
            }
        }
        else {
            if(item.permissionName === "BOM Explode Access") {
                try {
                    if(currentUser.user.permissions.find(r => r.permissionName === "BOM Admin") || currentUser.user.permissions.find(r => r.permissionName === "System Admin")) {
                        if(currentUser.user.permissions.find(r => r.permissionName === "BOM Admin").value === false && currentUser.user.permissions.find(r => r.permissionName === "System Admin").value === false) {
                            disabled = {Disabled: true, Reason: "You must be a BOM or system admin to modify this permission"}
                        }
                    }
                }
                catch {
                    disabled = {Disabled: true, Reason: "You must be a BOM or system admin to modify this permission"}
                }
            }
        }

        return disabled;
    }

    const clearUserSearchClick = () => {
        setUser("");
        setError({Error: false, Text: ""});
    }

    const onUserSearchKeyPress = event => {
        if(event.key === 'Enter') {
            onSearchClick();
        }
    } 

    const onAccessLevelChange = (event) => {
        const UserAccessArgs = {
            UserID: results.guid,
            ClassLevel: event.target.value
        }

        axios.post(`${getBaseURL()}/admin/manageuserrights/updateaccesslevel`, UserAccessArgs, config)
            .then(res => {
                const User = {
                    email: user
                }
    
                axios.post(`${getBaseURL()}/admin/manageuserrights`, User, config)
                .then(res => {
                    setResults(res.data);
                    setLoading(false);
                    setError({Error: false, Text: ""});
                })
                .catch(error => {
                    setError({Error: true, Text: error.response.data.message});
                    setLoading({Error: false, Text: ""});
                });
            })
            .catch(err => {
                
            });
    }

    useEffect(() => {
        if(results && results.email) {
            setUserEmail(results.email);
         }
    }, [results]);

    const UPDATE_USER_EMAIL = gql`
        mutation UpdateUserEmail($guid: String!, $Email: String!) {
            updateUserEmail(guid: $guid, email: $Email) {
                emailAddress
            }
        }
    `;

    const [updateUserEmail, updateUserEmailResult ] = useMutation(UPDATE_USER_EMAIL);

    const onUserEmailChange = (event) => { 
        updateUserEmail({variables: {guid: results.guid, Email: event.value}})
            .then(x => {
                toast.success(`Updated user email successfully`, {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    hideProgressBar: false
            });
            })
            .catch(err => {
                toast.error(`A problem occured update user email`, {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    hideProgressBar: false
                });

                setUserEmail(event.previousValue);
            });
    }

    return (
        <Container maxWidth={false} disableGutters={true}>
            {isAdmin ?
                <Container maxWidth={false} disableGutters={true}>
                    <Dialog fullScreen open={open} onClose={handleClose}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                size="large">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                User Permission Log
                            </Typography>
                        </Toolbar>
                        </AppBar>
                        {logs.length > 0 ? 
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>User Email</TableCell>
                                            <TableCell>Admin Email</TableCell>
                                            <TableCell>Permission Name</TableCell>
                                            <TableCell>Previous Value</TableCell>
                                            <TableCell>New Value</TableCell>
                                            <TableCell>Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {logs.map((item, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>{item.user.email}</TableCell>
                                                    <TableCell>{item.admin.email}</TableCell>
                                                    <TableCell>{item.permission.name}</TableCell>
                                                    <TableCell>{String(item.oldValue)}</TableCell>
                                                    <TableCell>{String(item.newValue)}</TableCell>
                                                    <TableCell>{new Date(item.logDate).toLocaleDateString()}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            : <span className={classes.noLogs}>No Logs Found</span>}
                    </Dialog>
                    <Typography variant="h4" gutterBottom>Manage User Rights</Typography>
                    <Paper className={classes.root}>
                        <Autocomplete
                            id="user"
                            freeSolo
                            onChange={(event, newValue) => {
                                setUser(newValue);
                                if(newValue != null){
                                    if(newValue.length > 0) {
                                        setUserError({Error: false, Text: ""});
                                        setError({Error: false, Text: ""});
                                    }
                                }
                            }}
                            options={userEmails.map((option) => option.email)}
                            renderInput={(params) => <TextField {...params} 
                            onChange={onUserChange}
                            label="User Email Address"
                            variant="outlined"
                            margin="normal"
                            value={user}
                            fullWidth
                            error={userError.Error}
                            helperText={userError.Error ? userError.Text : null}
                            onKeyPress = {onUserSearchKeyPress} />}
                        />
                        <Button variant="contained" color="primary" onClick={onSearchClick}><SearchIcon/>&nbsp; Search</Button>
                        {error.Error &&
                            <Typography variant="subtitle1" style={{color: "red"}}>{error.Text}</Typography>
                        }
                    </Paper>
                    <br />
                    <br />
                    {results.permissions !== undefined &&
                        <div>
                            <div style={{display: "flex", alignItems: 'center', marginLeft: '10px', marginTop: '10px'}}>
                                <Typography variant="h6">Email: </Typography><InPlaceEditorComponent value={userEmail} mode="inline" type='Text' change={(e) => onUserEmailChange(e)}/>
                            </div>
                            <br/>
                            <Paper className={classes.root}>
                                <Typography variant="subtitle1" style={{display: 'inline-block', marginTop: '10px'}}>Access Level:&nbsp;</Typography>
                                <Select
                                    value={results.classification != null ? results.classification.id : ""}
                                    onChange={onAccessLevelChange}
                                    variant="standard"
                                >
                                    {classifications.map((item, index) => {
                                        return(
                                            <MenuItem key={index} value={item.id}>{item.description}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </Paper>
                            <br />
                            <br/>
                        </div>
                    }
                    {results.permissions !== undefined &&
                        <Paper>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Permission Name</TableCell>
                                        <TableCell>Enabled</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {results.permissions.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{item.permissionName}</TableCell>
                                        <TableCell>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox 
                                                        // disabled={disableCheckbox(item).Disabled} 
                                                        id={item.permission.toString()} 
                                                        checked={item.value}
                                                        onChange={onCheckChange} 
                                                        value={item.permission} color="primary" 
                                                        disabled={disableCheckbox(item).Disabled}
                                                    />
                                                }
                                            />
                                            &nbsp;
                                            {disableCheckbox(item).Disabled && <span>{disableCheckbox(item).Reason}</span>}
                                            {(savingPermissions.permission === item.permission && savingPermissions.Saving && !savingPermissions.Saved && !savingPermissions.Error) && <span>Saving</span>}
                                            {(savingPermissions.permission === item.permission && !savingPermissions.Saving && savingPermissions.Saved && !savingPermissions.Error) && <span>Saved</span>}
                                            {(savingPermissions.permission === item.permission && !savingPermissions.Saving && !savingPermissions.Saved && savingPermissions.Error) && <span>Error</span>}
                                        </TableCell>
                                        <TableCell><Button onClick={() => onViewLogClick(item.permission, item.guid)} color="primary">View Logs</Button></TableCell>
                                    </TableRow>
                                );
                            })}
                                </TableBody>
                            </Table>
                            <ToastContainer
                                position="top-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                            />
                    </Paper>
                    }
                </Container>
            :
                <Paper className={classes.root}>
                    <Typography variant="h6">
                        <ErrorIcon />&nbsp;Access Denied
                    </Typography>
                </Paper>
            }
        </Container>
    );
}
export const getBaseURL = () => {
    if(window.location.hostname.includes("localhost")) {
        return "https://localhost:5001/api/v1";
    }
    else if (window.location.hostname.includes("ktip.jgi.joyglobalinc.com") || window.location.hostname.includes("ktip.mining.komatsu")) {
        //Prod
        return "https://ktip-api.jgi.joyglobalinc.com/api/v1"
    }
    else if(window.location.hostname.includes("kviewbeta2.jgi.joyglobalinc.com") || window.location.hostname.includes("ktip-dev.mining.komatsu") || window.location.hostname.includes("ktip-beta.mining.komatsu")) {
        return "https://ktip-api-beta.jgi.joyglobalinc.com/api/v1"
    }
    else if(window.location.hostname.includes("kmc-ktip-test.azurewebsites.net")) {
        console.log("azure test hostname");
        return "https://kmc-ktip-api-test.azurewebsites.net/api/v1"
    }
}

export const getGraphqlUrl = () => {
    if(window.location.hostname.includes("localhost")) {
        return "https://localhost:6001/graphql";
    }
    else if(window.location.hostname.includes("kviewbeta2.jgi.joyglobalinc.com") || window.location.hostname.includes("ktip-dev.mining.komatsu") || window.location.hostname.includes("ktip-beta.mining.komatsu")) {
        return "https://ktip-gql-beta.jgi.joyglobalinc.com/graphql"
    }
    else if (window.location.hostname.includes("ktip.jgi.joyglobalinc.com") || window.location.hostname.includes("ktip.mining.komatsu")) {
        //Prod
        return "https://ktip-gql.jgi.joyglobalinc.com/graphql"
    }
}

import React, { useState, useEffect } from "react";
import { Container, TextField, Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import { green, red } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper'
import { useSelector } from "react-redux";
import Axios from "axios";
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import clsx from 'clsx';
import { getBaseURL } from "../../baseURL";
import { RichTextEditorComponent, Toolbar as syncfusionToolbar, Inject, Link, HtmlEditor, Count, QuickToolbar, Table } from '@syncfusion/ej2-react-richtexteditor';

export const FeatureRequest = () => {
  const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(2, 2),
    },
    paperSpacing: {
      paddingTop: "30px",
    },
    wrapper: {
      position: 'relative',
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    buttonError: {
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[700],
      },
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -5,
      marginLeft: -12,
    },
    paperSpacing: {
      paddingTop: "30px",
    },
    helperText: {
      marginLeft: 0,
    },
  }));

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState({ Error: false, Text: "" });
  const [featureTitle, setFeatureTitle] = useState("");
  const [featureTitleError, setFeatureTitleError] = useState({ Error: false, Text: "" });
  const [featureDescription, setFeatureDescription] = useState("");
  const [featureDescriptionError, setFeatureDescriptionError] = useState({ Error: false, Text: "" });
  const [featureAcceptanceCriteria, setFeatureAcceptanceCriteria] = useState("");
  const [editor, setEditor] = useState();
  const [AzureDevopsFeatureUrl, setAzureDevopsFeatureUrl] = useState("");
  const [AzureDevopsSecretkey, setAzureDevopsSecretkey] = useState("");
  const [FeatureRequestEnable, setFeatureRequestEnable] = useState(false);
  const buttonClassname = clsx({
    [classes.buttonError]: submitError.Error,
  });



  const currentUser = useSelector(state => state.auth);

  const toolbarItems = ['Bold', 'Italic', 'Underline', 'StrikeThrough',
    'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
    'LowerCase', 'UpperCase', '|',
    'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
    'Outdent', 'Indent', 'SuperScript', 'SubScript', '|',
    'CreateTable', 'CreateLink', '|', 'ClearFormat',
    '|', 'Undo', 'Redo'];

  const toolbarSettings = { items: toolbarItems }

  const onFeatureTitleChange = event => {
    setFeatureTitle(event.target.value);

    if (event.target.value) {
      setFeatureTitleError({ Error: false, Text: "" });
    }
  }
  const config = {
    headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken,}
  };

  const onFeatureDescriptionChange = event => {
    setFeatureDescription(editor.value.replace(/"/g, "\\\"").replace(/\//g, "\\/"));

    if (editor.value) {
      setFeatureDescriptionError({ Error: false, Text: "" });
    }
  }
 useEffect(() => {         
        getAzureSecretkey();
        setFeatureRequestEnable(currentUser.featurecontrolList.find(x => x.name == "Request Feature").grantByAdmin)
       
      }, []);
      
    const getAzureSecretkey = () => {      
        Axios.get(`${getBaseURL()}/admin/AzuredevopsApiUrl`, config)
            .then(response => {               
               setAzureDevopsFeatureUrl(response.data.message.azureDevopsFeatureUrl)
               setAzureDevopsSecretkey(response.data.message.secretkey)
            })
            .catch(error => {
                console.log(error)
            })
    }
  const onSubmit = () => {
    if (featureDescription.length>=200 && featureTitle.length>=55) {
      const featurePatchJson = `[
        {
          "op": "add",
          "path": "/fields/System.Title",
          "from": null,
          "value": "${featureTitle}"
        },
        {
          "op": "add",
          "path": "/fields/System.Description",
          "from": null,
          "value": "${featureDescription}"
        },
        {
          "op": "add",
          "path": "/fields/System.History",
          "from": null,
          "value": "Submitted by ${currentUser.aadResponse.account.name}, email address ${currentUser.aadResponse.account.userName}"
        }
      ]`

      const devOpsAxiosConfig = {
        headers: {
          'Authorization':'Basic ' + AzureDevopsSecretkey,
          'Content-Type': 'application/json-patch+json',   
        }
      }

      const prodDevOpsApiFeatureUrl = AzureDevopsFeatureUrl;    
      setIsLoading(true);
      Axios.post(prodDevOpsApiFeatureUrl, featurePatchJson, devOpsAxiosConfig)
        .then(() => {          
          setIsLoading(false);
          setIsSubmitted(true);
        })
        .catch((error) => {
          setSubmitError({ Error: true, Text: error.response.data.message });
          setIsLoading(false);
        });
    }
    else {
      if (featureTitle.length<=55) {
        setFeatureTitleError({ Error: true, Text: "Summary must have at least 55 characters" });
      }
      if (featureDescription.length<=200) {
        setFeatureDescriptionError({ Error: true, Text: "Description must have at least 200 characters" });
      }
    }
  }

  return (
    <Container>
      {FeatureRequestEnable?
<React.Fragment>
      <Typography variant="h4">Request a Feature</Typography>
      {!isSubmitted ?
        <React.Fragment>
          <Paper className={classes.root}>
            <TextField
              id="featureTitle"
              value={featureTitle}
              onChange={onFeatureTitleChange}
              variant="outlined"
              label="Summary of the feature"
              fullWidth
              helperText={featureTitleError.Error ? featureTitleError.Text : null}
              error={featureTitleError.Error}
              FormHelperTextProps={{ classes: { root: classes.helperText } }}
              style={{ marginTop: '10px' }}
            />
            <br />
            <br />
            <span className="h4">Please go into detail. And let us know what makes your request important 😊</span>
            <RichTextEditorComponent
              style={{ marginTop: '10px' }}
              id="featureDescription"
              showCharCount={false}
              toolbarSettings={toolbarSettings}
              height="400"
              // style={{overflow: "hidden"}}
              ref={editor => setEditor(editor)}
              change={onFeatureDescriptionChange}
            >
              <Inject services={[syncfusionToolbar, Link, HtmlEditor, Count, QuickToolbar, Table]} />
            </RichTextEditorComponent>
            {featureDescriptionError.Error?<span style={{color:"red"}}>{featureDescriptionError.Text}</span>:null}
      
            <br />
            <br />
            <span className={classes.wrapper}>
              <Button
                id="submit"
                color="primary"
                variant="contained"
                onClick={onSubmit}
                className={buttonClassname}
                disabled={isLoading}
              >
                {(!isLoading && !submitError.Error) && <SendIcon />}
                {(!isLoading && submitError.Error) &&
                  <ErrorIcon />
                }
              &nbsp;Submit
            </Button>
              {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </span>

            {submitError.Error &&
              <Typography variant="subtitle1" style={{ color: "red", marginTop: 10 }}>{submitError.Text}</Typography>
            }
          </Paper>
        </React.Fragment>
        :
        <Paper className={classes.root}>
          <Typography variant="h6">
            <CheckIcon />&nbsp;Your feature request has been sent to the development team.
            </Typography>
        </Paper>

      }
</React.Fragment>
:
<Paper>
<Typography variant="h6">
    <ErrorIcon />&nbsp;Access Denied
</Typography>
</Paper>
}
    </Container>
  )
}
import clsx from 'clsx';
import Axios from "axios";
import gql from 'graphql-tag';
import "../miscellaneous/File.css";
import { useSelector } from "react-redux";
import Chip from '@mui/material/Chip';
import { useQuery } from '@apollo/client';
import { getBaseURL } from "../../baseURL";
import Table from '@mui/material/Table'; 
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import MuiAlert from '@mui/material/Alert';
import { useHistory } from "react-router-dom";
import Drafts from '@mui/icons-material/Drafts';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import Toolbar from '@mui/material/Toolbar';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import TableRow from '@mui/material/TableRow';
import SearchIcon from '@mui/icons-material/Search';
import { getPdfFiles } from '../../actions/actions';
import FormGroup from '@mui/material/FormGroup';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { green, red } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Autocomplete from '@mui/material/Autocomplete';
import DownloadIcon  from '@mui/icons-material/CloudDownload';
import CircularProgress from '@mui/material/CircularProgress';
import { TablePaginationActions } from '../../actions/TablePaginationActions';
import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Container, Paper, Snackbar, TextField, Button, FormControlLabel, Checkbox, TablePagination } from "@mui/material";
import { RichTextEditorComponent, Toolbar as syncfusionToolbar, Inject, Link, HtmlEditor, Count, QuickToolbar, Table  as syncfusionTable, Image, FileManager } from '@syncfusion/ej2-react-richtexteditor';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export const DraftEmailFiles = forwardRef((props, ref) => {

    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(2, 2)
        },
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative'
        },
        buttonSuccess: {
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[700]
            },
        },
        buttonError: {
            backgroundColor: red[500],
            '&:hover': {
                backgroundColor: red[700]
            },
        },
        fabProgress: {
            color: green[500],
            position: 'absolute',
            top: -6,
            left: -6,
            zIndex: 1
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -5,
            marginLeft: -12
        },
        appBar: {
            position: 'relative'
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1
        },
        subjectCss: {
            fontWeight: 550,
            cursor: 'pointer'
        },
        buttonProgressSearch: {
            color: green[500],
            position: 'absolute',
            marginTop: 11,
            marginLeft: -68
        },
        buttonProgressUpdate: {
            color: green[500],
            position: 'absolute',
            marginTop: 17,
            marginLeft: -99
        }
    }));

    const GET_EMAIL_GROUPS = gql`
      query emailGroups($id: Int, $groupName: String) {
          emailGroups(id: $id, groupName: $groupName) {
              id,
              groupName
          }
      }
    `;

    let history = useHistory();
    

    const classes = useStyles();
    const [email, setEmail] = useState([]);
    const [editor, setEditor] = useState();
    const [sent, setSent] = useState(false);
    const [open, setOpen] = useState(false);
    const [page, setPage] = React.useState(0);
    const [NewEmail, setNewEmail] = useState("");
    const [drawings, setDrawings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sentemail, setSentEmails] = useState([]);
    const [AlertColor, setAlertColor] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(3);
    const [isLoading, setIsLoading] = useState(false);
    const [emailGroups, setEmailGroups] = useState([]);
    const [savedDraft, setSavedDraft] = useState(false);
    const [SnackBarText, setSnackBarText] = useState("");
    const [customMessage, setCustomMessage] = useState("");
    const [documentNames, setDocumentNames] = useState("");
    const [exactResults, setExactResults] = useState(false);
    const [SnackBarOpen, setSnackBarOpen] = useState(false);
    const [searchDrawings, setSearchDrawings] = useState([]);
    const [isAlteredDraft, setIsAlteredDraft] = useState(false);
    const [hasEmailChanged, setHasEmailChanged] = useState(false);
    const [saveDraftLoading, setSaveDraftLoading] = useState(false);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [hasMessageChanged, setHasMessageChanged] = useState(false);
    const [hasSubjectChanged, setHasSubjectChanged] = useState(false);
    const [isEditButtonClicked, setIsEditButtonClicked] = useState(false);
    const [sendError, setSendError] = useState({ error: false, text: "" });
    const [draftError, setDraftError] = useState({ error: false, text: "" });
    const [emailError, setEmailError] = useState({ error: false, text: "" });
    const [selectEmailGroupsOpen, setSelectEmailGroupsOpen] = useState(false);  
    const [hasEmailGroupsChanged, setHasEmailGroupsChanged] = useState(false);
    const [drawingError, setDrawingError] = useState({ error: false, text: "" });
    const [subject, setSubject] = useState("Komatsu Mining Corp. Shared Drawings");
    const [documentNameError, setDocumentNameError] = useState({ Error: false, Text: "" });
    const { refetch } = useQuery(GET_EMAIL_GROUPS, { onCompleted: data => { setEmailGroups(data.emailGroups) } });

    const currentUser = useSelector(state => state.auth);
    const config = {
        headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken },
        timeout: 240000
    };

    // const Alert = (props) => {
    //     return <MuiAlert elevation={6} variant="filled" {...props} />;
    // }

    const toolbarItems = ['Bold', 'Italic', 'Underline', 'StrikeThrough',
        'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
        'LowerCase', 'UpperCase', '|',
        'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
        'Outdent', 'Indent', 'SuperScript', 'SubScript', '|',
        'CreateTable', 'Image', 'FileManager', 'CreateLink', '|', 'ClearFormat', 'Print',
        '|', 'Undo', 'Redo'];

    const toolbarSettings = { items: toolbarItems }

    const FileManagerSettingsModel = {
        enable: true,
        path: '/',
        ajaxSettings: {
            url: `${getBaseURL()}/Files/FileOperationsForDraft?userEmail=${currentUser.user.email}&draftId=${props.draftId}`,
            uploadUrl: `${getBaseURL()}/Files/UploadForDraft?userEmail=${currentUser.user.email}&draftId=${props.draftId}`,
        },

        contextMenuSettings: {
            visible: true,
            file: ['Delete', '|', 'Details'],
            folder: ['Delete', '|', 'Details'],
            layout: ['SortBy', 'View', 'Refresh', '|', 'Upload', '|', 'Details']
        },
        navigationPaneSettings: {
            visible: true,
            items: [
                'Upload', 'Delete', 'Download',
                'SortBy', 'Refresh', 'Selection', 'View', 'Details'
            ]
        },
        toolbarSettings: { visible: true, items: ['Upload', 'SortBy', 'Refresh', 'View', 'Delete'] },
        uploadSettings: { allowedExtensions: ".doc, .docx, .pdf, .xls, .xlsx" }

    }
    const buttonClassname = clsx({
        [classes.buttonSuccess]: sent,
        [classes.buttonError]: sendError.error,
    });

    const saveDraftButtonClassname = clsx({
        [classes.buttonSuccess]: savedDraft,
        [classes.buttonError]: draftError.error,
    });

    useImperativeHandle(ref, () => ({
        refFunc() {
            onDraftSubmit();
        },
        checkDraftAltered() {
            return isDraftDataAltered();
        }
    }))

    const saveCustomMessage = () => {
        setHasMessageChanged(true);
        setCustomMessage(editor.value);
        setIsAlteredDraft(true);
        handleClose();
    }

    useEffect(() => {
        getSentEmails();
    }, []);
    const getSentEmails = () => {
        Axios.get(`${getBaseURL()}/faq/getSentEmails`, config)
            .then(response => {
                
                setSentEmails(response.data);
            })
            .catch(error => {
                console.log(error)
            })
    }

    const setCustomMessageText = () => {
        if (editor) {
            var editorCopy = editor;
            editorCopy.value = customMessage;
            setEditor(editorCopy);
        }
    }

    useEffect(() => {
        if (props.drawings !== drawings) {
            if (sent) {
                setSent(false);
            }
            if(savedDraft) {
                setSavedDraft(false);
            }
        }
        setDrawings(props.drawings);

        if(props.sendEmailList !== email && !hasEmailChanged) {
            if(sentemail.length > 0) {
                if(props.sendEmailList.length > 0) {
                    setEmail(props.sendEmailList);
                }
            }
        }

        if(!hasMessageChanged) {
            setCustomMessage(props.message);
        }

        if(!hasSubjectChanged) {
            setSubject(props.draftSubject);
        }

        setIsEditButtonClicked(props.actionType == 'Edit' ? true : false);

        if (drawings.length > 0 && drawingError.error) {
            setDrawingError({ error: false, text: "" });
        }

        if (customMessage !== null && customMessage !== undefined) {
            setCustomMessageText();
        }
    });

    const setSelectedEmailGroups = () => {
        if(emailGroups.length > 0 && !hasEmailGroupsChanged) {
            var emailGroupsArray = emailGroups.map(g => ({ ...g, checked: props.emailGroupsList.some(group => group != null && group.id === g.id) ? true: Boolean(g.checked) }));
            setEmailGroups(emailGroupsArray);
        }
    }

    const onDocumentNamesChange = event => {
        setDocumentNames(event.target.value);

        if (event.target.value) {
            setDocumentNameError({ Error: false, Text: "" });
        }
    }

    const onLatestChanged = event => {
        setExactResults(event.target.checked);
    }

    const onSearchDrawings = () => {
        if (documentNames) {
            setSelectAllChecked(false);
            setIsLoading(true);
            setDocumentNameError({ Error: false, Text: "" });
            var document = {
                DrawingNumber: documentNames,
                ExactResults: exactResults
            }

            Axios.post(`${getBaseURL()}/documents/multipleiteminquiry`, document, config)
                .then((response) => {
                    setSearchDrawings(response.data);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setDocumentNameError({ Error: true, Text: error.response.data.message });
                    setIsLoading(false);
                });
        }
        else {
            setDocumentNameError({ Error: true, Text: "Enter Document Name(s)" });
        }
    }

    const onSubmit = () => {
        if (sent) {
            setAlertColor("info")
            setSnackBarOpen(true);
            setSnackBarText("Email has already been sent!");
        }
        else {
            var isError = false;
            if (drawings.length < 1) {
                setDrawingError({ error: true, text: "Select at least one drawing" });
                isError = true;
            }
            if (email.length < 1 && NewEmail.length < 1) {
                setEmailError({ error: true, text: "Enter email address" });
                isError = true;
            } else {
                setEmailError({ error: false, text: "" });
            }
            if (!isError) {
                setLoading(true);
                setDrawingError({ error: false, text: "" });
                setEmailError({ error: false, text: "" });
                setSent(false);
                setSendError({ error: false, text: "" });
                var selectedDrawings = drawings;
                var selectedSearchDrawings = searchDrawings.filter(x => x.isChecked == true);
                var newDrawings = selectedDrawings.concat(selectedSearchDrawings);
                newDrawings = newDrawings.filter((el, i, a) => i === a.indexOf(el));
                const Message = {
                    Drawings: newDrawings,
                    toEmails: email,
                    Email: NewEmail,
                    CustomMessage: customMessage,
                    Subject: subject,
                    Groups: emailGroups.filter(x => x.checked === true),
                    CurrentUserEmail: currentUser.user.email
                }

                Axios.post(`${getBaseURL()}/documents/emailfiles`, Message, config)
                    .then((response) => {
                        setSent(true);
                        setLoading(false);
                        setAlertColor("success")
                        setSnackBarOpen(true);
                        setSnackBarText("The email has been sent.");
                        setTimeout(()=>{
                            props.handleUpdateClose(false);
                        },1000);
                    })
                    .catch((error) => {
                        setLoading(false);
                        setSendError({ error: true, text: error.response.data.message })
                    });
            }
        }
    }

    const onDraftSubmit = () => {
        var isDraftUpdated = isDraftDataAltered();
        if(isDraftUpdated) {
            var isError = false;
            if (email.length < 1 && NewEmail.length < 1) {
                setEmailError({ error: true, text: "Enter email address" });
                isError = true;
            } else {
                setEmailError({ error: false, text: "" });
            }
    
            if (!isError) {
                setSaveDraftLoading(true);
                setDrawingError({ error: false, text: "" });
                setEmailError({ error: false, text: "" });
                setSavedDraft(false);
                setDraftError({ error: false, text: "" });
                let drawingIds = drawings.map(({ id }) => id );
                let searchDrawingIds = searchDrawings.filter(x => x.isChecked == true).map(({ id }) => id );
                let newDrawingIds = drawingIds.concat(searchDrawingIds);
                newDrawingIds = newDrawingIds.filter((el, i, a) => i === a.indexOf(el));
                let groupIds = emailGroups.filter(x => x.checked === true).map(({ id }) => id )
                const saveDraftModel = {
                    Email: NewEmail,
                    Subject: subject,
                    DraftId: props.draftId,
                    Message: customMessage,
                    SendEmails: email.join(),
                    GroupIds: groupIds.join(),
                    DrawingIds: newDrawingIds.join()
                }
    
                Axios.post(`${getBaseURL()}/documents/SaveUpdateDraft`, saveDraftModel, config)
                    .then((response) => {
                        setSavedDraft(true);
                        setIsAlteredDraft(false);
                        setSaveDraftLoading(false);
                        setAlertColor("success")
                        setSnackBarOpen(true);
                        setSnackBarText(response.data.message);
                        setTimeout(()=>{
                            props.handleUpdateClose(false);
                        },1000);
                        
                        // setTimeout(() => {
                        //     window.location.reload();
                        // }, 1000);
                    })
                    .catch((error) => {
                        setSaveDraftLoading(false);
                        setAlertColor("")
                        setSnackBarOpen(true);
                        setSnackBarText(error.response.data.message);
                        setDraftError({ error: true, text: error.response.data.message })
                    });
            }
        }
        else {
            setAlertColor("info")
            setSnackBarOpen(true);
            setSnackBarText("You didn’t make any changes!");
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    const removeLoalAttachments = () => {
        Axios.get(`${getBaseURL()}/documents/RemoveCacheFiles`, config)
        .then(response => {
        })
        .catch(error => {
            console.log(error)
        })
        setOpen(false);
    }

    const onAddMessageClick = event => {  
        setOpen(true);
    }

    const onSubjectChange = (event) => {
        setSubject(event.target.value);
        setHasSubjectChanged(true);
    }

    const onEmailGroupCheckedChange = (groupId) => {
        var emailGroupsCopy = emailGroups.map(g => ({ ...g, checked: Boolean(g.checked) }));

        if(emailGroupsCopy.length > 0) {
            if (emailGroupsCopy.find(x => x.id === groupId).checked) {
                emailGroupsCopy.find(x => x.id === groupId).checked = false;
            }
            else {
                emailGroupsCopy.find(x => x.id === groupId).checked = true;
            }
        }

        setEmailGroups(emailGroupsCopy);
        setHasEmailGroupsChanged(true);
        setIsAlteredDraft(true);
    }

    const isEmailGroupChecked = (groupId) => {
        if(emailGroups.length > 0) {
            if (emailGroups.find(x => x.id === groupId).checked) {
                return true;
            }
            else {
                return false;
            }
        }
    }
    
    const handleChange = ev => {
        setNewEmail(ev.target.value);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const onCheckedChange = event => {
        var drawingsArray = [...drawings];
        if(drawingsArray.length > 0){
            if(drawingsArray.some(x => x.id == parseInt(event.target.id))){
                setAlertColor("")
                setSnackBarOpen(true);
                setSnackBarText("This drawing has already been saved in draft");
                return;
            }
        }
        var searchDrawingsArray = [...searchDrawings];
        searchDrawingsArray.filter(x => (x.classificationLevel <= currentUser.user.classification.classLevel || x.hasSpecialAccess) && x.id == event.target.id).forEach(x => x.isChecked = event.target.checked);
        setSearchDrawings(searchDrawingsArray);
    
        if (searchDrawingsArray.filter(x => x.isChecked).length === searchDrawingsArray.length) {
          setSelectAllChecked(true);
        }
        else {
          setSelectAllChecked(false);
        }
      }

    const onCheckAllChange = event => {
        var drawingsArray = [...drawings];
        var searchDrawingsArray = [...searchDrawings];
        let matchingDrawings = searchDrawingsArray.filter(o1 => drawingsArray.some(o2 => o1.id === o2.id));
        searchDrawingsArray.filter(x => (x.classificationLevel <= currentUser.user.classification.classLevel || x.hasSpecialAccess) && x.id.length !== 0 && x.id !== 0 && !drawingsArray.some(y => x.id === y.id)).forEach(x => x.isChecked = event.target.checked);
        if(matchingDrawings.length > 0 && event.target.checked){
            setAlertColor("")
            setSnackBarOpen(true);
            let message = matchingDrawings.length == 1 ? "One drawing has already been saved in draft"
            : "Several drawings have already been saved in draft";
            setSnackBarText(message);
        }
        setSearchDrawings(searchDrawingsArray);
        let selectedDrawings = searchDrawingsArray.filter(x => x.isChecked == true);
        if(selectedDrawings.length > 0 || !event.target.checked)
            setSelectAllChecked(event.target.checked);
    }

    const handleDownloadClick = (drawingId) => {
        var searchDrawingsArray = [...searchDrawings];
        searchDrawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = true);
        setSearchDrawings(searchDrawingsArray);

        var selectedFile = [...searchDrawings].filter(x => x.id === drawingId);
        selectedFile.find(x => x.id === drawingId).isChecked = true;

        getPdfFiles(selectedFile, currentUser.jwtIdToken.idToken)
            .then((response) => {
                searchDrawingsArray = [...searchDrawings];
                searchDrawingsArray.filter(x => x.id === drawingId).forEach(x => x.isChecked = false);
                searchDrawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = false);
                setSearchDrawings(searchDrawingsArray);
            });
    }

    const isDraftDataAltered = () => {
        let searchDrawingIds = searchDrawings.filter(x => x.isChecked == true);
        if (searchDrawingIds.length > 0) {
            return true;
        }
        return isAlteredDraft;
    }

    return (
        <Container maxWidth={false} disableGutters={true}>
            <Typography variant="subtitle1">Separate multiple documents with commas or by putting each document on a new line.</Typography>
            <TextField
                id="documentNames"
                label="Documents"
                multiline
                fullWidth
                margin="normal"
                variant="outlined"
                rows="10"
                value={documentNames}
                onChange={onDocumentNamesChange}
                error={documentNameError.Error}
                helperText={documentNameError.Error ? documentNameError.Text : null}
                FormHelperTextProps={{ classes: { root: classes.helperText } }}
            />
            <FormGroup row>
                <FormControlLabel control={<Checkbox checked={exactResults} color="primary" onChange={onLatestChanged} />} label="Exact Results" />
            </FormGroup>
            <span>
                <Button
                    color="primary"
                    variant="contained"
                    style={{ marginTop: "5px" }}
                    disabled={isLoading}
                    onClick={onSearchDrawings}
                >
                    <SearchIcon />&nbsp;Search
                </Button>
                {isLoading && <CircularProgress size={24} className={classes.buttonProgressSearch} />}
            </span>
            <br />
            {searchDrawings.length > 0 ?
                <Paper p={5}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><Checkbox id={"selectAll"} checked={selectAllChecked} onChange={onCheckAllChange} color="primary" /></TableCell>
                                <TableCell>Drawing Number</TableCell>
                                <TableCell>Revision</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Model</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {searchDrawings.filter(d => d.id !== 0).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                                return (
                                    <TableRow key={index.toString()}>
                                        <TableCell><Checkbox id={item.id.toString()} checked={item.isChecked} color="primary" onChange={onCheckedChange} disabled={(item.classificationLevel > currentUser.user.classification.classLevel && !item.hasSpecialAccess)} /></TableCell>
                                        <TableCell>{item.drawingNumber}</TableCell>
                                        <TableCell>{item.revision}</TableCell>
                                        <TableCell>{item.description}</TableCell>
                                        <TableCell>{item.model}</TableCell>
                                        {(item.classificationLevel <= currentUser.user.classification.classLevel || item.hasSpecialAccess) ?
                                            (item.documentCount && item.documentCount > 0) ?
                                                <TableCell>
                                                    <span className={classes.wrapper}>
                                                        <Button
                                                            color="primary"
                                                            onClick={() => handleDownloadClick(item.id)}
                                                            disabled={item.isDownloading}
                                                        >
                                                            <DownloadIcon />
                                                        </Button>
                                                        {item.isDownloading && <CircularProgress size={24} className={classes.buttonProgressDownload} />}
                                                    </span>
                                                </TableCell>
                                                :
                                                <TableCell>
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={() => history.push("/scanrequest/" + item.drawingNumber)}
                                                    >
                                                        Scan Request
                                                    </Button>
                                                </TableCell>
                                            : <TableCell>
                                                <Button color="primary" onClick={() => history.push("/specialaccessrequest/" + item.drawingNumber)}>Request Access</Button>
                                            </TableCell>}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[3, 6, 9]}
                        component="div"
                        count={searchDrawings.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </Paper>
                : null}
            <br />
            <Typography variant="subtitle1">Hit enter to separate multiple emails or separate with a semicolon</Typography>
            <Autocomplete
                multiple
                value={email}
                id="tags-filled"
                options={sentemail.map((option) => option.email)}
                freeSolo
                onChange={(event, newValue) => {
                    setHasEmailChanged(true);
                    setIsAlteredDraft(true);
                    setEmail(newValue);
                    if (newValue.length === 0) {
                        setNewEmail("")
                    }

                }}
                renderTags={(value, getTagProps) =>

                    value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                }

                renderInput={(params) => (
                    <TextField {...params} variant="filled" label="Email" placeholder="Enter Emails" className="AutocompleteEmails" required={true} required
                        error={emailError.error}
                        helperText={emailError.error ? emailError.text : null} onChange={handleChange} />
                )}
            />
            &nbsp;
            <Button
                color="primary"
                variant="contained"
                style={{ marginTop: ".75em", height: "38px", marginRight: "10px" }}
                onClick={onAddMessageClick}
            >
                {customMessage != null && customMessage.length > 0 ? <EditIcon /> : <AddCircleIcon />}&nbsp;
                {customMessage != null && customMessage.length > 0 ? "Edit Message" : "Add Message"}
            </Button>
            {
                (currentUser.user.permissions.find(p => p.permissionName === "Send Group Emails") && currentUser.user.permissions.find(p => p.permissionName === "Send Group Emails").value) &&
                <Button
                    color="primary"
                    variant="contained"
                    style={{ marginTop: ".75em", height: "38px" }}
                    onClick={() => { setSelectEmailGroupsOpen(true); setSelectedEmailGroups() }}
                >Add Groups</Button>
            }

            <span>
                <span className={classes.wrapper}>
                    <Button
                        aria-label="send"
                        color="primary"
                        onClick={onSubmit}
                        disabled={loading}
                        variant="contained"
                        className={buttonClassname}
                        style={{ marginTop: ".75em", height: "38px" }}
                    >
                        {sendError.error ? <ErrorIcon /> : null}
                        {(!sent && !sendError.error) ? <EmailIcon /> : null}
                        {(sent && !sendError.error) ? <CheckIcon /> : null}
                        &nbsp; Send
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </span>
            </span>
            <span>
                <span>
                    <Button
                        aria-label="updateDraft"
                        color="primary"
                        onClick={onDraftSubmit}
                        disabled={saveDraftLoading}
                        variant="contained"
                        className={saveDraftButtonClassname}
                        style={{ marginTop: ".75em", height: "38px" }}
                        ref = {ref}
                    >
                        {draftError.error ? <ErrorIcon /> : null}
                        {(!savedDraft && !draftError.error) ? <Drafts /> : null}
                        {(savedDraft && !draftError.error) ? <CheckIcon /> : null}
                        &nbsp; Update Draft
                    </Button>
                    {saveDraftLoading && <CircularProgress size={24} className={classes.buttonProgressUpdate} />}
                </span>
            </span>
            <br />
            {sendError.error ? <Typography variant="caption" color="error">{sendError.text}</Typography> : null}
            <Typography variant="subtitle1">Number of Drawings Selected: {drawings.length}</Typography>
            {drawingError.error ? <Typography variant="caption" color="error">{drawingError.text}</Typography> : null}
            <Dialog fullScreen open={open} onClose={handleClose}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={removeLoalAttachments}
                            aria-label="close"
                            size="large">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Add Message
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={saveCustomMessage}
                            aria-label="save"
                            size="large">
                            <SaveIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div style={{ paddingTop: '10px', paddingLeft: '5px', paddingBottom: '5px', paddingRight: '5px' }}>
                    <TextField
                        variant="outlined"
                        label="Subject"
                        fullWidth
                        value={subject}
                        onChange={onSubjectChange}
                        helperText="Subject saves automatically"
                    />
                </div>
                <RichTextEditorComponent
                    showCharCount={false}
                    toolbarSettings={toolbarSettings}
                    height="100%"
                    style={{ overflow: "hidden" }}
                    ref={editor => setEditor(editor)}

                    fileManagerSettings={FileManagerSettingsModel}
                >
                    <Inject services={[syncfusionToolbar, Link, HtmlEditor, Count, QuickToolbar, syncfusionTable, Image, FileManager]} />
                </RichTextEditorComponent>
              
            </Dialog>
            <Dialog fullScreen open={selectEmailGroupsOpen} onClose={() => { setSelectEmailGroupsOpen(false) }}>
                <AppBar className={classes.apBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                            onClick={() => { setSelectEmailGroupsOpen(false) }}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Select Email Groups
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div style={{ paddingTop: '70px', paddingLeft: '5px', paddingBottom: '5px', paddingRight: '5px' }}>
                    {emailGroups && emailGroups.map((item, index) => {
                        return (
                            <FormControlLabel
                                key={index}
                                value={item.id}
                                label={item.groupName}
                                control={
                                    <Checkbox
                                        checked={isEmailGroupChecked(item.id)}
                                        onChange={() => onEmailGroupCheckedChange(item.id)}
                                    />
                                }
                            />
                        );
                    })}
                </div>
            </Dialog>

            <Snackbar
                open={SnackBarOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={AlertColor === "success" ? "success" : (AlertColor === "info" ? "info" : "error")}>
                    {SnackBarText}
                </Alert>
            </Snackbar>
        </Container>
    );
});
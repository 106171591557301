import React ,{useState, useMemo, useEffect}from "react";
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import { RequestAccess } from "./components/auth/RequestAccess";
import { DrawingSearch } from "./components/search/DrawingSearch";
import { DrawingExport } from "./components/miscellaneous/DrawingExport";
import { DrawingUpload } from "./components/miscellaneous/DrawingUpload";
import { KtipLoader } from "./components/miscellaneous/KtipLoader";
import { BOMAccessRequest } from "./components/miscellaneous/BOMAccessRequest";
import { ManageUserRights } from "./components/admin/ManageUserRights";
import { EmailedFilesReport } from "./components/admin/EmailedFilesReport";
import { ManageAccessRequests } from "./components/admin/ManageAccessRequests";
import { ManageSpecialAccessRequests } from "./components/admin/ManageSpecialAccessRequests";
import { ManageScanRequests } from "./components/admin/ScanAdmin";
import { ManageBomAccessRequests } from "./components/admin/ManageBomAccessRequests";
import { ManageFaq } from "./components/admin/ManageFaq";
import { ManageDrafts } from "./components/admin/ManageDrafts";
import { ManageUsersReport } from "./components/admin/ManageUsersReport";
import { ManageRecentUsers } from "./components/admin/ManageRecentUsers";
import { MultipleItemInquiry } from "./components/search/MultipleItemInquiry";
import { ScanRequest } from "./components/miscellaneous/ScanRequest";
import { SpecialAccessRequest } from "./components/miscellaneous/SpecialAccessRequest";
import { BOMExplode } from "./components/search/BOMExplode";
import { AuditReport } from "./components/search/AuditReport";
import 'core-js';
import { Provider } from "react-redux";
import store from "./store";
import Layout from './components/layout/Layout'
import '../node_modules/@syncfusion/ej2-base/styles/material.css';
import { ManageEmailGroups } from "./components/admin/ManageEmailGroups";
import { Faq } from "./components/miscellaneous/Faq";
import 'react-toastify/dist/ReactToastify.css';
import { FaqFiles } from "./components/miscellaneous/FaqFiles";
import { HelpLibrary } from "./components/miscellaneous/HelpLibrary";
import { DrawingSummary } from "./components/miscellaneous/DrawingSummary";
import { BugReport } from "./components/miscellaneous/BugReport";
import { FeatureRequest } from "./components/miscellaneous/FeatureRequest";
import { ManageFeatureControl } from "./components/admin/ManageFeatureControl";
import { IdleScreen } from "./actions/IdleScreen";
import { ManageLogs } from "./components/admin/ManageLogs";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ActiveUsers } from './components/reports/ActiveUsers';
import { PouchPrint } from './components/miscellaneous/PouchPrint';
import { InteractionType } from "@azure/msal-browser";
import { MsalProvider, useIsAuthenticated, useMsalAuthentication, useMsal } from "@azure/msal-react";
import { CustomNavigationClient } from './NavigationClient';
import { useSelector } from "react-redux";
import { EditAccess } from "./components/auth/EditAccess";

const App = ({ pca }) => {
  const [apiProgress, setApiProgress] = useState("");

  const FileUploadApInProgress = (apiStatus) => { // the callback. Use a better name
    setApiProgress(apiStatus);
  };

  const appInsights = new ApplicationInsights({ config: {
    connectionString: 'InstrumentationKey=8783187a-bbbe-4190-b266-d1b4497d7f73;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/'
    /* ...Other Configuration Options... */
  } });
  appInsights.loadAppInsights();
    appInsights.trackPageView();

    const history = useHistory();
    let navigationClient = new CustomNavigationClient(history);
    pca.setNavigationClient(navigationClient);
  
  return (
      <Provider store={store}>
          <MsalProvider instance={pca}>
            <AppComponent />
          </MsalProvider>
      
    </Provider>
  );
}

export default App;

function AppComponent() {
    const isAuthenticated = useIsAuthenticated();
    const { error } = useMsalAuthentication(InteractionType.Redirect);

    const { instance, accounts, inProgress } = useMsal();

    const [accessToken, setAccessToken] = useState();

    useEffect(() => {
        if (inProgress === "none" && accounts.length > 0) {
            console.log("Getting access token");
            // Retrieve an access token
            instance.acquireTokenSilent({
                account: accounts[0],
                scopes: ["User.Read"]
            }).then(response => {
                if (response.accessToken) {
                    console.log("got access token");
                    setAccessToken(response.accessToken);
                } else {
                    console.log("failed to get access token");
                }
            });
        }
    }, [inProgress, accounts, instance]);

    const currentUser = useSelector(state => state.auth);

    useEffect(() => {
        console.log("app component init");
    }, []);

    if (isAuthenticated) {
        return (
            (currentUser.jwtIdToken) ?
                <Router>
                    <Layout>
                        <Route exact path="/" render={() => <DrawingSearch />} />
                        <Route path="/drawingsearch" render={() => <DrawingSearch />} />
                        <Route path="/editAccess" render={() => <EditAccess />} />
                        <Route path="/BOMExplode" render={() => <BOMExplode />} />
                        <Route path="/scanrequest/:documentID?" render={() => <ScanRequest />} />
                        <Route path="/ManageUserRights" render={() => <ManageUserRights />} />
                        <Route path="/multipleiteminquiry" render={() => <MultipleItemInquiry />} />
                        <Route path="/requestaccess" render={() => <RequestAccess />} />
                        <Route path="/bomaccessrequest" render={() => <BOMAccessRequest />} />
                        <Route path="/manageaccessrequests" render={() => <ManageAccessRequests />} />
                        <Route path="/managebomaccessrequests" render={() => <ManageBomAccessRequests />} />
                        <Route path="/auditreport" render={() => <AuditReport />} />
                        <Route path="/specialaccessrequest/:documentID?" render={() => <SpecialAccessRequest />} />
                        <Route path="/managescanrequests" render={() => <ManageScanRequests />} />
                        <Route path="/managespecialaccessrequests" render={() => <ManageSpecialAccessRequests />} />
                        <Route path="/emailedfilesreport" render={() => <EmailedFilesReport />} />
                        <Route path="/manageemailgroups" render={() => <ManageEmailGroups />} />
                        <Route path="/faq" render={() => <Faq />} />
                        <Route path="/managefaq" render={() => <ManageFaq />} />
                        <Route path="/inactiveusers" render={() => <ManageUsersReport />} />
                        <Route path="/recentusers" render={() => <ManageRecentUsers />} />
                        <Route path="/managedrafts" render={() => <ManageDrafts />} />
                        <Route path="/faqfiles" render={() => <FaqFiles />} />
                        <Route path="/helpLibrary" render={() => <HelpLibrary />} />
                        <Route path="/BugReport" render={() => <BugReport />} />
                        <Route path="/FeatureRequest" render={() => <FeatureRequest />} />
                        <Route path="/managefeaturecontrol" render={() => <ManageFeatureControl />} />
                        <Route path="/DrawingExport" render={() => <DrawingExport />} />
                        <Route path="/DrawingUpload" render={() => <DrawingUpload />} />
                        <Route path="/ktipLoader" render={() => <KtipLoader FileUploadApInProgress={FileUploadApInProgress} />} />
                        <Route path="/drawingsummary" render={() => <DrawingSummary />} />
                        <Route path="/apilogs" render={() => <ManageLogs />} />
                        <Route path="/reports/activeusers" render={() => <ActiveUsers />} />
                        <Route path="/pouchprint" render={() => <PouchPrint />} />
                    </Layout>
                </Router>
                :
                <p>Loading. Please wait</p>
        );
    }
    else if (error) {
        return <span>An error occurred during login</span>
    }
    else {
        return <span>Please wait. Redirecting to login.</span>
    }
}
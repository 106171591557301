import React, { useState, useEffect } from "react";
import { Container, Paper, TextField, Button} from "@mui/material";
import Axios from "axios";
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { getBaseURL } from "../../baseURL";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSelector } from "react-redux";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Clear';
import {green, red} from '@mui/material/colors';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const EmailedFilesReport = () => {
    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(2, 2),
          },
          paperSpacing: {
            paddingTop: "30px",
          },
          wrapper: {
            position: 'relative',
          },
          buttonSuccess: {
            backgroundColor: green[500],
            '&:hover': {
              backgroundColor: green[700],
            },
          },
          buttonError: {
              backgroundColor: red[500],
              '&:hover': {
                backgroundColor: red[700],
              },
          },
          buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -10,
            marginLeft: -12,
          },
          paperSpacing: {
            paddingTop: "30px",
          },
          helperText: {
            marginLeft: 0,
          },
    }));

    useEffect(() => {
        if(isAdmin === null) {
            try {
                if(currentUser.user.permissions.find(p => p.permissionName === "System Admin").value === true) {
                    setIsAdmin(true);
                }
                else {
                    setIsAdmin(false);
                }
            }
            catch(error) {
                setIsAdmin(false);
            }
    
            if(!isAdmin) {
                try {
                    if(currentUser.user.permissions.find(p => p.permissionName === "User Admin").value === true) {
                        setIsAdmin(true);
                    }
                    else {
                        setIsAdmin(false);
                    }
                }
                catch(error) {
                    setIsAdmin(false);
                }
            }
        }
    }, []);

    const classes = useStyles();
    const currentUser = useSelector(state => state.auth);
    const config = {
        headers: {'Authorization': "Bearer " + currentUser.jwtIdToken.idToken}
    };

    const[isAdmin, setIsAdmin] = useState(null);
    const [emailedFilesList, setEmailedFilesList] = useState([]);
    const [userName, setUserName] = useState("");
    const [drawingNumber, setDrawingNumber] = useState("");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const onDrawingNumberChange = event => {
        setDrawingNumber(event.target.value);
    }
    
    const onUserNameChange = event => {
        setUserName(event.target.value);
    }
    
    const onFromDateChange = fromDate => {
        setFromDate(fromDate);
    }
    
    const onToDateChange = toDate => {
        setToDate(toDate);
    }

    const clearUserName = () => {
        setUserName("");
    }

    const clearDrawingNumber = () => {
        setDrawingNumber("");
    }

    const onSubmit = () => {
        setLoading(true);
        setSubmitted(true);

        const formData = {
            DrawingNumber: drawingNumber,
            Email: userName,
            StartDate: fromDate,
            EndDate: toDate
        }
        
        Axios.post(`${getBaseURL()}/admin/emailedfiles`, formData, config)
        .then(o => {setEmailedFilesList(o.data); setLoading(false); setSubmitted(true);})
        // .catch((error) => {
        //   setPartError({Error: true, Text: error.response.data.message});
        //   setIsLoading(false);
        // });
    }

    return (
        <Container maxWidth={false} disableGutters={true}>
            {isAdmin ?
                <Container maxWidth={false} disableGutters={true}>
                    <Typography variant="h4" gutterBottom>Emailed Files</Typography>
                    <Paper className={classes.root}>
                        <Typography variant="h6" gutterBottom>All Fields Optional</Typography>
                        <TextField
                            id="drawingNumber"
                            label="Drawing Number"
                            onChange={onDrawingNumberChange}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            value={drawingNumber}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton onClick={clearDrawingNumber} style={{ outline: 'none' }} size="large">
                                            <CloseIcon opacity={drawingNumber.length > 0 ? 1 : 0} />
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                        <br />
                        <TextField
                            id="userName"
                            label="User Email"
                            value={userName}
                            onChange={onUserNameChange}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton onClick={clearUserName} style={{ outline: 'none' }} size="large">
                                            <CloseIcon opacity={userName.length > 0 ? 1 : 0} />
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                        <br />
                        <DatePicker
                            label="From Date"
                            value={fromDate}
                            onChange={(newValue) => onFromDateChange(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <DatePicker
                            label="To Date"
                            value={toDate}
                            onChange={(newValue) => onToDateChange(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <br />
                        <span className={classes.wrapper}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={onSubmit}
                                disabled={loading}
                            >
                                <SearchIcon />&nbsp;Search
                            </Button>
                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </span>

                    </Paper>
                    {(submitted && !loading && emailedFilesList.length > 0) &&
                        <Paper className={classes.root} style={{ marginTop: "20px" }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>User</TableCell>
                                        <TableCell>Drawing Number</TableCell>
                                        <TableCell>Version</TableCell>
                                        <TableCell>Emailed To</TableCell>
                                        <TableCell>Date Sent</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {emailedFilesList.map((item, index) => {
                                        return (
                                            <TableRow>
                                                <TableCell>{item.user.email}</TableCell>
                                                <TableCell>{item.document.drawingNumber}</TableCell>
                                                <TableCell>{item.document.revision}</TableCell>
                                                <TableCell>{item.email}</TableCell>
                                                <TableCell>{new Date(item.dateSent + 'UTC').toLocaleDateString()}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Paper>
                    }
                </Container>
                :
                <Paper className={classes.root}>
                    <Typography variant="h6">
                        <ErrorIcon />&nbsp;Access Denied
                    </Typography>
                </Paper>
            }
        </Container>
    );
}
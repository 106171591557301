import React, { useState, useEffect } from "react";
import { Container, TextField, Paper, Checkbox} from "@mui/material";
import Axios from "axios";
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { getBaseURL } from "../../baseURL";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSelector } from "react-redux";
import CheckIcon from '@mui/icons-material/Check';
import {green, red} from '@mui/material/colors';
import Fab from '@mui/material/Fab';
import ClearIcon from '@mui/icons-material/Clear';
import CircularProgress from '@mui/material/CircularProgress';
import { Shimmer, ShimmerElementType } from '@fluentui/react/lib/Shimmer';
import { Fabric } from '@fluentui/react/lib/Fabric';
import { TeachingBubble } from '@fluentui/react/lib/TeachingBubble';
import { IconButton } from '@fluentui/react/lib/Button';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { Persona } from '@fluentui/react/lib/Persona';
import withStyles from '@mui/styles/withStyles';
import ErrorIcon from '@mui/icons-material/Error';

export const ManageBomAccessRequests = () => {
    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(3, 2),
        },
        paperSpacing: {
            paddingTop: "30px",
        },
        fabProgress: {
            color: green[500],
            position: 'absolute',
            top: -18,
            left: -3,
            zIndex: 1,
          },
          wrapper: {
            margin: theme.spacing(1),
            position: 'relative',
          },
          buttonGreen: {
            backgroundColor: green[500],
            '&:hover': {
              backgroundColor: green[700],
            },
          },
          buttonRed: {
            backgroundColor: red[500],
            '&:hover': {
              backgroundColor: red[700],
            },
          }
    }));

    initializeIcons();
    const classes = useStyles();
    const currentUser = useSelector(state => state.auth);
    const config = {
        headers: {'Authorization': "Bearer " + currentUser.jwtIdToken.idToken}
    };

    const[isAdmin, setIsAdmin] = useState(null);
    const [accessRequests, setAccessRequests] = useState([]);
    const [checkedRequests, setCheckedRequests] = useState(false);
    const [adminHelpOpen, setAdminHelpOpen] = useState(false);
    const [commentsToUserHelpOpen, setCommentsToUserHelpOpen] = useState(false);

    useEffect(() => {
        if(accessRequests.length < 1 && !checkedRequests) {
            Axios.get(`${getBaseURL()}/admin/bomaccessrequests`, config)
            .then((response) => {
                var requests = [...response.data];
                requests.forEach(x => x.submitted = {Submitted: false, Error: false, Message: ""});
                setAccessRequests(requests);
                setCheckedRequests(true);
            })
            .catch((error) => {
                // setLoading(false);
                // setSendError({error: true, text: error.response.data.message})
            });
        }

        if(isAdmin === null) {
            try {
                if(currentUser.user.permissions.find(p => p.permissionName === "System Admin").value === true) {
                    setIsAdmin(true);
                }
                else {
                    setIsAdmin(false);
                }
            }
            catch(error) {
                setIsAdmin(false);
            }
    
            if(!isAdmin) {
                try {
                    if(currentUser.user.permissions.find(p => p.permissionName === "User Admin").value === true) {
                        setIsAdmin(true);
                    }
                    else {
                        setIsAdmin(false);
                    }
                }
                catch(error) {
                    setIsAdmin(false);
                }
            }
        }
    }, []);

    const approveDeny = (userID, approve) => {
        const userRequest = accessRequests.find(x => x.userID === userID);

        const request = {
            userID: userRequest.user.guid,
            state: approve,
        }

        var requests = [...accessRequests];

        if(approve) {
            requests.find(x => x.userID === userID).SendingApprove = true;
        }
        else {
            requests.find(x => x.userID === userID).SendingReject = true;
        }
        
        setAccessRequests(requests);

        RefreshToken();
        Axios.post(`${getBaseURL()}/admin/bomaccessrequests/update`, request, config)
        .then((response) => {
            requests = [...accessRequests];
            var message = "";
            if(approve) {
                message = "APPROVED"
            }
            else {
                message = "REJECTED"
            }
            requests.find(x => x.userID === userID).submitted = {Submitted: true, Error: false, Message: message}
            requests.find(x => x.userID === userID).SendingApprove = false;
            requests.find(x => x.userID === userID).SendingReject = false;

            setAccessRequests(requests);
        })
    }

    const adminCommentsHelp = () => {
        setAdminHelpOpen(!adminHelpOpen);
    }

    const commentsToUserHelp = () => {
        setCommentsToUserHelpOpen(!commentsToUserHelpOpen);
    }

    const getUserPersonaText = (user) => {
        if(user.firstName && user.lastName) {
            return(user.firstName + " " + user.lastName);
        }

        return user.email
    }

    const CustomPersonaTableCell = withStyles(theme => ({
        head: {
          maxWidth: '20em'
        },
        body: {
            maxWidth: '20em'
        }
      }))(TableCell);

    const CustomJustificationTableCell = withStyles(theme => ({
        head: {
            maxWidth: '5em'
        },
        body: {
            maxWidth: '5em'
        }
        }))(TableCell);

    const adminComments = (requestID, event) => {
        var requests = [...accessRequests];
        requests.find(x => x.id === requestID).adminComments = event.target.value;

        setAccessRequests(requests);
    }

    const commentsToUser = (requestID, event) => {
        var requests = [...accessRequests];
        requests.find(x => x.id === requestID).commentsToUser = event.target.value;

        setAccessRequests(requests);
    }

    return (
        <Container maxWidth={false} disableGutters={true}>
            {isAdmin ?
                <Container maxWidth={false} disableGutters={true}>
                    <Typography variant="h4" gutterBottom>Manage BOM Access Requests</Typography>
                    {checkedRequests ?
                        (accessRequests.length > 0) ?
                            <Paper className={classes.root}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>User</TableCell>
                                            <TableCell>Request Date</TableCell>
                                            <TableCell>Email Files</TableCell>
                                            <TableCell>Admin Comments
                                                <IconButton
                                                    iconProps={{ iconName: 'Help' }}
                                                    id="adminCommentsHelp"
                                                    title="Help"
                                                    ariaLabel="Help"
                                                    style={{ height: "20px" }}
                                                    onClick={adminCommentsHelp}
                                                    size="large" />
                                                {adminHelpOpen &&
                                                    <div>
                                                        <TeachingBubble
                                                            target={'#adminCommentsHelp'}
                                                            hasCondensedHeadline={true}
                                                            onDismiss={adminCommentsHelp}
                                                            hasCloseButton={true}
                                                            closeButtonAriaLabel="Close"
                                                            headline="Admin Comments"

                                                        >
                                                            These comments are stored only for review and reporting purposes and are not sent to the requestor.
                                                        </TeachingBubble>
                                                    </div>
                                                }
                                            </TableCell>
                                            <TableCell>
                                                <span style={{ paddingTop: '5px' }}>Comments to User</span>
                                                <IconButton
                                                    iconProps={{ iconName: 'Help' }}
                                                    id="commentsToUserHelp"
                                                    title="Help"
                                                    ariaLabel="Help"
                                                    style={{ height: "20px" }}
                                                    onClick={commentsToUserHelp}
                                                    size="large" />
                                                {commentsToUserHelpOpen &&
                                                    <div>
                                                        <TeachingBubble
                                                            target={'#commentsToUserHelp'}
                                                            hasCondensedHeadline={true}
                                                            onDismiss={commentsToUserHelp}
                                                            hasCloseButton={true}
                                                            closeButtonAriaLabel="Close"
                                                            headline="Comments to User"

                                                        >
                                                            These comments are sent directly to the user, and are stored for review and reporting.
                                                        </TeachingBubble>
                                                    </div>
                                                }
                                            </TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {accessRequests.map((item, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <CustomPersonaTableCell>
                                                        <Persona
                                                            text={getUserPersonaText(item.user)}
                                                            secondaryText={item.user.jobTitle}
                                                            imageUrl={"data:image/png;base64," + item.user.photo}
                                                        />
                                                    </CustomPersonaTableCell>
                                                    <TableCell>{new Date(item.requestDate).toLocaleDateString()}</TableCell>
                                                    <TableCell>
                                                        <Checkbox
                                                            color="primary"
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            id="adminComment"
                                                            multiline
                                                            value={item.adminComments}
                                                            onChange={(e) => adminComments(item.id, e)}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            id="commentsToUser"
                                                            multiline
                                                            value={item.commentsToUser}
                                                            onChange={(e) => commentsToUser(item.id, e)}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        {!item.submitted.Submitted ?
                                                            <span>
                                                                <span className={classes.wrapper}>
                                                                    <Fab
                                                                        id={item.userID}
                                                                        size="medium"
                                                                        color="primary"
                                                                        onClick={() => approveDeny(item.userID, false)}
                                                                        disabled={item.SendingReject || item.SendingApprove}
                                                                    >
                                                                        <ClearIcon />
                                                                    </Fab>
                                                                    {item.SendingReject && <CircularProgress size={52} className={classes.fabProgress} />}
                                                                </span>
                                                                <span className={classes.wrapper}>
                                                                    <Fab
                                                                        size="medium"
                                                                        color="primary"
                                                                        onClick={() => approveDeny(item.userID, true)}
                                                                        disabled={item.SendingReject || item.SendingApprove}
                                                                    >
                                                                        <CheckIcon />
                                                                    </Fab>
                                                                    {item.SendingApprove && <CircularProgress size={52} className={classes.fabProgress} />}
                                                                </span>
                                                            </span>
                                                            : <Typography variant="button">{item.submitted.Message}</Typography>}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </Paper>
                            :
                            <Typography variant="h6">
                                No Requests Found
                            </Typography>
                        :
                        <Fabric>
                            <Shimmer style={{ marginBottom: '10px' }}
                                shimmerElements={[
                                    { type: ShimmerElementType.line, height: 48 },
                                    { type: ShimmerElementType.gap, width: 20 },
                                    { type: ShimmerElementType.circle, height: 48 },
                                    { type: ShimmerElementType.gap, width: 10 },
                                    { type: ShimmerElementType.circle, height: 48 }
                                ]}
                                shimmerColors={{
                                    background: "#fafafa",
                                    shimmer: "#E0E0E0",
                                    shimmerWave: "#BABABA"
                                }}
                                isDataLoaded={checkedRequests}
                            />
                            <Shimmer style={{ marginBottom: '10px' }}
                                shimmerElements={[
                                    { type: ShimmerElementType.line, height: 48 },
                                    { type: ShimmerElementType.gap, width: 20 },
                                    { type: ShimmerElementType.circle, height: 48 },
                                    { type: ShimmerElementType.gap, width: 10 },
                                    { type: ShimmerElementType.circle, height: 48 }
                                ]}
                                shimmerColors={{
                                    background: "#fafafa",
                                    shimmer: "#E0E0E0",
                                    shimmerWave: "#BABABA"
                                }}
                                isDataLoaded={checkedRequests}
                            />
                            <Shimmer style={{ marginBottom: '10px' }}
                                shimmerElements={[
                                    { type: ShimmerElementType.line, height: 48 },
                                    { type: ShimmerElementType.gap, width: 20 },
                                    { type: ShimmerElementType.circle, height: 48 },
                                    { type: ShimmerElementType.gap, width: 10 },
                                    { type: ShimmerElementType.circle, height: 48 }
                                ]}
                                shimmerColors={{
                                    background: "#fafafa",
                                    shimmer: "#E0E0E0",
                                    shimmerWave: "#BABABA"
                                }}
                                isDataLoaded={checkedRequests}
                            />
                        </Fabric>
                    }
                </Container>
                :
                <Paper className={classes.root}>
                    <Typography variant="h6">
                        <ErrorIcon />&nbsp;Access Denied
                    </Typography>
                </Paper>
            }
        </Container>
    );
}
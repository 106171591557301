import React, { useState, useEffect } from "react";
import { Container, Button, Snackbar, MenuItem, TablePagination } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { green, red } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useSelector } from "react-redux";
import Axios from "axios";
import { getBaseURL } from "../../baseURL";
import MuiAlert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { TablePaginationActions } from '../../actions/TablePaginationActions';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
export const ManageUsersReport = () => {
    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(2, 2),
        },
        paperSpacing: {
            paddingTop: "30px",
        },
        wrapper: {
            position: 'relative',
        },
        buttonSuccess: {
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[700],
            },
        },
        buttonError: {
            backgroundColor: red[500],
            '&:hover': {
                backgroundColor: red[700],
            },
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -5,
            marginLeft: -12,
        },
        paperSpacing: {
            paddingTop: "30px",
        },
        helperText: {
            marginLeft: 0,
        },
    }));

    const classes = useStyles();
    const [Users, setUsers] = useState([]);
    const[isAdmin, setIsAdmin] = useState(null);
    const [AllUsers, setAllUsers] = useState([]);
    const [UsersError, setUsersError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, Users.length - page * rowsPerPage);
    const [pageloading, setPageLoading] = useState(false);
    const [SnackBarOpen, setSnackBarOpen] = useState(false);
    const currentUser = useSelector(state => state.auth);
    const [AlertColor, setAlertColor] = useState("");

    const config = {
        headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken }
    };
    const [open, setOpen] = React.useState(false);
    const [SnackBarText, setSnackBarText] = useState("");
    const filterdata = [{ id: 1, name: "Deactivate" }, { id: 2, name: "Inactive" }
    ]
    const [report, setreport] = React.useState('');

    useEffect(() => {
        GetUsers();
        if(isAdmin === null) {
            try {
                if(currentUser.user.permissions.find(p => p.permissionName === "System Admin").value === true) {
                    setIsAdmin(true);
                }
                else {
                    setIsAdmin(false);
                }
            }
            catch(error) {
                setIsAdmin(false);
            }
    
            if(!isAdmin) {
                try {
                    if(currentUser.user.permissions.find(p => p.permissionName === "User Admin" || "Scan Admin").value === true) {
                        setIsAdmin(true);
                    }
                    else {
                        setIsAdmin(false);
                    }
                }
                catch(error) {
                    setIsAdmin(false);
                }
            }
        }
    }, []);

    const GetUsers = () => {
        Axios.get(`${getBaseURL()}/users/InActiveUsers`, config)
            .then(response => {

                setUsers(response.data);
                setAllUsers(response.data);
                setPage(0);
            })
            .catch(error => {
                console.log(error)
            })
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarOpen(false);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    const Alert = (props) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleUserPermissionAccess = (event) => {
        setPageLoading(true);
        const newResults = [...Users];
        const IsDeactivate = event.target.checked;
        newResults.find(x => x.guid === event.target.id).permissions.find(r => r.permission === 9).value = event.target.checked ? false : true;
        const args = {
            GUID: event.target.id,
            UserPermission: 9,
            state: event.target.checked ? false : true
        }
        Axios.post(`${getBaseURL()}/users/updateUserAccess`, args, config)
            .then((response) => {
                if (response.data.message === "Update Successfully" && IsDeactivate) {
                    setAlertColor("success")
                    setSnackBarText("Deactivate Successfully");
                }
                else if (response.data.message === "Update Successfully" && !IsDeactivate) {
                    setAlertColor("success")
                    setSnackBarText("Inactive Successfully");
                }
                setSnackBarOpen(true);
                const filterValue = report === "" ? "" : report === 1 ? false : true;
                if(filterValue !== ""){
                    const userslist = newResults.filter(obj => obj.permissions.find(x => x.value === filterValue));
                    if(userslist.length == 0) {
                        GetUsers();
                        setTimeout(() => {
                            setreport("");
                            setPageLoading(false);
                        }, 2200);
                    }
                    else {
                        setUsers(userslist);
                        setPageLoading(false);
                    }
                }
                else
                    setPageLoading(false);
            })
            .catch((error) => {
                setSnackBarText(error);
                setSnackBarOpen(true);
                console.log(error)
                setPageLoading(false)
            })
    };

    const handleAccess = (event) => {
        const args = {
            GUID: event.target.id,
            ClassLevel: 2,
        }
        const Ischeck = event.target.checked;
        Axios.post(`${getBaseURL()}/users/updateUserAccess`, args, config)
            .then((response) => {
                var userslist = [...Users];
                userslist.find(x => x.guid === args.GUID).usercheck = Ischeck;
                setUsers(userslist);
                setAlertColor("success")
                setSnackBarText("User Access is Successfully granted");
                setSnackBarOpen(true);
            })
            .catch((error) => {
                setAlertColor("")
                setSnackBarText("Something is wrong!");
                setSnackBarOpen(true);
                console.log(error)
            })

    };

    const onfilterDataChange = event => {
        setreport(event.target.value)
        setPageLoading(true)
        const Filtervalue = event.target.value === "" ? "" : event.target.value === 1 ? false : true;
        Axios.get(`${getBaseURL()}/users/InActiveUsers`, config)
            .then(response => {
                if (Filtervalue === "") {
                    setUsers(response.data)
                } else {
                    const userslist = response.data.filter(obj => obj.permissions.find(x => x.value === Filtervalue));
                    setUsers(userslist)
                }
                setPageLoading(false)
                setPage(0);
            })
            .catch(error => {
                console.log(error)
            })
    }
    return (
        <Container maxWidth={false} disableGutters={true}>
            {isAdmin ?
                <Container maxWidth={false} disableGutters={true}>
                    {(AllUsers.length && !UsersError.Error) > 0 ?
                        <Paper className={classes.paperSpacing} p={5}>
                            <Typography variant="h4" gutterBottom style={{ marginLeft: "10px" }}>Inactive Users </Typography>
                            <div style={{ float: "right", marginRight: "40px" }}>
                                <InputLabel htmlFor="Filteration">Filter</InputLabel>
                                <Select
                                    value={report}
                                    displayEmpty
                                    onChange={onfilterDataChange}
                                    style={{ minWidth: "150px" }}
                                    name="report"
                                    inputProps={{ 'aria-label': 'report' }}
                                >
                                    <MenuItem value="">
                                        <em>All</em>
                                    </MenuItem>
                                    {filterdata.map((item, i) =>
                                        <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                                    )}
                                </Select>
                            </div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Emails</TableCell>
                                        <TableCell>Inactive Count In One Year</TableCell>
                                        <TableCell>Special Access Only </TableCell>
                                        <TableCell>Deactivate User Access </TableCell>
                                        <TableCell>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {

                                        if (item.guid === 0) {
                                            return;
                                        }
                                        return (
                                            <TableRow key={item.guid}>
                                                <TableCell>{item.email}</TableCell>
                                                <TableCell>{item.noUseOccurence}</TableCell>
                                                <TableCell>  <Switch
                                                    id={item.guid}
                                                    color="primary"
                                                    checked={item.classification.id === 2 ? true
                                                        : item.usercheck}
                                                    onChange={handleAccess}
                                                    name="checkedA"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />

                                                </TableCell>
                                                <TableCell>
                                                    <Switch
                                                        id={item.guid}
                                                        color="primary"
                                                        checked={item.permissions.length > 0 ? item.permissions[0].value == true ? false : true : false}
                                                        onChange={handleUserPermissionAccess}
                                                        name="checkedB"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </TableCell>
                                                <TableCell> {item.permissions.length > 0 ? item.permissions[0].value == true ? "Inactive" : "Deactivate" : "Inactive"}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}

                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 20]}
                                component="div"
                                count={Users.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    'aria-label': 'previous page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'next page',
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                            {pageloading ? <CircularProgress style={{ width: '40px', height: '40px', position: 'fixed', left: '50%', top: '50%' }} />
                                : null}
                        </Paper>
                        : <Typography variant="h4" gutterBottom>No Record Found </Typography>

                    }

                    <Snackbar
                        open={SnackBarOpen}
                        autoHideDuration={5000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <Alert onClose={handleSnackbarClose} severity={AlertColor === "success" ? "success" : "error"}>
                            {SnackBarText}
                        </Alert>
                    </Snackbar>

                </Container>
                :
                <Paper className={classes.root}>
                    <Typography variant="h6">
                        <ErrorIcon />&nbsp;Access Denied
                    </Typography>
                </Paper>
            }
        </Container>
    );
}
import clsx from 'clsx';
import Axios from "axios";
import { useSelector } from "react-redux";
import Grid from '@mui/material/Grid';
import { getBaseURL } from "../../baseURL";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import { useHistory } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import TableBody from '@mui/material/TableBody';
import { getPdfFiles } from '../../actions/actions';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { green, red } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState, useEffect, useRef } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DownloadIcon  from '@mui/icons-material/CloudDownload';
import CircularProgress from '@mui/material/CircularProgress';
import { DraftEmailFiles } from "../miscellaneous/DraftEmailFiles";
import DialogContentText from '@mui/material/DialogContentText';
import { TablePaginationActions } from '../../actions/TablePaginationActions';
import { Container, Button, Snackbar, FormControlLabel, Checkbox, TablePagination } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export const ManageDrafts = () => {
    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(2, 2),
        },
        paperSpacing: {
            paddingTop: "30px"
        },
        wrapper: {
            position: 'relative'
        },
        gridHeading: {
            fontWeight: 550
        },
        buttonSuccess: {
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[700]
            },
        },
        buttonError: {
            backgroundColor: red[500],
            '&:hover': {
                backgroundColor: red[700]
            },
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -5,
            marginLeft: -12
        },
        helperText: {
            marginLeft: 0,
        },
        actionButtonHover: {
            '&:focus': {
                outline: 'none'
            }
        }
    }));

    const classes = useStyles();
    const childCompRef = useRef();
    const [editor, setEditor] = useState();
    const [page, setPage] = React.useState(0);
    const [draftId, setDraftId] = useState("");
    const[isAdmin, setIsAdmin] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [allDrafts, setDrafts] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [buttonText, setButtonText] = useState("");
    const [AlertColor, setAlertColor] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [isLoading, setIsLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [emailGroups, setEmailGroups] = useState([]);
    const [draftEmails, setDraftEmails] = useState({});
    const [draftMessage, setDraftMessage] = useState("");
    const [draftSubject, setDraftSubject] = useState("");
    const [SnackBarText, setSnackBarText] = useState("");
    const currentUser = useSelector(state => state.auth);
    const [draftDrawings, setDraftDrawings] = useState({});
    const [customMessage, setCustomMessage] = useState("");
    const [ deleteDraftId, setDeleteDraftId ] = useState(5);
    const [SnackBarOpen, setSnackBarOpen] = useState(false);
    const [draftEmailGroups, setDraftEmailGroups] = useState({});
    const [draftAttachments, setDraftAttachments] = useState({});
    const [saveChangesOpen, setSaveChangesOpen] = useState(false);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [hasDrawingsRemoved, setHasDrawingsRemoved] = useState(false);
    const [downloadAllDisabled, setDownloadAllDisabled] = useState(false);
    const [selectEmailGroupsOpen, setSelectEmailGroupsOpen] = useState(false);
    const [downloadAllDowloading, setDownloadAllDownloading] = useState(false);
    const [submitError, setSubmitError] = useState({ Error: false, Text: "" });
    const [allDraftsError, setDraftsError] = useState({ Error: false, Text: "" });
    const [subject, setSubject] = useState("Komatsu Mining Corp. Shared Drawings");
    const [deleteDraftConfirmationOpen, setDeleteDraftConfirmationOpen] = useState(false);
    const [draftUpdateStatus, setDraftUpdateStatus] = useState(false);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, allDrafts.length - page * rowsPerPage);
    const config = {
        headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken }
    };

    let history = useHistory();

    const buttonClassname = clsx({
        [classes.buttonSuccess]: submitted,
        [classes.buttonError]: submitError.Error,
    });

    const cancelSaveChanges = () => {
        setSaveChangesOpen(false);
    }

    const closeSaveChangesNoSave = () => {
        setSaveChangesOpen(false);
        setOpen(false);
    }

    const closeSaveChangesSave = () => {
        childCompRef.current.refFunc();
        closeSaveChangesNoSave();
    }

    const cancelDeleteDraftConfirmation = () => {
        setDeleteDraftConfirmationOpen(false);
    }

    const saveDeleteDraftConfirmation = () => {
        var id = deleteDraftId;
        if (id > 0) {
            const draftData = {
                DraftId: id
            }
            Axios.post(`${getBaseURL()}/documents/DeleteDraft`, draftData, config)
                .then((response) => {
                    if (response.data.message === "Draft Deleted Successfully") { 
                        setDeleteDraftConfirmationOpen(false);
                        const newDraftList = allDrafts.filter((item) => item.id !== id); 
                        setDrafts(newDraftList); 
                        setPage(0); 
                        setOpen(false);
                        setAlertColor("success");
                        setSnackBarOpen(true); 
                        setSnackBarText(response.data.message);
                        GetDraftsData(); 
                    }
                })
                .catch((error) => {
                    setDeleteDraftConfirmationOpen(false);
                    setAlertColor("")
                    setSnackBarOpen(true);
                    setSnackBarText(error.response.data.message);
                    console.log(error)
                })
        }
        else{
            setDeleteDraftConfirmationOpen(false);
        }
    }
    useEffect(() => {         
        GetDraftsData();

        if (draftDrawings && draftDrawings.length > 0) {
            if (draftDrawings.filter(x => x.isDownloading === true).length > 0) {
                setDownloadAllDisabled(true);
            }
            else {
                setDownloadAllDisabled(false);
            }
        }

        if(isAdmin === null) {
            try {
                if(currentUser.user.permissions.find(p => p.permissionName === "Special Access Admin").value === true) {
                    setIsAdmin(true);
                }
                else {
                    setIsAdmin(false);
                }
            }
            catch(error) {
                setIsAdmin(false);
            }
    
            if(!isAdmin) {
                try {
                    if(currentUser.user.permissions.find(p => p.permissionName === "Manage Drafts Access").value === true) {
                        setIsAdmin(true);
                    }
                    else {
                        setIsAdmin(false);
                    }
                }
                catch(error) {
                    if(currentUser.user.permissions.find(p => p.permissionName === "Special Access Admin") != undefined){
                        if(currentUser.user.permissions.find(p => p.permissionName === "Special Access Admin").value === true) {
                            setIsAdmin(true);
                        }
                        else {
                            setIsAdmin(false);
                        }
                    }
                    else {
                        setIsAdmin(false);
                    }
                }
            }
        }
      }, []);
      
    const GetDraftsData = () => {
        Axios.get(`${getBaseURL()}/documents/GetAllDrafts`, config)
            .then(response => {
                setDrafts(response.data);
                setPage(0);
            })
            .catch(error => {
                console.log(error)
            })
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarOpen(false);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    // const Alert = (props) => {
    //     return <MuiAlert elevation={6} variant="filled" {...props} />;
    // }

    const handleClose = () => {
        var draftUpdateStatus = childCompRef.current.checkDraftAltered();
        if(draftUpdateStatus || hasDrawingsRemoved){
            //setSaveChangesOpen(true);
            GetDraftsData();
        }
        else{
            setOpen(false);
            GetDraftsData();
        }
    }

    const onViewEditDraftClick = (id, btnName) => {
        setDraftId(id);
        setButtonText(btnName);
        setOpen(true);
        Axios.get(`${getBaseURL()}/documents/GetDraftDataById?draftId=${id}`, config)
            .then(response => {
                if (response.data.message == "Ok") {
                    response.data.draftData.drawingsList.filter(x => (x.classificationLevel <= currentUser.user.classification.classLevel || x.hasSpecialAccess) && x.id.length !== 0 && x.id !== 0).forEach(x => x.isChecked = true);
                    setSelectAllChecked(true);
                    setDownloadAllDisabled(false);
                    setDraftSubject(response.data.draftData.subject);
                    setDraftMessage(response.data.draftData.message);
                    setDraftDrawings(response.data.draftData.drawingsList);
                    setDraftEmailGroups(response.data.draftData.emailGroupList);
                    setDraftAttachments(response.data.draftData.draftAttachmentsList);
                    if (response.data.draftData.sendEmails != "") {
                        var splitEmails = response.data.draftData.sendEmails.split(',');
                        setDraftEmails(splitEmails);
                    }
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    const DeleteDraft = (id) => {
        setDeleteDraftId(id);
        setDeleteDraftConfirmationOpen(true);
    }

    const onEmailGroupCheckedChange = (groupId) => {
        var emailGroupsCopy = emailGroups.map(g => ({ ...g, checked: Boolean(g.checked) }));

        if (emailGroupsCopy.find(x => x.id === groupId).checked) {
            emailGroupsCopy.find(x => x.id === groupId).checked = false;
        }
        else {
            emailGroupsCopy.find(x => x.id === groupId).checked = true;
        }

        setEmailGroups(emailGroupsCopy);
    }

    const isEmailGroupChecked = (groupId) => {
        if (emailGroups.find(x => x.id === groupId).checked) {
            return true;
        }
        else {
            return false;
        }
    }

    const downloadAllClick = () => {
        if (draftDrawings.length > 0) {
          setDownloadAllDownloading(true);
          getPdfFiles(draftDrawings, currentUser.jwtIdToken.idToken)
            .then((response) => {
              var drawingsArray = [...draftDrawings];
              drawingsArray.forEach(x => x.isChecked = false);
              setDownloadAllDownloading(false);
            })
            .catch((error) => {
              setDownloadAllDownloading(false);
            })
        }
      }

      const onCheckAllChange = event => {
        var drawingsArray = [...draftDrawings];
    
        drawingsArray.filter(x => (x.classificationLevel <= currentUser.user.classification.classLevel || x.hasSpecialAccess) && x.id.length !== 0 && x.id !== 0).forEach(x => x.isChecked = event.target.checked);
    
        setDraftDrawings(drawingsArray);
        setSelectAllChecked(event.target.checked);
        if (event.target.checked)
          setDownloadAllDisabled(false)
        else
          setDownloadAllDisabled(true)
    
      }

      const onCheckedChange = event => {
        var drawingsArray = [...draftDrawings];
        drawingsArray.filter(x => (x.classificationLevel <= currentUser.user.classification.classLevel || x.hasSpecialAccess) && x.id == event.target.id).forEach(x => x.isChecked = event.target.checked);
    
        setDraftDrawings(drawingsArray);
    
        var userDrawings = [...drawingsArray].filter(x => (x.classificationLevel <= currentUser.user.classification.classLevel || x.hasSpecialAccess));
        if (userDrawings.filter(x => x.isChecked).length)
          setDownloadAllDisabled(false)
        else
          setDownloadAllDisabled(true)
    
        if (userDrawings.filter(x => x.isChecked).length === userDrawings.length) {
          setSelectAllChecked(true);
        }
        else {
          setSelectAllChecked(false);
        }
      }
    
      const handleDownloadClick = (drawingId) => {
        var drawingsArray = [...draftDrawings];
        drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = true);
        setDraftDrawings(drawingsArray);
    
        var selectedFile = [...draftDrawings].filter(x => x.id === drawingId);
    
        getPdfFiles(selectedFile, currentUser.jwtIdToken.idToken)
          .then((response) => {
            drawingsArray = [...draftDrawings];
            drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = false);
            setDraftDrawings(drawingsArray);
          });
      }

      const handleRemoveDrawingsClick = (drawingId) => {
        var drawingsArray = [...draftDrawings];
        drawingsArray = drawingsArray.filter(x => x.id !== drawingId);
        setDraftDrawings(drawingsArray);
        setHasDrawingsRemoved(true);
        if(drawingsArray.length == 0)
            setDownloadAllDisabled(true);
        else
          setDownloadAllDisabled(false);
      }

    const handleUpdateClose = (shouldClose) => {
        setOpen(shouldClose);
        handleClose();
    }
    
    return (
        <Container maxWidth={false} disableGutters={true}>
            {isAdmin ?
                <Container maxWidth={false} disableGutters={true}>
                    {allDrafts.length > 0 ?
                        <Paper className={classes.paperSpacing} p={5}>
                            <Typography variant="h4" gutterBottom style={{ marginLeft: "10px" }}>Manage Drafts </Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.gridHeading}>User Email</TableCell>
                                        <TableCell className={classes.gridHeading}>Subject</TableCell>
                                        <TableCell className={classes.gridHeading}>Send To Email</TableCell>
                                        <TableCell className={classes.gridHeading}>Draft Date</TableCell>
                                        <TableCell className={classes.gridHeading}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {allDrafts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                                        return (
                                            <TableRow key={item.id}>
                                                <TableCell>{item.email}</TableCell>
                                                <TableCell>{item.subject}</TableCell>
                                                <TableCell>{item.sendEmails}</TableCell>
                                                <TableCell>{new Date(item.createdDate).toLocaleDateString()}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        id="btnview"
                                                        className={classes.actionButtonHover}
                                                        color="primary"
                                                        onClick={() => onViewEditDraftClick(item.id, 'View')}
                                                    >
                                                        <VisibilityIcon />
                                                    </Button>

                                                    {/* <Button
                                                        id="btnedit"
                                                        color="primary"
                                                        onClick={() => onViewEditDraftClick(item.id, 'Edit')}
                                                    >
                                                        <EditIcon />
                                                    </Button> */}

                                                    <Button
                                                        id="btndelete"
                                                        className={classes.actionButtonHover}
                                                        color="primary"
                                                        onClick={() => DeleteDraft(item.id)}
                                                    >
                                                        <DeleteIcon />
                                                    </Button>

                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}

                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 20]}
                                component="div"
                                count={allDrafts.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    'aria-label': 'previous page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'next page',
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                            {loading ? <CircularProgress style={{ width: '40px', height: '40px', position: 'fixed', left: '50%', top: '50%' }} />
                                : null}
                        </Paper>
                        : <Typography variant="h4" gutterBottom>No Record Found </Typography>

                    }

                    <Snackbar
                        open={SnackBarOpen}
                        autoHideDuration={5000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <Alert onClose={handleSnackbarClose} severity={AlertColor === "success" ? "success" : "error"}>
                            {SnackBarText}
                        </Alert>
                    </Snackbar>

                    <Dialog fullScreen open={open} onClose={handleClose}>
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    className={classes.actionButtonHover}
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                    size="large">
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" className={classes.title}>
                                    View Draft
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <br />
                        <br />
                        <br />
                        {(draftDrawings.length == 0 || draftDrawings.length > 0) ?
                            <span>
                                <Paper className={classes.root}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} className={classes.wrapper}>
                                            <DraftEmailFiles ref={childCompRef} handleUpdateClose={handleUpdateClose} draftId={draftId} drawings={draftDrawings} sendEmailList={draftEmails} draftSubject={draftSubject} message={draftMessage}
                                                emailGroupsList={draftEmailGroups} actionType={buttonText} hasDrawingsRemoved={hasDrawingsRemoved} />
                                            <span >
                                                <Button
                                                    id="downloadAll"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={downloadAllDisabled}
                                                    style={{ bottom: "30px", right: "10px", whiteSpace: "nowrap", position:"absolute" }}
                                                    onClick={downloadAllClick}
                                                >
                                                    {downloadAllDowloading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                                    Download All
                                                </Button>
                                            </span>
                                        </Grid>
                                    </Grid>
                                    {(draftDrawings.length > 0) ?
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.gridHeading}><Checkbox id={"selectAll"} checked={selectAllChecked} onChange={onCheckAllChange} disabled={true} color="primary" /></TableCell>
                                                    <TableCell className={classes.gridHeading}>Drawing Number</TableCell>
                                                    <TableCell className={classes.gridHeading}>Revision</TableCell>
                                                    <TableCell className={classes.gridHeading}>Description</TableCell>
                                                    <TableCell className={classes.gridHeading}>Model</TableCell>
                                                    <TableCell className={classes.gridHeading}>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {draftDrawings.filter(d => d.id !== 0).map((item, index) => {
                                                    return (
                                                        <TableRow key={index.toString()}>
                                                            <TableCell><Checkbox id={item.id.toString()} checked={item.isChecked} color="primary" onChange={onCheckedChange} disabled={true} /></TableCell>
                                                            <TableCell>{item.drawingNumber}</TableCell>
                                                            <TableCell>{item.revision}</TableCell>
                                                            <TableCell>{item.description}</TableCell>
                                                            <TableCell>{item.model}</TableCell>
                                                            {(item.classificationLevel <= currentUser.user.classification.classLevel || item.hasSpecialAccess) ?
                                                                (item.documentCount && item.documentCount > 0) ?
                                                                    <TableCell>
                                                                        <span className={classes.wrapper}>
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => handleDownloadClick(item.id)}
                                                                                disabled={item.isDownloading}
                                                                            >
                                                                                <DownloadIcon />
                                                                            </Button>
                                                                            {item.isDownloading && <CircularProgress className={classes.buttonProgressDownload} />}
                                                                        </span>
                                                                        
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => handleRemoveDrawingsClick(item.id)}
                                                                                disabled={item.isDownloading}
                                                                            >
                                                                                <DeleteIcon />
                                                                            </Button>
                                                                    </TableCell>
                                                                    :
                                                                    <TableCell>
                                                                        <Button
                                                                            color="primary"
                                                                            variant="contained"
                                                                            onClick={() => history.push("/scanrequest/" + item.drawingNumber)}
                                                                        >
                                                                            Scan Request
                                                                        </Button>
                                                                    </TableCell>
                                                                : <TableCell>
                                                                    <Button color="primary" onClick={() => history.push("/specialaccessrequest/" + item.drawingNumber)}>Request Access</Button>
                                                                </TableCell>}
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                        : null}
                                </Paper>
                                {(draftDrawings.filter(x => x.id === 0).length > 0) ?
                                    <span>
                                        <Typography variant="h5" gutterBottom style={{ paddingTop: '10px' }}>Drawings Not Found</Typography>
                                        <Paper className={classes.root}>
                                            <Table>
                                                <TableBody>
                                                    {draftDrawings.filter(d => d.id === 0).map((item, index) => {
                                                        return (
                                                            <TableRow key={index.toString()}>
                                                                <TableCell>{item.drawingNumber}</TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </Paper></span>
                                    : null}
                            </span>
                            : null}
                    </Dialog>
                    <Dialog open={saveChangesOpen}>
                        <DialogTitle id="Save Changes Alert">Save Changes?</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Do you wish to close without saving changes?
                            </DialogContentText>
                            <DialogActions>
                                <Button onClick={cancelSaveChanges} color="primary">Cancel</Button>
                                <Button onClick={closeSaveChangesNoSave} color="primary">Don't Save</Button>
                                <Button onClick={closeSaveChangesSave} color="primary">Save</Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={deleteDraftConfirmationOpen}>
                        <DialogTitle id="Delete Confirmation Alert">Delete Draft?</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you wish to delete this draft?
                            </DialogContentText>
                            <DialogActions>
                                <Button onClick={cancelDeleteDraftConfirmation} color="primary">Cancel</Button>
                                <Button onClick={saveDeleteDraftConfirmation} color="primary">Confirm</Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>
                    <Dialog fullScreen open={selectEmailGroupsOpen} onClose={() => { setSelectEmailGroupsOpen(false) }}>
                        <AppBar className={classes.apBar}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="close"
                                    onClick={() => { setSelectEmailGroupsOpen(false) }}
                                    size="large">
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" className={classes.title}>
                                    Select Email Groups
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <div style={{ paddingTop: '70px', paddingLeft: '5px', paddingBottom: '5px', paddingRight: '5px' }}>
                            {emailGroups && emailGroups.map((item, index) => {
                                return (
                                    <FormControlLabel
                                        key={index}
                                        value={item.id}
                                        label={item.groupName}
                                        control={
                                            <Checkbox
                                                checked={isEmailGroupChecked(item.id)}
                                                onChange={() => onEmailGroupCheckedChange(item.id)}
                                            />
                                        }
                                    />
                                );
                            })}
                        </div>
                    </Dialog>
                    {/* <Snackbar
                        open={SnackBarOpen}
                        autoHideDuration={5000}
                        onClose={handleSnackbarClose}
                        //TransitionComponent={GrowTransition}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <Alert onClose={handleSnackbarClose} severity={AlertColor === "success" ? "success" : "error"}>
                            {SnackBarText}
                        </Alert>
                    </Snackbar> */}
                </Container>
                :
                <Paper className={classes.root}>
                    <Typography variant="h6">
                        <ErrorIcon />&nbsp;Access Denied
                    </Typography>
                </Paper>
            }
        </Container>
    );
}
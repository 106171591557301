import React, { useState, useEffect } from "react";
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Container, TextField, Button, TablePagination ,Snackbar} from "@mui/material";
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { TablePaginationActions } from '../../actions/TablePaginationActions';
import Axios from "axios";
import ErrorIcon from '@mui/icons-material/Error';
import { getBaseURL } from "../../baseURL";
import SearchIcon from '@mui/icons-material/Search';
import GetAppIcon from '@mui/icons-material/GetApp';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useSelector } from "react-redux";
import MuiAlert from '@mui/material/Alert';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const AuditReport = (props) => {
  const[fromDate, setFromDate] =  useState(new Date() );
  const[toDate, setToDate] = useState(new Date());
  const[drawingNumber, setDrawingNumber] = useState("");
  const[userName, setUserName] = useState("");
  const[results, setResults] = useState([]);
  const[loading, setLoading] = useState(false);
  const[pageloading, setPageLoading] = useState(false);
  const[submitted, setSubmitted] = useState(false);
  const[open, setOpen] = useState(false);
  const[sortDate, setSortDate] = useState(false);
  const[sortDateDesc, setSortDateDesc] = useState(true);
  const [page, setPage] = React.useState(0);
  const[rowsPerPage, setRowsPerPage] = useState(20);
  const [SnackBarOpen, setSnackBarOpen] = useState(false);
  const [SnackBarText, setSnackBarText] = useState("");
 
  const[isAdmin, setIsAdmin] = useState(null);
  const [drawingError, setDrawingNumberError] = useState({ Error: false, Text: "" });

  const [userNameError, setuserNameError] = useState({ Error: false, Text: "" });
  const [fromDateError, setfromDateError] = useState({ Error: false, Text: "" });
  const [toDateError, settoDateError] = useState({ Error: false, Text: "" });
  const currentUser = useSelector(state => state.auth);
  const [AlertColor, setAlertColor] = useState("");
  const [totalCount, settotalCount] = useState(0);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  
  const config = {
    headers: {'Authorization': "Bearer " + currentUser.jwtIdToken.idToken}
  };

  const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(3, 2),
    },
    paperSpacing: {
      paddingTop: "30px",
    },
  }));

  useEffect(() => {
    if(isAdmin === null) {
        try {
            if(currentUser.user.permissions.find(p => p.permissionName === "System Admin").value === true) {
                setIsAdmin(true);
            }
            else {
                setIsAdmin(false);
            }
        }
        catch(error) {
            setIsAdmin(false);
        }

        if(!isAdmin) {
            try {
                if(currentUser.user.permissions.find(p => p.permissionName === "User Admin").value === true) {
                    setIsAdmin(true);
                }
                else {
                    setIsAdmin(false);
                }
            }
            catch(error) {
                setIsAdmin(false);
            }
        }
    }
}, []);

  const onDrawingNumberChange = event => {
    setDrawingNumber(event.target.value);

    if (drawingNumber) {
      setDrawingNumberError({ Error: false, Text: "" });
    }
  }

  const onUserNameChange = event => {
    setUserName(event.target.value);
    if (userName) {
      setuserNameError({ Error: false, Text: "" });
    }
  }

  const onFromDateChange = fromDate => {
    setFromDate(fromDate);
  }

  const onToDateChange = toDate => {
    setToDate(toDate);
  }

  const changeLoadingStatus = (isLoading) => {
    setLoading(isLoading);
  }


  
  const onSubmit = (event) => {
    event.preventDefault();
    if ((drawingNumber || userName) && fromDate && toDate) {
    
    setSubmitted(false);
    setLoading(true);

    const formData = {
      DrawingNumber: drawingNumber.trim(),
      Email: userName,
      StartDate: fromDate,
      EndDate: toDate,
      currentPage:page,
      pageSize:rowsPerPage
    }
    
      Axios.post(`${getBaseURL()}/admin/auditreport`,formData,config).then(o => {                 
        
        if(o.data.auditReport.length>0){
                setResults(o.data.auditReport);
                settotalCount(o.data.totalCount);
              }else{
                setAlertColor("success")
                setSnackBarOpen(true)
                setSnackBarText("No Records found")  
            }         
                      
               setLoading(false); 
               setSubmitted(true);
              }).catch((error) => {
              setLoading(false)
              setSnackBarOpen(true);
              setSnackBarText(error.response.data.message);
              console.log(error.response.data.message);
      
        //  SetTotalDrawingCount(res.data.totalCount);
      }).catch(err => {
         
      });
    
  
   
    // Axios.post(`${getBaseURL()}/admin/auditreport`, formData, config)
    //   .then(o => {        
    //     if(o.data.length>0){
    //       setResults(o.data);
    //     }else{
    //       setAlertColor("success")
    //       setSnackBarOpen(true)
    //       setSnackBarText("No Records found")  
    //   }          
                
    //      setLoading(false); 
    //      setSubmitted(true);
    //     }).catch((error) => {
    //     setLoading(false)
    //     setSnackBarOpen(true);
    //     setSnackBarText(error.response.data.message);
    //     console.log(error.response.data.message);
    //   })
  }
  else if (!drawingNumber || !userName) {
    setSnackBarOpen(true);
  setSnackBarText("Select Atleast Drawing Number or User Email")

  }
 
  }


  const handleClose = () => {
    setOpen(false);
  }
  const getauditReports = (pageisze,rows) => {
    const formData = {
      DrawingNumber: drawingNumber.trim(),
      Email: userName,
      StartDate: fromDate.toUTCString(),
      EndDate: toDate.toUTCString(),
      currentPage:pageisze,
      pageSize:rows
    }

    
      Axios.post(`${getBaseURL()}/admin/auditreport`,formData,config).then(o => {        
                  
        if(o.data.auditReport.length>0){
                setResults(o.data.auditReport);              
                setPageLoading(false)
              }                      
              
              }).catch((error) => {            
              console.log(error.response.data.message);     
     
      }).catch(err => {
         
      });
  }
  const handleChangePage = (event, newPage) => {    
    setPage(newPage);
    setPageLoading(false);
    getauditReports(newPage,rowsPerPage);
   
  };

  const handleChangeRowsPerPage = event => {
    
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    getauditReports(0,parseInt(event.target.value, 10))
  }
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarOpen(false);
  }

  const onExportClick = event => {

    if ((drawingNumber || userName) && fromDate && toDate) {
    
    setSubmitted(false);
      setLoading(true);

      const formData = {
        DrawingNumber: drawingNumber.trim(),
        Email: userName,
        StartDate: fromDate,
        EndDate: toDate
      };

      //RefreshToken();
      Axios.post(`${getBaseURL()}/admin/auditreport/download`, formData, config).then(res => {
        if(res.data.length>0){
          
          var reportArray = base64ToArrayBuffer(res.data);

          const blob = new Blob([reportArray], { type: "text/csv" });
          if(window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, "AuditReport.csv");
          }
          else {
            const link = document.createElement('a');  
            link.href = URL.createObjectURL(blob);
            link.download = "AuditReport.csv";
            link.click();
            link.remove();
  
            window.addEventListener('focus', e => URL.revokeObjectURL(link.href), {once: true});
          }
        }
        
        else{
          setAlertColor("success")
          setSnackBarOpen(true)
          setSnackBarText("No Records found") 
        }
      
        setLoading(false);
        setSubmitted(false);
      }).catch((error) => {
        setLoading(false)
        setSnackBarOpen(true);
        setSnackBarText(error.response.data.message);
        console.log(error.response.data.message);
      })

    }

      
  else if (!drawingNumber || !userName) {
    setSnackBarOpen(true);
    setSnackBarText("Select Atleast Drawing Number or User Email")

  }
  }

  const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  const clearUserName = () => {
    setUserName("");
  }

  const clearDrawingNumber = () => {
    setDrawingNumber("");
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, results.length - page * rowsPerPage);

  const classes = useStyles();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Container maxWidth={false} disableGutters={true}>
      {isAdmin ?
        <Container maxWidth={false} disableGutters={true}>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle id="requireDrawingOrUser">Missing Reqiured Field</DialogTitle>
            <DialogContent>
              <DialogContentText>You must enter either a drawing number, user email, or both</DialogContentText>
              <DialogActions>
                <Button onClick={handleClose}>Okay</Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
          <Typography variant="h4" gutterBottom>Audit Report</Typography>
          <Paper className={classes.root}>
            <Typography variant="h6" gutterBottom>Drawing Number or User Email Required</Typography>
            <form onSubmit={onSubmit}>
              <TextField
                id="drawingNumber"
                label="Drawing Number"
                onChange={onDrawingNumberChange}
                variant="outlined"
                margin="normal"
                fullWidth
                value={drawingNumber}
                helperText={drawingError.Error ? drawingError.Text : null}
                error={drawingError.Error}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton onClick={clearDrawingNumber} style={{ outline: 'none' }} size="large">
                        <CloseIcon opacity={drawingNumber.length > 0 ? 1 : 0} />
                      </IconButton>
                    </InputAdornment>
                }}
              />
              <br />
              <TextField
                id="userName"
                label="User Email"
                value={userName}
                onChange={onUserNameChange}
                variant="outlined"
                margin="normal"
                fullWidth
                helperText={userNameError.Error ? userNameError.Text : null}
                error={userNameError.Error}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton onClick={clearUserName} style={{ outline: 'none' }} size="large">
                        <CloseIcon opacity={userName.length > 0 ? 1 : 0} />
                      </IconButton>
                    </InputAdornment>
                }}
              />
              <br />
              <DatePicker
                label="From Date"
                value={fromDate}
                onChange={(newValue) => onFromDateChange(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label="To Date"
                value={toDate}
                onChange={(newValue) => onToDateChange(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
              <br />
              <Button variant="contained" type="submit" color="primary"><SearchIcon />&nbsp;Search Report</Button>
              &nbsp;&nbsp;&nbsp;
              <Button variant="contained" color="primary" onClick={onExportClick}><GetAppIcon />&nbsp;Export Report</Button>
            </form>
          </Paper>
          <br />
          {loading ? <CircularProgress style={{ marginLeft: '50%' }} /> : null}

          <br />
          <br />
          {(submitted && !loading && results.length > 0) &&
            <Paper className={`${classes.root} ${'MuiPaper-elevation4'} ${classes.paperSpacing}`} p={5} style={{ overflow: 'auto' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>Drawing Number</TableCell>
                    <TableCell>Revision</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Classification Level</TableCell>
                    <TableCell>Action</TableCell>
                    <TableCell>Emailed To</TableCell>
                    <TableCell>Group</TableCell>
                    <TableCell>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{item.email}</TableCell>
                        <TableCell>{item.drawingNumber}</TableCell>
                        <TableCell>{item.revision}</TableCell>
                        <TableCell>{item.description}</TableCell>
                        <TableCell>{item.classificationLevel}</TableCell>
                        <TableCell>{item.action}</TableCell>
                        <TableCell>{item.emailedTo ? item.emailedTo : ""}</TableCell>
                        <TableCell>{item.groupName ? item.groupName : ""}</TableCell>
                        <TableCell>{new Date(item.date).toLocaleDateString() + ' ' + new Date(item.date).toLocaleTimeString()}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 25, 50]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'next page',
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />

            </Paper>
          }
          {pageloading ? <CircularProgress style={{ width: '40px', height: '40px', position: 'fixed', left: '50%', top: '50%' }} />
            : null}
          <Snackbar
            open={SnackBarOpen}
            autoHideDuration={8000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert onClose={handleSnackbarClose} severity={AlertColor === "success" ? "success" : "error"}>
              {SnackBarText}
            </Alert>
          </Snackbar>
        </Container>
        :
        <Paper className={classes.root}>
          <Typography variant="h6">
            <ErrorIcon />&nbsp;Access Denied
          </Typography>
        </Paper>
      }
    </Container>
  </LocalizationProvider>
  );
}
import axios from 'axios';
import { useSelector } from "react-redux";
import { getBaseURL } from "../../baseURL";
import Select from '@mui/material/Select';
import ErrorIcon from '@mui/icons-material/Error';
import { green } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import React, { useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import DataTable from 'react-data-table-component';
import CachedIcon from '@mui/icons-material/Cached';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import { Container, MenuItem, Paper, TextField, Button } from "@mui/material";
const columns = [
  {
    name: 'Drawing Number',
    selector: row => `${row.drawingNumber} `,
    sortable: true
  },
  {
    name: 'Description',
    selector: row => `${row.description} `,
    sortable: true
  },
  {
    name: 'Model',
    selector: row => `${row.model} `,
    sortable: true
  },
  {
    name: 'Revision',
    selector: row => `${row.revision} `,
    sortable: true
  },
  {
    name: 'Image',
    selector: row => `${row.byteArray === "" || row.byteArray === null ? "No" : "Yes"} `,
    sortable: true
  }
];
export const DrawingSummary = () => {
  const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(2, 2)
    },
    helperText: {
      marginLeft: 0
    },
    wrapper: {
      position: 'relative'
    },
    searchButtonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      marginTop: -10,
      marginLeft: -74
    },
    searchTextBoxStyle: {
      width: 300
    },
    refreshButtonStyle: {
      marginTop: 28,
      height: 42
    }
  }));

  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 8;
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const[latest, setLatest] = useState(false);
  const[searchText, setSearchText] = useState("");
  const [drawings, setDrawinglist] = useState({});
  const currentUser = useSelector(state => state.auth);
  const [countPerPage, setcountPerPage] = useState(10);
  const [pageloading, setPageLoading] = useState(false);
  const [isExactMatch, setIsExactMatch] = useState(false);
  const[searchLoading, setSearchLoading] = useState(false);
  const [TotalDrawingCount, SetTotalDrawingCount] = useState(0);
  const [IsFeatureEnable, setIsFeatureEnable] = useState(false);
  const [LoaderFeatureEnable, setLoaderFeatureEnable] = useState(false);
  const[drawingError, setDrawingError] = useState({Error: false, Text: ""});
  const [DrawingSummaryFilteration, setDrawingSummaryFilteration] = useState({ Name: "" });

  const config = {
    headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken, 'Content-Type': "multipart/form-data" }
  };
  const summaryData = [{ id: "", name: "All" }, { id: 1, name: "Yes" }, { id: 2, name: "No" }]

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    getDrawings();
  }, [page]);
  const getDrawings = () => {
    setPageLoading(true)
    let encodeSearchingText = encodeURIComponent(searchText.trim())
    axios.get(`${getBaseURL()}/documents/DrawingSummary?currentPage=${page}&pageSize=${countPerPage}&Filter=${DrawingSummaryFilteration.Name}&searchDrawing=${encodeSearchingText}&isExactMatch=${isExactMatch}&isLatestRevision=${latest}`, config).then(res => {
      setDrawinglist(res.data.drawingSummary);
      SetTotalDrawingCount(res.data.totalCount);
      setPageLoading(false)
    }).catch(err => {
      setDrawinglist({});
      setPageLoading(false)
    });
  }
  useEffect(() => {
    setIsFeatureEnable(currentUser.featurecontrolList.find(x => x.name == "Drawing Summary").grantByAdmin);
       getDrawings();
  }, [countPerPage]);

  useEffect(() => {
    try {
      if (currentUser.user.permissions.find(p => p.permissionName === "KTIP Loader").value === true) {
        setLoaderFeatureEnable(true);
      }
      else {
        setLoaderFeatureEnable(false);
      }
    }
    catch (error) {
      setLoaderFeatureEnable(false);
    }
  }, []);

  const handleChangeRowsPerPage = event => {
    setPageLoading(true)
    setcountPerPage(event)
  }
  const handleChangePage = event => {
    setPageLoading(true)
    setPage(event)
  }
  const onchangeSummary = event => {
    setPageLoading(true)
    setDrawingSummaryFilteration({ Name: event.target.value })
    let encodeSearchingText = encodeURIComponent(searchText.trim())
    axios.get(`${getBaseURL()}/documents/DrawingSummary?currentPage=${page}&pageSize=${countPerPage}&Filter=${event.target.value}&searchDrawing=${encodeSearchingText}&isExactMatch=${isExactMatch}&isLatestRevision=${latest}`, config).then(res => {
      setDrawinglist(res.data.drawingSummary);
      SetTotalDrawingCount(res.data.totalCount);
      setPageLoading(false)
    }).catch(err => {
      setDrawinglist({});
    });
  }

  const onSearchTextChange = event => {
    setSearchText(event.target.value);

    if (searchText) {
      setDrawingError({ Error: false, Text: "" });
    }
  }

  const handleEnter = (event) => {
    if(event.key === 'Enter') {
      onSubmit();
    }
  }

  const onExactMatchChanged = event => {
    setIsExactMatch(event.target.checked);
  }

  const onLatestChanged = event => {
    setLatest(event.target.checked);
  }

  const refreshDrawings = () => {
    setSearchText("");
    setDrawingSummaryFilteration({ Name: "" })
    setLatest(false);
    setIsExactMatch(false);
    let searchingText = '';
    let searchingFilter = '';
    setPageLoading(true)
    axios.get(`${getBaseURL()}/documents/DrawingSummary?currentPage=${page}&pageSize=${countPerPage}&Filter=${searchingFilter}&searchDrawing=${searchingText}&isExactMatch=${isExactMatch}&isLatestRevision=${latest}`, config).then(res => {
      setDrawinglist(res.data.drawingSummary);
      SetTotalDrawingCount(res.data.totalCount);
      setPageLoading(false)
    }).catch(err => {
      setDrawinglist({});
      setPageLoading(false)
    });
  }

  const onSubmit = () => {
    setDrawinglist({});
    if (searchText) {
      setPageLoading(true)
      setSearchLoading(true)
      let encodeSearchingText = encodeURIComponent(searchText.trim())
      axios.get(`${getBaseURL()}/documents/DrawingSummary?currentPage=${page}&pageSize=${countPerPage}&Filter=${DrawingSummaryFilteration.Name}&searchDrawing=${encodeSearchingText}&isExactMatch=${isExactMatch}&isLatestRevision=${latest}`, config).then(res => {
        setDrawinglist(res.data.drawingSummary);
        SetTotalDrawingCount(res.data.totalCount);
        setDrawingError({ Error: false, Text: "" });
        setPageLoading(false)
        setSearchLoading(false)
      }).catch(err => {
        setDrawinglist({});
        setPageLoading(false)
        setSearchLoading(false)
      });
    }
    else {
      setDrawingError({ Error: true, Text: "Enter a drawing number" });
      setDrawinglist({});
      setPageLoading(false)
      setSearchLoading(false)
    }
  }
  
  return (
    <Container maxWidth={false} disableGutters={true}>
      {LoaderFeatureEnable ?
        <div className="App">
          <h3>Drawing Summary</h3>
          <Paper className={classes.root}>
            <TextField
              id="drawingNumber"
              label="Drawing Number"
              value={searchText}
              onChange={onSearchTextChange}
              variant="outlined"
              margin="normal"
              className={classes.searchTextBoxStyle}
              helperText={drawingError.Error ? drawingError.Text : null}
              error={drawingError.Error}
              FormHelperTextProps={{ classes: { root: classes.helperText } }}
              onKeyPress={handleEnter}
            />
            <div style={{ float: "right", marginTop: "15px", display:"flex", alignItems:"center" }}>
              <div style={{ marginRight:"10px" }}>
              <InputLabel htmlFor="Filteration">Filter By Image</InputLabel>
              <Select
                value={DrawingSummaryFilteration.Name}
                displayEmpty
                onChange={onchangeSummary}
                style={{ minWidth: "150px", marginRight: "10px", height: 50, marginTop: -7 }}
              >
                {summaryData.map((item, i) =>
                  <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                )}
              </Select>
              </div>
              <Button
                color="primary"
                variant="contained"
                className={classes.refreshButtonStyle}
                onClick={refreshDrawings}
              >
                <CachedIcon />&nbsp;Refresh
              </Button>
            </div>
            <FormGroup row> 
              <FormControlLabel control={<Checkbox checked={isExactMatch} color="primary" onChange={onExactMatchChanged} />} label="Exact Match" />
            </FormGroup>
            <FormGroup row>
            <FormControlLabel control={<Checkbox checked={latest} color="primary" onChange={onLatestChanged} />} label="Latest Revision Only" />
          </FormGroup>
            <span className={classes.wrapper}>
              <Button
                color="primary"
                variant="contained"
                onClick={onSubmit}
                disabled={searchLoading}
              >
                <SearchIcon />&nbsp;Search
              </Button>
              {searchLoading && <CircularProgress size={24} className={classes.searchButtonProgress} />}
            </span>
          </Paper>
          <br />

          <DataTable
            columns={columns}
            data={drawings}
            highlightOnHover
            pagination
            paginationServer
            paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
            paginationTotalRows={TotalDrawingCount}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
              noRowsPerPage: false
            }}
            onChangeRowsPerPage={
              handleChangeRowsPerPage
            }
            onChangePage={handleChangePage}
          />
          {pageloading ? <CircularProgress style={{ width: '40px', height: '40px', position: 'fixed', left: '50%', top: '50%' }} />
            : null}
        </div>
        : <Paper>
          <Typography variant="h6">
            <ErrorIcon />&nbsp;Access Denied
          </Typography>
        </Paper>}
    </Container>
  );
}
import Axios from "axios";
import { useSelector } from "react-redux";
import Grid from '@mui/material/Grid';
import { getBaseURL } from "../../baseURL";
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import { useHistory } from "react-router-dom";
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState, useEffect } from "react";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { green, red } from '@mui/material/colors';
import { getPdfFiles, ViewPdfFiles } from '../../actions/actions';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { EmailFiles } from "../miscellaneous/EmailFiles";
import CircularProgress from '@mui/material/CircularProgress';
import { Container, TextField, Button, Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export const BOMExplode = (props) => {
  const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(2, 2),
    },
    paperSpacing: {
      paddingTop: "30px",
    },
    wrapper: {
      position: 'relative',
      marginLeft: '10px'
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    buttonError: {
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[700],
      },
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -5,
      marginLeft: -12,
    },
    buttonProgressDownload: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -10,
      marginLeft: -12,
    },
    paperSpacing: {
      paddingTop: "30px",
    },
    helperText: {
      marginLeft: 0,
    },
    buttonProgressDownloadAll: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -10,
      marginLeft: -12,
    },
    levelOne: {
      marginLeft: 5
    },
    levelTwo: {
      marginLeft: 10
    },
    levelThree: {
      marginLeft: 15
    },
    gridHeading: {
      fontWeight: 550
    }
  }));

  const classes = useStyles();
  const [part, setPart] = useState("");
  const [drawings, setDrawings] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectAllDisabled, setSelectAllDisabled] = useState(true);
  const [partError, setPartError] = useState({ Error: false, Text: "" });
  const [downloadAllDisabled, setDownloadAllDisabled] = useState(true);
  const [downloadAllDowloading, setDownloadAllDownloading] = useState(false);
  const [AlertColor, setAlertColor] = useState("");
  const [SnackBarText, setSnackBarText] = useState("");
  const [SnackBarOpen, setSnackBarOpen] = useState(false);
  const [activeBom, setActiveBom] = useState("true");

  let history = useHistory();
  const currentUser = useSelector(state => state.auth);
  const config = {
    headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken }
  };

  const onPartChange = event => {
    setPart(event.target.value);
    const { value } = event.target;
    const modifiedVal = value.replace(/\s+/g, ',');
    setPart(modifiedVal);
    if (event.target.value) {
      setPartError({ Error: false, Text: "" });
    }
  }

  const submit = () => {
    setDrawings({});

    if (part) {
      setIsLoading(true);
      setPartError({ Error: false, Text: "" });

      const commaSepratedPartNo = part;
      const dataArray = commaSepratedPartNo.split(',').map(val => val.trim());
      let newItems = [];
      dataArray.forEach((item, index) => {
        console.log(`Element ${index + 1}: ${item}`);
        const partNumber =
        {
          DrawingNumber: item,
          ActiveBom: (activeBom?.toLowerCase?.() === 'true')
        }
        newItems.push(partNumber);
      });

      let mergedPartNo = [];
      const requests = newItems.map(m => Axios.post(`${getBaseURL()}/documents/bomexplode`, m, config));

      Promise.all(requests)
        .then(res => {
          res.forEach(res => {
            console.log(res.data);
            //mergedPartNo.push(res.data);
            // const combinedArray = res.reduce((acc, curr) => [...acc, ...cur], []);
            res.data.forEach(arr => {
              mergedPartNo = mergedPartNo.concat(arr);
            });
            // setDrawings(res.data);
            setDrawings(mergedPartNo);
            setIsLoading(false);
          });
        }).catch(error => {
          console.error('Error : ', error);
        })

        //setDrawings(mergedPartNo);
        
      //console.log(combinedArray);
      //console.log(activeBom)
      // const partNumber = {
      //   DrawingNumber: part,
      //   ActiveBom: (activeBom?.toLowerCase?.() === 'true')
      // }
      // const res = newItems.forEach(spl => {
      //   Axios.post(`${getBaseURL()}/documents/bomexplode`, spl, config);
      // });
      // if (!res) {
      //   setDrawings(res.data);
      //   setIsLoading(false);
      // }
      // console.log(partNumber);

      // Axios.post(`${getBaseURL()}/documents/bomexplode`, partNumber, config)
      //   .then((response) => {
      //     setDrawings(response.data);
      //     setIsLoading(false);
      //   })
      //   .catch((error) => {
      //     setPartError({ Error: true, Text: error.response.data.message });
      //     setIsLoading(false);
      //   });
    }
    else {
      setPartError({ Error: true, Text: "Enter Part Number" });
    }
  }

  useEffect(() => {
    if (drawings && drawings.length > 0) {
      if (drawings.filter(x => x.isChecked === true).length > 0) {
        if (drawings.filter(x => x.isDownloading === true).length > 0) {
          setDownloadAllDisabled(true);
        }
        else {
          setDownloadAllDisabled(false);
        }
      }
      else {
        setDownloadAllDisabled(true);
        setSelectAllChecked(false);
      }
    }
  });

  const onCheckAllChange = event => {
    var drawingsArray = [...drawings];

    drawingsArray.filter(x => (x.classificationLevel <= currentUser.user.classification.classLevel || x.hasSpecialAccess) && x.id.length !== 0 && x.id !== 0).forEach(x => x.isChecked = event.target.checked);

    setDrawings(drawingsArray);
    setSelectAllChecked(event.target.checked);
  }

  const onCheckedChange = event => {
    var drawingsArray = [...drawings];

    drawingsArray.filter(x => (x.classificationLevel <= currentUser.user.classification.classLevel || x.hasSpecialAccess) && x.id == event.target.id).forEach(x => x.isChecked = event.target.checked);

    setDrawings(drawingsArray);

    var userDrawings = [...drawingsArray].filter(x => (x.classificationLevel <= currentUser.user.classification.classLevel || x.hasSpecialAccess));
    if (userDrawings.filter(x => x.isChecked).length === userDrawings.length) {
      setSelectAllChecked(true);
    }
    else {
      setSelectAllChecked(false);
    }
  }


  const handleDownloadClick = (drawingId) => {
    var drawingsArray = [...drawings];
    drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = true);
    setDrawings(drawingsArray);

    var selectedFile = [...drawings].filter(x => x.id === drawingId);
    selectedFile.find(x => x.id === drawingId).isChecked = true;

    getPdfFiles(selectedFile, currentUser.jwtIdToken.idToken)
      .then((response) => {
        drawingsArray = [...drawings];
        drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isChecked = false);
        drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = false);
        setDrawings(drawingsArray);
      });
  }

  const handleViewClick = (drawingId) => {
    var drawingsArray = [...drawings];
    drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = true);
    setDrawings(drawingsArray);

    var selectedFile = [...drawings].filter(x => x.id === drawingId);
    selectedFile.find(x => x.id === drawingId).isChecked = true;

    ViewPdfFiles(selectedFile, currentUser.jwtIdToken.idToken)
      .then((response) => {
        drawingsArray = [...drawings];
        drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isChecked = false);
        drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = false);
        setDrawings(drawingsArray);
      })
      .catch((error) => {
        drawingsArray = [...drawings];
        drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isChecked = false);
        drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = false);
        setDrawings(drawingsArray);
        setAlertColor("error");
        setSnackBarText(error.response.data);
        setSnackBarOpen(true);
      });
  }

  const downloadAllClick = () => {
    if (drawings.filter(x => x.isChecked).length > 0) {
      setDownloadAllDownloading(true);
      getPdfFiles(drawings, currentUser.jwtIdToken.idToken)
        .then((response) => {
          var drawingsArray = [...drawings];
          drawingsArray.forEach(x => x.isChecked = false);
          setDownloadAllDownloading(false);
        })
        .catch((error) => {
          setDownloadAllDownloading(false);
        })
    }
  }

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      submit();
    }
  }

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
  }

  const levelNumber = (level) => {
    if (level === null) {
      return 0;
    }

    return parseInt(level);
  }

  const onActiveBomChange = (event) => {
    setActiveBom(event.target.value);
  }

  return (
    <Container maxWidth={false} disableGutters={true}>
      <Typography variant="h4" gutterBottom>BOM Explode</Typography>
      <Paper className={classes.root}>
        <TextField
          id="partNumber"
          label="Part Number"
          variant="outlined"
          color="primary"
          fullWidth
          value={part}
          onChange={onPartChange}
          error={partError.Error}
          helperText={partError.Error ? partError.Text : null}
          FormHelperTextProps={{ classes: { root: classes.helperText } }}
          onKeyPress={handleEnter}
        />

        <Typography variant="subtitle1"><span style={{ color: "red" }}>Warning: </span> BOM Explode may produce a large number of drawings and timeouts may occur. Please do not attempt on machine BOMs.</Typography>
        <span className={classes.wrapper}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: "15px" }}
            onClick={submit}
            disabled={isLoading}
          >
            <SearchIcon />&nbsp;Search
          </Button>
          {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </span>
        <RadioGroup
          value={activeBom}
          onChange={onActiveBomChange}
        >
          <FormControlLabel value={"true"} control={<Radio />} label="Active BOM" />
          <FormControlLabel value={"false"} control={<Radio />} label="Engineering BOM (Alt 01)" />
        </RadioGroup>
      </Paper>
      <br />
      <br />
      {(drawings.length > 0 && !isLoading && !partError.Error) ?
        <div>
          <Paper className={classes.root}>
            <Grid container spacing={1}>
              <Grid item xs={1}>
                <span className={classes.wrapper}>
                  <Button
                    id="downloadAll"
                    variant="contained"
                    color="primary"
                    disabled={downloadAllDisabled || downloadAllDowloading}
                    style={{ marginTop: "25%" }}
                    onClick={downloadAllClick}
                  >
                    {downloadAllDowloading && <CircularProgress size={24} className={classes.buttonProgressDownloadAll} />}
                    Download
                  </Button>
                </span>
              </Grid>
              {currentUser.user.permissions.filter(p => p.permissionName === "Email Files" && p.value === true).length > 0 &&
                <Grid item xs={11}>
                  <EmailFiles drawings={drawings.filter(x => x.isChecked)} />
                </Grid>
              }
            </Grid>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><Checkbox id={"selectAll"} checked={selectAllChecked} onChange={onCheckAllChange} color="primary" /></TableCell>
                  <TableCell className={classes.gridHeading}>Drawing Number</TableCell>
                  <TableCell className={classes.gridHeading}>Part Number</TableCell>
                  {/* searchedPartNumber */}
                  <TableCell className={classes.gridHeading}>Revision</TableCell>
                  <TableCell className={classes.gridHeading}>Description</TableCell>
                  <TableCell className={classes.gridHeading}>Model</TableCell>
                  <TableCell className={classes.gridHeading}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {drawings.filter(d => d.id !== 0).map((item, index) => {
                  return (
                    <TableRow key={index.toString()}>
                      <TableCell><Checkbox id={item.id.toString()} checked={item.isChecked} color="primary" onChange={onCheckedChange} disabled={(item.classificationLevel > currentUser.user.classification.classLevel && !item.hasSpecialAccess)} /></TableCell>
                      <TableCell>
                        {item.drawingNumber}<sup style={{ color: 'red' }}>{item.f}</sup>
                        {item.f.length > 0 ?
                          <Typography variant="subtitle2">{item.materialNumber}</Typography>
                          : null}
                        {/* <span style={{paddingLeft: levelNumber(item.level) * 10}}></span> */}
                      </TableCell>
                      <TableCell>{item.searchedPartNumber}</TableCell>
                      <TableCell>{item.revision}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{item.model}</TableCell>
                      {item.classificationLevel != null ?
                        (item.classificationLevel <= currentUser.user.classification.classLevel || item.hasSpecialAccess) ?
                          (item.documentCount && item.documentCount > 0) ?
                            <TableCell>
                              <span className={classes.wrapper}>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={() => handleDownloadClick(item.id)}
                                  disabled={item.isDownloading}
                                >
                                  Download
                                </Button>
                                {item.isDownloading && <CircularProgress size={24} className={classes.buttonProgressDownload} />}
                              </span>
                              {(currentUser.user.permissions.some(p => p.permissionName === "View Button") && currentUser.user.permissions.find(p => p.permissionName === "View Button").value === true) &&
                                <span className={classes.wrapper}>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => handleViewClick(item.id)}
                                    disabled={item.isDownloading}
                                  >
                                    View
                                  </Button>
                                  {item.isDownloading && <CircularProgress size={24} className={classes.buttonProgressDownload} />}
                                </span>
                              }
                            </TableCell>
                            :
                            <TableCell>
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => history.push("/scanrequest/" + item.drawingNumber)}
                              >
                                Scan Request
                              </Button>
                            </TableCell>
                          : <TableCell>
                            <Button color="primary" onClick={() => history.push("/specialaccessrequest/" + item.drawingNumber)}>Request Access</Button>
                          </TableCell>
                        : <TableCell></TableCell>
                      }
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>

          {(drawings.filter(x => x.id === 0).length > 0) ?
            <span>
              <Typography variant="h5" gutterBottom style={{ paddingTop: '10px' }}>Drawings Not Found</Typography>
              <Paper className={classes.root}>
                <Table>
                  <TableBody>
                    {drawings.filter(d => d.id === 0).map((item, index) => {
                      return (
                        <TableRow key={index.toString()}>
                          <TableCell>{item.drawingNumber}</TableCell>
                          <TableCell>{item.searchedPartNumber}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Paper></span>
            : null}
        </div>
        : null}
      <Snackbar
        open={SnackBarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={AlertColor === "success" ? "success" : (AlertColor === "info" ? "info" : "error")}>
          {SnackBarText}
        </Alert>
      </Snackbar>
    </Container>
  )
}
import clsx from 'clsx';
import Axios from "axios";
import { useSelector } from "react-redux";
import { getBaseURL } from "../../baseURL";
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import Checkbox from '@mui/material/Checkbox';
import React, { useState, useEffect } from "react";
import {green, red} from '@mui/material/colors';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import RadioGroup from '@mui/material/RadioGroup';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Container, TextField, Button, Snackbar } from "@mui/material";

export const RequestAccess = () => {
  const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(2, 2),
    },
    paperSpacing: {
      paddingTop: "30px",
    },
    wrapper: {
      position: 'relative',
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    buttonError: {
        backgroundColor: red[500],
        '&:hover': {
          backgroundColor: red[700],
        },
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -5,
      marginLeft: -12,
    },
    paperSpacing: {
      paddingTop: "30px",
    },
    helperText: {
      marginLeft: 0,
    },
  }));

  const classes = useStyles();
  // const [assetNumber, setAssetNumber] = useState();
  const [hasAccess, setHasAccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [printNeeded, setPrintNeeded] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [userDeactivate, setuserDeactivate] = useState(false);
  const [classificationLevels, setClassificationLevles] = useState([]);
  const [businessJustification, setBusinessJustification] = useState("");
  const [selectedClassification, setSelectedClassification] = useState(0);
  const [submitError, setSubmitError] = useState({Error: false, Text: ""});
  // const [assetNumberError, setAssetNumberError] = useState({Error: false, Text: ""});
  const [businessJustificationError, setBusinessJustificationError] = useState({Error: false, Text: ""});
  const buttonClassname = clsx({
    [classes.buttonError]: submitError.Error,
  });

  const currentUser = useSelector(state => state.auth);
  const config = {
    headers: {'Authorization': "Bearer " + currentUser.jwtIdToken.idToken}
  };

  useEffect(() => {    
    if(classificationLevels.length === 0) {
      Axios.get(`${getBaseURL()}/admin/getclassificationlevels`, config)
      .then((response) => {
        setClassificationLevles(response.data);
      })
    }

    if(selectedClassification === 0 && classificationLevels.length !== 0) {
      setSelectedClassification(classificationLevels[0].id);
    }

    if (currentUser.user.permissions !== undefined) {
      if (currentUser.user.permissions.length > 0) {
        if (currentUser.user.permissions.find(p => p.permissionName === "Access State").accountActive === false) {
          setuserDeactivate(true);
        }
      }
    }
    
    // if(currentUser && currentUser.user && currentUser.user.classification) {
    //   setHasAccess(true);
    // }

    // console.log(hasAccess);
  }, []);

  const onClassificationLevelChange = event => {
    setSelectedClassification(parseInt(event.target.value));
  }

  const onPrintNeededChange = event => {
    setPrintNeeded(event.target.checked);
  }

  const onBusinessJustificationChange = event => {
    setBusinessJustification(event.target.value);

    if(event.target.value) {
      setBusinessJustificationError({Error: false, Text: ""});
    }
  }

  // const onAssetNumberChange = event => {
  //   setAssetNumber(event.target.value);

  //   if(event.target.value) {
  //     setAssetNumberError({Error: false, Text: ""});
  //   }
  // }

  const onSubmit = () => {
    if(businessJustification ) {  //&& assetNumber
      const AccessRequest = {
        RequestPrint: printNeeded,
        ClassLevel: selectedClassification,
        Justification: businessJustification,
         AssetNumber: 'NULL',
        AccessReason: 'NA',
      }
      setIsLoading(true);
      Axios.post(`${getBaseURL()}/users/accessrequest/new`, AccessRequest, config)
        .then((response) => {
          setIsLoading(false);
          setIsSubmitted(true);
        })
        .catch((error) => {
          setSubmitError({Error: true, Text: error.response.data.message});
          setIsLoading(false);
        });
    }
    else {
      if(!businessJustification) {
        setBusinessJustificationError({Error: true, Text: "Enter Justification"});
      }
      
      // if(!assetNumber) {
      //   setAssetNumberError({Error: true, Text: "Enter Computer Asset Number"});
      // }
    }
  }

  return (
    <Container>
      <Typography variant="h4">{userDeactivate ? "Your account has been deactivated. Please apply again for access." : "Request Access"}   </Typography>
      {!isSubmitted ?
        <React.Fragment>
          {/* {hasAccess ? (
            <Typography variant="subtitle1">To change your access level, please submit a new request below</Typography>
            ) : (
            <Typography  variant="subtitle1">You do not currently have access. Please submit a request</Typography>
            )} */}
          <Paper className={classes.root}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Access Level</FormLabel>
              <RadioGroup value={selectedClassification} onChange={onClassificationLevelChange} row>
                {classificationLevels.map((item, index) => {
                  return (
                    <FormControlLabel
                      value={item.id}
                      control={<Radio color="primary" />}
                      label={item.description}
                      labelPlacement="end"
                      key={item.id}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
            <FormGroup row>
              <FormControlLabel
                control={<Checkbox checked={printNeeded} onChange={onPrintNeededChange} color="primary" />}
                label="Print Access Needed?"
              />
            </FormGroup>
            {/* <TextField
              variant="outlined"
              label="Computer Asset Number"
              helperText={<span><span style={{ color: "red" }}>Important: </span> You will NOT get the required software to view drawings if this is missing or incorrect</span>}
              value={assetNumber}
              onChange={onAssetNumberChange}
              FormHelperTextProps={{ classes: { root: classes.helperText } }}
              error={assetNumberError.Error}
            /> */}
            <TextField
              id="businessJustification"
              value={businessJustification}
              onChange={onBusinessJustificationChange}
              variant="outlined"
              label="Business Justification"
              fullWidth
              multiline
              rows="10"
              helperText={businessJustificationError.Error ? businessJustificationError.Text : null}
              error={businessJustificationError.Error}
              FormHelperTextProps={{ classes: { root: classes.helperText } }}
              style={{ marginTop: '10px' }}
            />
            <br />
            <br />
            <span className={classes.wrapper}>
              <Button
                id="submit"
                color="primary"
                variant="contained"
                onClick={onSubmit}
                className={buttonClassname}
                disabled={isLoading}
              >
                {(!isLoading && !submitError.Error) && <SendIcon />}
                {(!isLoading && submitError.Error) &&
                  <ErrorIcon />
                }
                &nbsp;Submit
              </Button>
              {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </span>

            {submitError.Error &&
              <Typography variant="subtitle1" style={{ color: "red", marginTop: 10 }}>{submitError.Text}</Typography>
            }
          </Paper>
        </React.Fragment>
        :
        <Paper className={classes.root}>
          <Typography variant="h6">
            <CheckIcon />&nbsp;Your request has been submitted and a confirmation email has been sent
          </Typography>
        </Paper>
      }
    </Container>
  )
}
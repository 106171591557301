import clsx from 'clsx';
import Axios from "axios";
import gql from 'graphql-tag';
import "../miscellaneous/File.css";
import { useSelector } from "react-redux";
import Chip from '@mui/material/Chip';
import { useQuery } from '@apollo/client';
import { getBaseURL } from "../../baseURL";
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Drafts from '@mui/icons-material/Drafts';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import Toolbar from '@mui/material/Toolbar';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState, useEffect } from "react";
import Typography from '@mui/material/Typography';
import { green, red } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Autocomplete from '@mui/material/Autocomplete';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContentText from '@mui/material/DialogContentText';
import { Container, Snackbar, TextField, Button, FormControlLabel, Checkbox, Grid, InputAdornment } from "@mui/material";
import { RichTextEditorComponent, Toolbar as syncfusionToolbar, Inject, Link, HtmlEditor, Count, QuickToolbar, Table, Image, FileManager } from '@syncfusion/ej2-react-richtexteditor';
import { useHistory } from "react-router-dom";
import { isError } from '@microsoft/applicationinsights-core-js';

export const EmailFiles = (props) => {
    const useStyles = makeStyles(theme => ({
        root: {
            //display: 'flex',
            //alignItems: 'center',
            //border: '1px solid black',
        },
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative'
        },
        buttonSuccess: {
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[700]
            }
        },
        buttonError: {
            backgroundColor: red[500],
            '&:hover': {
                backgroundColor: red[700]
            }
        },
        fabProgress: {
            color: green[500],
            position: 'absolute',
            top: -6,
            left: -6,
            zIndex: 1
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -5,
            marginLeft: -12
        },
        buttonDraftProgress: {
            color: green[500],
            position: 'absolute',
            marginTop: 17,
            marginLeft: -86
        },
        appBar: {
            position: 'relative'
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1
        }
    }));

    const GET_EMAIL_GROUPS = gql`
      query emailGroups($id: Int, $groupName: String) {
          emailGroups(id: $id, groupName: $groupName) {
              id,
              groupName
          }
      }
    `;

    var getEmailGroupsQuery = useQuery(GET_EMAIL_GROUPS);

    const classes = useStyles();
    const [email, setEmail] = useState([]);
    const [editor, setEditor] = useState();
    const [sent, setSent] = useState(false);
    const [open, setOpen] = useState(false);
    const [draftOpen, setDraftOpen] = useState(false);
    const [NewEmail, setNewEmail] = useState("");
    const [drawings, setDrawings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sentemail, setSentEmails] = useState([]);
    const [AlertColor, setAlertColor] = useState("");
    const [emailGroups, setEmailGroups] = useState([]);
    const [savedDraft, setSavedDraft] = useState(false);
    const [SnackBarText, setSnackBarText] = useState("");
    const [SnackBarOpen, setSnackBarOpen] = useState(false);
    const [saveChangesOpen, setSaveChangesOpen] = useState(false);
    const [saveDraftLoading, setSaveDraftLoading] = useState(false);
    const [sendError, setSendError] = useState({ error: false, text: "" });
    const [emailError, setEmailError] = useState({ error: false, text: "" });
    const [draftError, setDraftError] = useState({ error: false, text: "" });
    const [selectEmailGroupsOpen, setSelectEmailGroupsOpen] = useState(false);  
    const [drawingError, setDrawingError] = useState({ error: false, text: "" });
    const [subject, setSubject] = useState("Komatsu Mining Corp. Shared Drawings");
    const [groupSearchText, setGroupSearchText] = useState("");
    const currentUser = useSelector(state => state.auth);
    const config = {
        headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken },
        timeout: 240000
    };

    const toolbarItems = ['Bold', 'Italic', 'Underline', 'StrikeThrough',
        'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
        'LowerCase', 'UpperCase', '|',
        'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
        'Outdent', 'Indent', 'SuperScript', 'SubScript', '|',
        'CreateTable', 'Image', 'FileManager', 'CreateLink', '|', 'ClearFormat', 'Print',
        '|', 'Undo', 'Redo'];

    const toolbarSettings = { items: toolbarItems }

    const FileManagerSettingsModel = {
        enable: true,
        path: '/',
        ajaxSettings: {
            url: `${getBaseURL()}/files/FileOperations?userEmail=${currentUser.user.email}`,
            uploadUrl: `${getBaseURL()}/Files/Upload?userEmail=${currentUser.user.email}`,
        },

        contextMenuSettings: {
            visible: true,
            file: ['Delete', '|', 'Details'],
            folder: ['Delete', '|', 'Details'],
            layout: ['SortBy', 'View', 'Refresh', '|', 'Upload', '|', 'Details']
        },
        navigationPaneSettings: {
            visible: true,
            items: [
                'Upload', 'Delete', 'Download',
                'SortBy', 'Refresh', 'Selection', 'View', 'Details'
            ]
        },
        toolbarSettings: { visible: true, items: ['Upload', 'SortBy', 'Refresh', 'View', 'Delete'] },
        uploadSettings: { allowedExtensions: ".doc, .docx, .pdf, .xls, .xlsx" }
    }

    const Alert = (props) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const buttonClassname = clsx({
        [classes.buttonSuccess]: sent,
        [classes.buttonError]: sendError.error,
    });

    const saveDraftButtonClassname = clsx({
        [classes.buttonSuccess]: savedDraft,
        [classes.buttonError]: draftError.error,
    });

    let history = useHistory();

    useEffect(() => {
        getSentEmails();
    }, []);

    const getSentEmails = () => {
        Axios.get(`${getBaseURL()}/faq/getSentEmails`, config)
            .then(response => {
                
                setSentEmails(response.data);
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        if (props.drawings !== drawings) {
            if (sent) {
                setSent(false);
            }
            if(savedDraft) {
                setSavedDraft(false);
            }
        }
        setDrawings(props.drawings);

        if (drawings.length > 0 && drawingError.error) {
            setDrawingError({ error: false, text: "" });
        }
    });

    useEffect(() => {
        if(getEmailGroupsQuery && getEmailGroupsQuery.data) {
            var emailGroups = [...getEmailGroupsQuery.data.emailGroups];
            console.log(emailGroups);
            //sort groups alphabetically
            emailGroups.sort((a, b) => a.groupName.localeCompare(b.groupName))
            setEmailGroups(emailGroups);
        }

    }, [getEmailGroupsQuery])

    const onSubmit = () => {
        var isError = false;
        if (drawings.length < 1) {
            setDrawingError({ error: true, text: "Select at least one drawing" });
            isError = true;
        }
        
        if (email.length < 1 && NewEmail.length < 1) {
            setEmailError({ error: true, text: "Enter email address" });
            isError = true;
        } else {
            setEmailError({ error: false, text: "" });
        }
        if (!isError) {
            setLoading(true);
            setDrawingError({ error: false, text: "" });
            setEmailError({ error: false, text: "" });
            setSent(false);
            setSendError({ error: false, text: "" });
            const Message = {
                Drawings: drawings,
                toEmails: email,
                Email: NewEmail,
                CustomMessage: editor,
                Subject: subject,
                Groups: emailGroups.filter(x => x.checked === true),
                CurrentUserEmail: currentUser.user.email
            }

            Axios.post(`${getBaseURL()}/documents/emailfiles`, Message, config)
                .then((response) => {
                    setSent(true);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    setSendError({ error: true, text: "Error while sending emails!" });
                    // if(error.response != undefined)
                    //     setSendError({ error: true, text: error.response.data.message })
                });
        }
    }

    const draftChecks = () => {
        var isError2 = false;
        if (drawings.length < 1) {
            setDrawingError({ error: true, text: "Select at least one drawing" });
            isError2 = true;
        }

        if (email.length < 1 && NewEmail.length < 1) {
            setEmailError({ error: true, text: "Enter email address" });
            isError2 = true;
        } else {
            setEmailError({ error: false, text: "" });
        }
        if(!isError2){
            handleDraftOpen();
        } 
    }
    
    const onDraftSubmit = () => {        
        if (savedDraft) {
            setAlertColor("info")
            setSnackBarOpen(true);
            setSnackBarText("Draft has already been saved!");
        }
        else {
            var isError = false;
            if (drawings.length < 1) {
                setDrawingError({ error: true, text: "Select at least one drawing" });
                isError = true;
            }

            if (email.length < 1 && NewEmail.length < 1) {
                setEmailError({ error: true, text: "Enter email address" });
                isError = true;
            } else {
                setEmailError({ error: false, text: "" });
            }

            if (!isError) {
                
                setSaveDraftLoading(true);
                setDrawingError({ error: false, text: "" });
                setEmailError({ error: false, text: "" });
                setSavedDraft(false);
                setDraftError({ error: false, text: "" });
                let drawingIds = drawings.map(({ id }) => id);
                drawingIds = drawingIds.filter((el, i, a) => i === a.indexOf(el));
                let groupIds = emailGroups.filter(x => x.checked === true).map(({ id }) => id)
                const saveDraftModel = {
                    Email: NewEmail,
                    Message: editor,
                    SendEmails: email.join(),
                    GroupIds: groupIds.join(),
                    DrawingIds: drawingIds.join(),
                    Subject: subject
                }

                Axios.post(`${getBaseURL()}/documents/SaveUpdateDraft`, saveDraftModel, config)
                    .then((response) => {
                        setSavedDraft(true);
                        setSaveDraftLoading(false);
                        setAlertColor("success")
                        setSnackBarOpen(true);
                        setSnackBarText(response.data.message);
                        
                    })
                    .catch((error) => {
                        setSaveDraftLoading(false);
                        setAlertColor("")
                        setSnackBarOpen(true);
                        setSnackBarText(error.response.data.message);
                        setDraftError({ error: true, text: error.response.data.message })
                    });
            }
        }
    }

    const onEmailChange = event => {
        if (sent) {
            setSent(false);
        }
        if(savedDraft) {
            setSavedDraft(false);
        }

        setEmail(event.target.value);

        if (event.target.value.length > 0) {
            setEmailError({ error: false, text: "" });
        }
    }

    const handleDraftOpen = () => {
        setDraftOpen(true);
    }

    const handleDraftClose= () => {
        onDraftSubmit();
        setDraftOpen(false);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const onAddMessageClick = event => {       
        setOpen(true);
    }

    const onSubjectChange = (event) => {
        setSubject(event.target.value);
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    }

    const onEmailGroupCheckedChange = (groupId) => {
        var emailGroupsCopy = emailGroups.map(g => ({ ...g, checked: Boolean(g.checked) }));

        if (emailGroupsCopy.find(x => x.id === groupId).checked) {
            emailGroupsCopy.find(x => x.id === groupId).checked = false;
        }
        else {
            emailGroupsCopy.find(x => x.id === groupId).checked = true;
        }

        setEmailGroups(emailGroupsCopy);
    }

    const isEmailGroupChecked = (groupId) => {
        if (emailGroups.find(x => x.id === groupId).checked) {
            return true;
        }
        else {
            return false;
        }
    }
    
    const handleChange = ev => {
        setNewEmail(ev.target.value);
    };

    const onClearSearchTextClick = () => {
        setGroupSearchText("");
    }

    return (
        <Container maxWidth={false} disableGutters={true}>
            <Typography variant="subtitle1">Hit enter to separate multiple emails or separate with a semicolon</Typography>
            <Autocomplete
                multiple
                id="tags-filled"
                options={sentemail.map((option) => option.email)}
                freeSolo
                onChange={(event, newValue) => {
                    setEmail(newValue);
                    if (newValue.length === 0) {
                        setNewEmail("")
                    }

                }}
                renderTags={(value, getTagProps) =>

                    value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                }

                renderInput={(params) => (
                    <TextField {...params} variant="filled" label="Email" placeholder="Enter Emails" className="AutocompleteEmails" required={true}
                        error={emailError.error}
                        helperText={emailError.error ? emailError.text : null} onChange={handleChange} />
                )}
            />
            {/* <TextField
                id="email"
                label="Email"
                variant="outlined"
                onChange={onEmailChange}
                value={email}
                required
                error={emailError.error}
                helperText={emailError.error ? emailError.text : null}
                style={{width: "75%", marginTop: 5}}
            />  */}
            &nbsp;
            <Button
                color="primary"
                variant="contained"
                style={{ marginTop: ".75em", height: "38px", marginRight: "10px" }}
                onClick={onAddMessageClick}
            >
                {editor != null && editor.length > 0 ? <EditIcon /> : <AddCircleIcon />}&nbsp;
                {editor != null && editor.length > 0 ? "Edit Message" : "Add Message"}
            </Button>
            {
                (currentUser.user.permissions.find(p => p.permissionName === "Send Group Emails") && currentUser.user.permissions.find(p => p.permissionName === "Send Group Emails").value) &&
                <Button
                    color="primary"
                    variant="contained"
                    style={{ marginTop: ".75em", height: "38px" }}
                    onClick={() => { setSelectEmailGroupsOpen(true) }}
                >Add Groups</Button>
            }

            <span className={classes.root}>
                <span className={classes.wrapper}>
                    <Button
                        aria-label="send"
                        color="primary"
                        onClick={onSubmit}
                        disabled={loading}
                        variant="contained"
                        className={buttonClassname}
                        style={{ marginTop: ".75em", height: "38px" }}
                    >
                        {sendError.error ? <ErrorIcon /> : null}
                        {(!sent && !sendError.error) ? <EmailIcon /> : null}
                        {(sent && !sendError.error) ? <CheckIcon /> : null}
                        &nbsp; Send
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </span>
            </span>
            <span>
                <span>
                    <Button
                        aria-label="saveDraft"
                        color="primary"
                        onClick={() => {draftChecks()}}
                        disabled={saveDraftLoading}
                        variant="contained"
                        className={saveDraftButtonClassname}
                        style={{ marginTop: ".75em", height: "38px" }}
                    >
                        {draftError.error ? <ErrorIcon /> : null}
                        {(!savedDraft && !draftError.error) ? <Drafts /> : null}
                        {(savedDraft && !draftError.error) ? <CheckIcon /> : null}
                        &nbsp; Save Draft
                    </Button>
                    {saveDraftLoading && <CircularProgress size={24} className={classes.buttonDraftProgress} />}
                </span>
            </span>
            <Dialog open={draftOpen} onClose={handleDraftClose}>
                        <DialogTitle id="Saved Draft Confirmation Alert">Your email has been saved as a draft.</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Please go to the manage draft page to see your draft
                            </DialogContentText>
                            <DialogActions>
                                <Button 
                                    onClick = {() => {handleDraftClose(); props.setDocumentNames(""); props.setDrawings(0); }}
                                    color="primary">
                                        OK
                                </Button>
                            </DialogActions>
                        </DialogContent>
        </Dialog>            
            <br />
            {sendError.error ? <Typography variant="caption" color="error">{sendError.text}</Typography> : null}
            <Typography variant="subtitle1">Number of Drawings Selected: {drawings.length}</Typography>
            {drawingError.error ? <Typography variant="caption" color="error">{drawingError.text}</Typography> : null}
            <Dialog fullScreen open={open} onClose={handleClose}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            size="large">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Add Message
                    </Typography>
                    </Toolbar>
                </AppBar>
                <div style={{ paddingTop: '10px', paddingLeft: '5px', paddingBottom: '5px', paddingRight: '5px' }}>
                    <TextField
                        variant="outlined"
                        label="Subject"
                        fullWidth
                        value={subject}
                        onChange={onSubjectChange}
                        helperText="Subject saves automatically"
                    />
                </div>
                <RichTextEditorComponent
                    showCharCount={false}
                    toolbarSettings={toolbarSettings}
                    height="100%"
                    style={{ overflow: "hidden" }}
                    value={editor}
                    change={param => {setEditor(param.value); console.log(param)}}
                    fileManagerSettings={FileManagerSettingsModel}
                >
                    <Inject services={[syncfusionToolbar, Link, HtmlEditor, Count, QuickToolbar, Table, Image, FileManager]} />
                </RichTextEditorComponent>
            </Dialog>
            <Dialog fullScreen open={selectEmailGroupsOpen} onClose={() => { setSelectEmailGroupsOpen(false); setGroupSearchText(""); }}>
                <AppBar className={classes.apBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                            onClick={() => { setSelectEmailGroupsOpen(false) }}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Select Email Groups
                    </Typography>
                    </Toolbar>
                </AppBar>
                <div style={{ paddingTop: '70px', paddingLeft: '5px', paddingBottom: '5px', paddingRight: '5px' }}>
                    <TextField
                        value={groupSearchText}
                        onChange={(event) => { setGroupSearchText(event.target.value) }}
                        label="Search"
                        InputProps={{
                            endAdornment:
                              <InputAdornment position="end">
                                <IconButton onClick={onClearSearchTextClick} style={{ outline: 'none' }} size="large">
                                  <CloseIcon opacity={groupSearchText.length > 0 ? 1 : 0} />
                                </IconButton>
                              </InputAdornment>
                          }}
                    />
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {emailGroups && emailGroups.map((item, index) => {
                            return (
                                item.groupName.toLowerCase().includes(groupSearchText.toLowerCase()) &&
                                <Grid item xs={2} sm={4} md={4}>
                                    <FormControlLabel
                                        key={index}
                                        value={item.id}
                                        label={item.groupName}
                                        control={
                                            <Checkbox
                                                checked={isEmailGroupChecked(item.id)}
                                                onChange={() => onEmailGroupCheckedChange(item.id)}
                                                color="primary"
                                            />
                                        }
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </div>
            </Dialog>
            <Snackbar
                open={SnackBarOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={AlertColor === "success" ? "success" : (AlertColor === "info" ? "info" : "error")}>
                    {SnackBarText}
                </Alert>
            </Snackbar>
        </Container>
    );
}
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import clsx from 'clsx';
import Axios from "axios";
import gql from 'graphql-tag';
import "../miscellaneous/File.css";
import { useSelector } from "react-redux";
import Chip from '@mui/material/Chip';
import { useQuery } from '@apollo/client';
import { getBaseURL } from "../../baseURL";
import MuiAlert from '@mui/material/Alert';
import Drafts from '@mui/icons-material/Drafts';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import Toolbar from '@mui/material/Toolbar';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState, useEffect } from "react";
import Typography from '@mui/material/Typography';
import { green, red } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Autocomplete from '@mui/material/Autocomplete';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContentText from '@mui/material/DialogContentText';
import { Container, Snackbar, TextField, Button, FormControlLabel, Checkbox, Grid, InputAdornment } from "@mui/material";
import { RichTextEditorComponent, Toolbar as syncfusionToolbar, Inject, Link, HtmlEditor, Count, QuickToolbar, Table, Image, FileManager } from '@syncfusion/ej2-react-richtexteditor';


export const EmailTemplate= (props) =>{

    const useStyles = makeStyles(theme => ({
        root: {
            //display: 'flex',
            //alignItems: 'center',
            //border: '1px solid black',
        },
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative'
        },
        buttonSuccess: {
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[700]
            }
        },
        buttonError: {
            backgroundColor: red[500],
            '&:hover': {
                backgroundColor: red[700]
            }
        },
        fabProgress: {
            color: green[500],
            position: 'absolute',
            top: -6,
            left: -6,
            zIndex: 1
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -5,
            marginLeft: -12
        },
        buttonDraftProgress: {
            color: green[500],
            position: 'absolute',
            marginTop: 17,
            marginLeft: -86
        },
        appBar: {
            position: 'relative'
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1
        }
    }));

    
    const classes = useStyles();
    const [subject, setSubject] = useState("ATTENTION REQUIRED: Rejection of Your Scan Request");
    const [email, setEmail] = useState([props.toUser]);
    const [editor, setEditor] = useState();
    const [sent, setSent] = useState(false);
    const [NewEmail, setNewEmail] = useState(props.toUser);
    const [drawings, setDrawings] = useState(props.drawings);
    const [loading, setLoading] = useState(false);
    const [sentemail, setSentEmails] = useState([]);
    const [AlertColor, setAlertColor] = useState("");
    const [emailGroups, setEmailGroups] = useState([]);
    const [savedDraft, setSavedDraft] = useState(false);
    const [SnackBarText, setSnackBarText] = useState("");
    const [SnackBarOpen, setSnackBarOpen] = useState(false);
    const [saveChangesOpen, setSaveChangesOpen] = useState(false);
    const [saveDraftLoading, setSaveDraftLoading] = useState(false);
    const [sendError, setSendError] = useState({ error: false, text: "" });
    const [emailError, setEmailError] = useState({ error: false, text: "" });
    const [draftError, setDraftError] = useState({ error: false, text: "" });
    const [selectEmailGroupsOpen, setSelectEmailGroupsOpen] = useState(false);  
    const [drawingError, setDrawingError] = useState({ error: false, text: "" });
    const [groupSearchText, setGroupSearchText] = useState("");
    const currentUser = useSelector(state => state.auth);
    const config = {
        headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken },
        timeout: 240000
    };

    const FileManagerSettingsModel = {
        enable: true,
        path: '/',
        ajaxSettings: {
            url: `${getBaseURL()}/files/FileOperations?userEmail=${currentUser.user.email}`,
            uploadUrl: `${getBaseURL()}/Files/Upload?userEmail=${currentUser.user.email}`,
        },

        contextMenuSettings: {
            visible: true,
            file: ['Delete', '|', 'Details'],
            folder: ['Delete', '|', 'Details'],
            layout: ['SortBy', 'View', 'Refresh', '|', 'Upload', '|', 'Details']
        },
        navigationPaneSettings: {
            visible: true,
            items: [
                'Upload', 'Delete', 'Download',
                'SortBy', 'Refresh', 'Selection', 'View', 'Details'
            ]
        },
        toolbarSettings: { visible: true, items: ['Upload', 'SortBy', 'Refresh', 'View', 'Delete'] },
        uploadSettings: { allowedExtensions: ".doc, .docx, .pdf, .xls, .xlsx" }
    }

    const toolbarItems = ['Bold', 'Italic', 'Underline', 'StrikeThrough',
    'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
    'LowerCase', 'UpperCase', '|',
    'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
    'Outdent', 'Indent', 'SuperScript', 'SubScript', '|',
    'CreateTable', 'Image', 'FileManager', 'CreateLink', '|', 'ClearFormat', 'Print',
    '|', 'Undo', 'Redo'];

    const toolbarSettings = { items: toolbarItems }    

  //ADDED BY BH 1/23
    const onEmailChange = event => {
        if (sent) {
            setSent(false);
        }
        if(savedDraft) {
            setSavedDraft(false);
        }

        setEmail(event.target.value);


        if (event.target.value.length > 0) {
            setEmailError({ error: false, text: "" });
        }
    }

    const onSubmit = () =>{
        var isError = false;

        var userEmail = [props.toUser];
        //TO: DO Fix Check 
        if (email.length < 1 && NewEmail.length < 1) { 
            setEmailError({ error: true, text: "Enter email address" });
            isError = true;
        } else {
            setEmailError({ error: false, text: "" });
        }

        if (!isError) { 
            
            setLoading(true);
            setEmailError({ error: false, text: "" });
            setSent(false);
            setSendError({ error: false, text: "" });
            const Message = {
                toEmails: userEmail,
                Email: props.toUser,
                CustomMessage: editor,
                //added drawing number in subject line
                Subject: subject+"-"+props.setDrawings,
                CurrentUserEmail: currentUser.user.email,
                Drawings: drawings,
            }
            
            Axios.post(`${getBaseURL()}/admin/managescanrequests/emailUser`, Message, config)
                .then((response) => { 
                    setSent(true);
                    setLoading(false);
                    setTimeout(()=>{
                        handleClose();
                        setSent(false);
                    },1000);
                    
                })
                .catch((error) => { 
                    setLoading(false);
                    setSendError({ error: true, text: "Error while sending emails!" });
                    console.log("the Error is: " + error);
                    // if(error.response != undefined)
                    //     setSendError({ error: true, text: error.response.data.message })
                });
                
        }
    }

    const buttonClassname = clsx({
        [classes.buttonSuccess]: sent,
        [classes.buttonError]: sendError.error,
    });
    
    
    
    const onSubjectChange = (event) => {
        setSubject(event.target.value);
    }

    const handleClose = () =>{
        setEmail([""]);
        setEditor("");
        props.setOpen(false);
    }

    //handles the passing of the values to trigger the approveDeny function in the paren
    const handleSend = () => {        
        onSubmit();
        props.onClick(props.ID, false);
    }

    const handleChange = ev => {
        setNewEmail(ev.target.value);
    };

    return(
        <div>
        <Dialog fullScreen open={props.open} onClose={handleClose}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            size="large">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Add Message
                    </Typography>
                    </Toolbar>
                </AppBar>
                <div style={{ paddingTop: '20px', paddingLeft: '5px', paddingBottom: '5px', paddingRight: '5px' }}>
                    <TextField
                        variant="outlined"
                        label="TO:"
                        fullWidth
                        value={props.toUser}
                        // id="tags-filled"
                        // options={sentemail.map((option) => option.email)}
                        // freeSolo
                        // onChange={(event, newValue) => {
                        //     setHasEmailChanged(true);
                        //     setIsAlteredDraft(true);
                        //     setEmail(newValue);
                        //     if (newValue.length === 0) {
                        //         setNewEmail("")
                        //     }

                        // }}
                        // renderTags={(value, getTagProps) =>

                        //     value.map((option, index) => (
                        //         <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                        //     ))
                        // }

                        // renderInput={(params) => (
                        //     <TextField {...params} variant="filled" label="Email" placeholder="Enter Emails" className="AutocompleteEmails" required={true} required
                        //         error={emailError.error}
                        //         helperText={emailError.error ? emailError.text : null} onChange={handleChange} />
                        // )}
                    />
                </div>
                <div style={{paddingTop: '20px', paddingLeft: '5px', paddingBottom: '5px', paddingRight: '5px' }}>
                    <TextField
                            variant="outlined"
                            label="Subject"
                            fullWidth
                            //added drawing number in subject line
                            value={subject+"-"+props.setDrawings}
                            onChange={onSubjectChange}
                            helperText="Subject saves automatically"
                    />
                </div>

                <RichTextEditorComponent
                    showCharCount={false}
                    toolbarSettings={toolbarSettings}
                    height="100%"
                    style={{ overflow: "hidden" }}
                    value={editor}
                    change={param => {setEditor(param.value); console.log(param)}}
                    fileManagerSettings={FileManagerSettingsModel}
                >
                    <Inject services={[syncfusionToolbar, Link, HtmlEditor, Count, QuickToolbar, Table, Image, FileManager]} />
                </RichTextEditorComponent>
                <Button
                        aria-label="send"
                        color="primary"
                        onClick={handleSend}
                        disabled={loading}
                        variant="contained"
                        className={buttonClassname}
                        style={{ marginTop: ".50em", marginBottom: ".50em", marginLeft: "1em" ,  width: "105px" , height: "48px" }}
                    >
                        {sendError.error ? <ErrorIcon /> : null}
                        {(!sent && !sendError.error) ? <EmailIcon /> : null}
                        {(sent && !sendError.error) ? <CheckIcon /> : null}
                        &nbsp; Send
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
                    
            </Dialog>
        </div>        
    );
}
import React, { useState,  Component ,useEffect} from "react";
import { Container, TextField, Button, Icon, TablePagination, Snackbar } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import { green, red } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper'
import { useSelector } from "react-redux";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Axios from "axios";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import MuiAlert from '@mui/material/Alert';
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { TablePaginationActions } from '../../actions/TablePaginationActions';
import { getPdfFiles } from '../../actions/actions';
import { getBaseURL } from "../../baseURL";
import { useHistory } from "react-router-dom";
import ErrorIcon from '@mui/icons-material/Error';

export const DrawingExport = () => {
  const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(2, 2),
    },
    paperSpacing: {
      paddingTop: "30px",
    },
    wrapper: {
      position: 'relative',
    },
    Fieldsspace: {
      marginRight: '209px',
    },
    FieldsspaceForButton: {
      marginRight: '376px',
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    buttonError: {
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[700],
      },
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -16,
      marginLeft: 229,
    },
    buttonProgressSearch: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -16,
      marginLeft: -272,
    },
    paperSpacing: {
      paddingTop: "30px",
    },
    helperText: {
      marginLeft: 0,
    },
    buttonProgressDownload: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -10,
      marginLeft: -12,
    },
  }));

  const classes = useStyles();
  const [drawings, setDrawings] = useState([]);
  const [drawingError, setDrawingError] = useState({ Error: false, Text: "" });
  const [enddrawingError, setendDrawingError] = useState({ Error: false, Text: "" });
  const [loading, setLoading] = useState(false);
  const [Excelloading, setExcelloading] = useState(false);
  const currentUser = useSelector(state => state.auth);
  const [latest, setLatest] = useState(true);
  const [SnackBarOpen, setSnackBarOpen] = useState(false);
  const [SnackBarText, setSnackBarText] = useState("");
  const [showmessage, setshowmessage] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [startRange, setstartRange] = useState("");
  const [endRange, setendRange] = useState("");
  const [AlertColor, setAlertColor] = useState("");
  const [IsExporDrawingFeatureEnable, setIsExporDrawingFeatureEnable] = useState(false);
  const [exportDrawingAccess, setExportDrawingAccess] = useState(false);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, drawings.length - page * rowsPerPage);

  const config = {
    headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken }
  };

  let history = useHistory();

  const onSearchTextChange = event => {
    setstartRange(event.target.value);

    if (startRange) {
      setDrawingError({ Error: false, Text: "" });
    }
  }
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarOpen(false);
  }
  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const onSearchEndTextChange = event => {

    setendRange(event.target.value);

    if (endRange) {
      setendDrawingError({ Error: false, Text: "" });
    }
  }
  const onLatestChanged = event => {
    setLatest(event.target.checked);
  }

  useEffect(() => {
    try {
        if (currentUser.user.permissions.find(p => p.permissionName === "Export Drawing").value === true) {
          setExportDrawingAccess(true);
        }
        else {
          setExportDrawingAccess(false);
        }
    }
    catch (error) {
      setExportDrawingAccess(false);
    }
}, []);

  const onSubmit = () => {
    setDrawings([]);

    if (startRange && endRange) {

      const search = {
        StartRange: startRange,
        EndRange: endRange,
        LatestRev: latest,
      }
      setLoading(true);

      Axios.post(`${getBaseURL()}/documents/DrawingSearchByRange`, search, config)
        .then((response) => {
          setDrawings(response.data);
          if (response.data.length > 0) {
            setshowmessage(false)
          } else {
            setshowmessage(true)
          }
          setLoading(false);
          setPage(0);
        })
        .catch((error) => {
          setDrawingError({ Error: true, Text: error.response.data.message });
          setLoading(false);
          setPage(0);
        })
    }
    if (!startRange) {
      setDrawingError({ Error: true, Text: "Enter a Start Range" });
      setDrawings([]);
    }
    if (!endRange) {
      setendDrawingError({ Error: true, Text: "Enter a End Range" });
      setDrawings([]);
    }
  }

  const ExportExcel = () => {

    if (startRange && endRange) {

      const search = {
        StartRange: startRange,
        EndRange: endRange,
        LatestRev: latest,
      }

      setExcelloading(true);

      Axios.post(`${getBaseURL()}/documents/ExportExcel`, search, config)
        .then((response) => {
          if (response.data.byteArray != null) {
            var byteArray = base64ToArrayBuffer(response.data.byteArray);
            setExcelloading(false)
            const blob = new Blob([byteArray], { type: "application/vnd.ms-excel" });
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            var url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = "DrawingRangeData.csv";
            a.click();
            window.URL.revokeObjectURL(url);
          }
          if (response.data.message === "No Drawnings found") {
            setExcelloading(false);
            setSnackBarOpen(true)
            setSnackBarText("No drawings Found Please try with another range ")
          }
        })
        .catch((error) => {
          setDrawingError({ Error: true, Text: error.response.data.message });
          setExcelloading(false);
          setSnackBarOpen(true)
          setSnackBarText(error.response.data.message)
        })
    }
    if (!startRange) {
      setDrawingError({ Error: true, Text: "Enter a Start Range" });
      setDrawings([]);
    }
    if (!endRange) {
      setendDrawingError({ Error: true, Text: "Enter a End Range" });
      setDrawings([]);
    }
  }

  // Functions for getting byte array back as PDF
  function base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);

    for (var i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }

    return bytes;
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const handleDownloadClick = (drawingId) => {
    var drawingsArray = [...drawings];
    drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = true);
    setDrawings(drawingsArray);

    var selectedFile = [...drawings].filter(x => x.id === drawingId);
    selectedFile.find(x => x.id === drawingId).isChecked = true;

    getPdfFiles(selectedFile, currentUser.jwtIdToken.idToken)
      .then((response) => {
        drawingsArray = [...drawings];
        drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isChecked = false);
        drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = false);
        setDrawings(drawingsArray);
      });
  }
  useEffect(() => {
   
    setIsExporDrawingFeatureEnable(currentUser.featurecontrolList.find(x => x.name == "Export Drawings").grantByAdmin)

  }, []);


  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  }

  return (
    <Container maxWidth={false} disableGutters={true}>
      {exportDrawingAccess ?
      <div>
      <Typography variant="h4" gutterBottom>Drawing Search - Range</Typography>
      <Paper className={classes.root}>

        <TextField
          id="StartRange"
          label="Start Range"
          value={startRange}
          onChange={onSearchTextChange}
          variant="outlined"
          margin="normal"
          className={classes.Fieldsspace}
          helperText={drawingError.Error ? drawingError.Text : null}
          error={drawingError.Error}
          FormHelperTextProps={{ classes: { root: classes.helperText } }}
          onKeyPress={handleEnter}
        />

        <TextField
          id="EndRange"
          label="End Range"
          value={endRange}
          onChange={onSearchEndTextChange}
          variant="outlined"
          margin="normal"

          helperText={enddrawingError.Error ? enddrawingError.Text : null}
          error={enddrawingError.Error}
          FormHelperTextProps={{ classes: { root: classes.helperText } }}
          onKeyPress={handleEnter}
        />
        <FormGroup row>
          <FormControlLabel control={<Checkbox checked={latest} color="primary" onChange={onLatestChanged} />} label="Latest Revision Only" />
        </FormGroup>

        <span className={classes.wrapper}>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={loading}
            className={classes.FieldsspaceForButton}
          >
            <SearchIcon />&nbsp;Search
                  </Button>

          {loading && <CircularProgress size={24} className={classes.buttonProgressSearch} />}


          <Button
            variant="contained"
            color="primary"
            onClick={ExportExcel}
            disabled={Excelloading}
          >
            Export To Excel
                  </Button>

          {Excelloading && <CircularProgress size={24} className={classes.buttonProgress} />}


        </span>

        <Snackbar
          open={SnackBarOpen}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleSnackbarClose} severity={AlertColor === "success" ? "success" : "error"}>
            {SnackBarText}
          </Alert>
        </Snackbar>
      </Paper>
      <br />
      <br />

      {loading && <Typography variant="h4" gutterBottom>please wait while we’re fetching all drawings</Typography>}
      {(drawings.length && !loading && !drawingError.Error) > 0 ?
        <Paper className={classes.paperSpacing} p={5}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Drawing Number</TableCell>
                <TableCell>Revision</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Model</TableCell>
                <TableCell>Image</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {drawings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell>{item.drawingNumber}</TableCell>
                    <TableCell>{item.revision}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>{item.model}</TableCell>
                    <TableCell>{item.image}</TableCell>
                    {(item.classificationLevel <= currentUser.user.classification.classLevel || item.hasSpecialAccess) ?
                      (item.documentCount && item.documentCount > 0) ?
                        <TableCell>
                          <span className={classes.wrapper}>
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => handleDownloadClick(item.id)}
                              disabled={item.isDownloading}
                            >
                              Download
                                  </Button>
                            {item.isDownloading && <CircularProgress size={24} className={classes.buttonProgressDownload} />}
                          </span>
                        </TableCell>
                        :
                        <TableCell>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => history.push("/scanrequest/" + item.drawingNumber)}
                          >
                            Scan Request
                                  </Button>
                        </TableCell>
                      : <TableCell>
                        <Button color="primary" onClick={() => history.push("/specialaccessrequest/" + item.drawingNumber)}>Request Access</Button>
                      </TableCell>
                    }
                    {}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={drawings.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
        : (showmessage && <Typography variant="h5" gutterBottom style={{ paddingTop: '10px' }}>Drawings Not Found</Typography>)}
    </div>
        : <Paper>
          <Typography variant="h6">
            <ErrorIcon />&nbsp;Access Denied
          </Typography>
        </Paper>}
    </Container>
  );
}
import gql from 'graphql-tag';
import { useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import ErrorIcon from '@mui/icons-material/Error';
import React, { useState, useEffect } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import makeStyles from '@mui/styles/makeStyles';
import { toast, ToastContainer } from 'react-toastify';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import {
    Container,
    Paper,
    Typography, 
    Grid, 
    Button, 
    Dialog, 
    DialogContent, 
    DialogActions, 
    DialogTitle, 
    TextField, 
    List, 
    ListItem, 
    ListItemText, 
    ListItemSecondaryAction, 
    IconButton,
    CircularProgress
 } from '@mui/material';

export const ManageEmailGroups = () => {
    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(1, 2),
            paddingBottom: "20px",
        }
    }));

    const GET_EMAIL_GROUPS = gql`
      query emailGroups($id: Int, $groupName: String) {
         emailGroups(id: $id, groupName: $groupName) {
            id,
            groupName
          }
      }
    `;

    const GET_EMAIL_GROUP_MEMBERS = gql`
      query emailGroupMembers($groupId: Int) {
          emailGroupMembers(groupId: $groupId) {
              id,
              email
          }
      }
    `;

    const ADD_EMAIL_GROUP = gql`
      mutation addEmailGroup($groupName: String) {
          addEmailGroup(groupName: $groupName) {
              id
          }
      }
    `;

    const ADD_EMAIL_GROUP_MEMBER = gql`
      mutation addEmailGroupMember($email: String, $groupId: Int!) {
          addEmailGroupMember(email: $email, groupId: $groupId) {
              id
          }
      }
    `;

    const DELETE_EMAIL_GROUP = gql`
      mutation deleteEmailGroup($groupId: Int!) {
          deleteEmailGroup(groupId: $groupId) {
              groupName
          }
      }
    `;

    const DELETE_EMAIL_GROUP_MEMBER = gql`
      mutation deleteEmailGroupMember($id: Int!) {
          deleteEmailGroupMember(id: $id) {
              email,
              groupId
          }
      }
    `;

    const classes = useStyles();
    const currentUser = useSelector(state => state.auth);
    const config = {
        headers: {'Authorization': "Bearer " + currentUser.jwtIdToken.idToken}
    };

    const[isAdmin, setIsAdmin] = useState(null);
    const [emailGroups, setEmailGroups] = useState([]);
    const [selectedEmailGroup, setSelectedEmailGroup] = useState();
    const [selectedGroupMembers, setSelectedGroupMembers] = useState([]);

    const {refetch} = useQuery(GET_EMAIL_GROUPS, {notifyOnNetworkStatusChange: true, onCompleted: data => {setEmailGroups(data.emailGroups)}});
    const [getGroupMembers, getGroupMembersResult] = useLazyQuery(GET_EMAIL_GROUP_MEMBERS, {variables: {groupId: selectedEmailGroup}});
    const [addGroupDialogOpen, setAddGroupDialogOpen] = useState(false);
    const [addGroupMemberDialogOpen, setAddGroupMemberDialogOpen] = useState(false);
    const [addGroupText, setAddGroupText] = useState("");
    const [addGroupResut, setAddGroupResult] = useState();
    const [addEmailGroup] = useMutation(ADD_EMAIL_GROUP);
    const [addEmailGroupMember] = useMutation(ADD_EMAIL_GROUP_MEMBER);
    const [deleteEmailGroup] = useMutation(DELETE_EMAIL_GROUP);
    const [deleteEmailGroupMember, deleteEmailGroupMemberResult] = useMutation(DELETE_EMAIL_GROUP_MEMBER);
    const [emailGroupMemberText, setEmailGroupMemberText] = useState("");
    const [addGroupMemberError, setAddGroupMemberError] = useState({error: false, text: ""});
    const [snackbarStatus, setSnackbarStatus] = useState({open: false, severity: "", message: ""});

    const onGroupSelection = (groupId) => {
        setSelectedEmailGroup(groupId);
    }

    useEffect(() => {
        if(isAdmin === null) {
            try {
                if(currentUser.user.permissions.find(p => p.permissionName === "System Admin").value === true) {
                    setIsAdmin(true);
                }
                else {
                    checkManageEmailGroupPermission();
                }
            }
            catch(error) {
                checkManageEmailGroupPermission();
            }
    
            if(!isAdmin) {
                try {
                    if(currentUser.user.permissions.find(p => p.permissionName === "User Admin").value === true) {
                        setIsAdmin(true);
                    }
                    else {
                        checkManageEmailGroupPermission();
                    }
                }
                catch(error) {
                    checkManageEmailGroupPermission();
                }
            }
        }

        if(selectedEmailGroup) {
            getGroupMembers();
        }
    }, [selectedEmailGroup])

    useEffect(() => {
    }, [selectedEmailGroup]);

    useEffect(() => {
        if(getGroupMembersResult.data) {
            setSelectedGroupMembers(getGroupMembersResult.data.emailGroupMembers);
        }
    }, [getGroupMembersResult])

    const onAddGroupTextUpdate = (e) => {
        setAddGroupText(e.target.value);
    }

    const onAddGroupMemberUpdate = (e) => {
        setEmailGroupMemberText(e.target.value);
    }

    const checkManageEmailGroupPermission = () => {
        if(currentUser.user.permissions.find(p => p.permissionName === "Manage Group Emails") != undefined) {
            if(currentUser.user.permissions.find(p => p.permissionName === "Manage Group Emails").value === true) {
                setIsAdmin(true);
            }
            else
                setIsAdmin(false);
        }
        else
            setIsAdmin(false);
    }

    const onGroupDelete = (groupId) => {
        deleteEmailGroup({variables: {groupId: groupId}}, )
        .then(x => {
            refetch(); 
            if(selectedEmailGroup === groupId) { 
                setSelectedEmailGroup();
            }
            
            toast.success(`Group "${x.data.deleteEmailGroup.groupName}" deleted`, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                hideProgressBar: false
            });
        })
        .catch(x => {
            toast.error(`A problem occured deleting group`, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                hideProgressBar: false
            });
        })
    }

    const getGroupName = (groupId) => {
        return emailGroups.find(x => x.id === groupId);
    }

    const onGroupMemmberDelete = (id) => {
        deleteEmailGroupMember({variables: {id: id}})
        .then(x => {
            getGroupMembersResult.refetch();

            toast.success(`Group memmber "${x.data.deleteEmailGroupMember.email}" deleted from group "${getGroupName(x.data.deleteEmailGroupMember.groupId).groupName}"`, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                hideProgressBar: false
            });
        })
        .catch(x => {
            toast.error(`A problem occured deleting member from group`, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                hideProgressBar: false
            });
        })
    }

    const isGroupDeleteLoading = (groupId) => {
        try {
            if(emailGroups.find(x => x.groupId === groupId).deleteLoading){
                return true;
            }
        }
        catch {
            return false;
        }
    }

    return (
        <Container maxWidth={false} disableGutters={true}>
            {isAdmin ?
                <React.Fragment>
                    <Typography variant='h4'>Manage Email Groups</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Grid container justifyContent="space-between">
                                <Grid item><Typography variant='h5'>Groups</Typography></Grid>
                                <Grid item>
                                    <Button
                                        color="primary"
                                        onClick={() => setAddGroupDialogOpen(true)}
                                    >
                                        <AddIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                            <List dense>
                                {
                                    emailGroups.map((item, index) => {
                                        return (
                                            <ListItem button key={index} style={{ backgroundColor: selectedEmailGroup === item.id && '#E8E8E8' }}>
                                                <ListItemText onClick={() => { onGroupSelection(item.id) }}>{item.groupName}</ListItemText>
                                                <ListItemSecondaryAction>
                                                    {
                                                        isGroupDeleteLoading(item.id) ?
                                                            <CircularProgress color="primary" />
                                                            :
                                                            <IconButton edge="end" onClick={() => { onGroupDelete(item.id); }} size="large">
                                                                <DeleteIcon />
                                                            </IconButton>
                                                    }

                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        );
                                    })
                                }
                            </List>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container justifyContent="space-between">
                                <Grid item><Typography variant='h5'>Group Members</Typography></Grid>
                                <Grid item>
                                    <Button
                                        disabled={(selectedEmailGroup === null || selectedEmailGroup === undefined)}
                                        color="primary"
                                        onClick={() => setAddGroupMemberDialogOpen(true)}
                                    >
                                        <AddIcon />
                                    </Button>
                                </Grid>
                            </Grid>

                            {(selectedEmailGroup && getGroupMembersResult.called && getGroupMembersResult.loading) &&
                                <Typography variant="body1">Loading...</Typography>
                            }
                            {(selectedEmailGroup && getGroupMembersResult.called && !getGroupMembersResult.loading && selectedGroupMembers.length === 0) &&
                                <Typography variant="body1">No Group Members Found</Typography>
                            }
                            {(selectedEmailGroup && getGroupMembersResult.called && !getGroupMembersResult.loading && selectedGroupMembers.length > 0) &&
                                <List dense>
                                    {
                                        selectedGroupMembers.map((item, index) => {
                                            return (
                                                <ListItem button key={index}>
                                                    <ListItemText>{item.email}</ListItemText>
                                                    <ListItemSecondaryAction>
                                                        <IconButton edge="end" onClick={() => { onGroupMemmberDelete(item.id) }} size="large">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            );
                                        })
                                    }
                                </List>
                            }
                        </Grid>
                    </Grid>
                    <Dialog open={addGroupDialogOpen} onClose={() => { setAddGroupDialogOpen(false) }}>
                        <DialogTitle>Add Group</DialogTitle>
                        <DialogContent>
                            <TextField
                                color="primary"
                                value={addGroupText}
                                onChange={onAddGroupTextUpdate}
                            ></TextField>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color="primary"
                                onClick={() => { setAddGroupDialogOpen(false); }}
                            >Cancel</Button>
                            <Button
                                color="primary"
                                onClick={() => { addEmailGroup({ variables: { groupName: addGroupText } }).then(x => { setAddGroupDialogOpen(false); refetch(); setAddGroupText(""); }) }}
                            >Add Group</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={addGroupMemberDialogOpen} onClose={() => { setAddGroupMemberDialogOpen(false) }}>
                        <DialogTitle>Add Group Member</DialogTitle>
                        <DialogContent>
                            <TextField
                                color="primary"
                                value={emailGroupMemberText}
                                onChange={onAddGroupMemberUpdate}
                                error={addGroupMemberError.error}
                                helperText={addGroupMemberError.text}
                            ></TextField>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color="primary"
                                onClick={() => { setAddGroupMemberDialogOpen(false); }}
                            >Cancel</Button>
                            <Button
                                color="primary"
                                onClick={() => { addEmailGroupMember({ variables: { groupId: selectedEmailGroup, email: emailGroupMemberText } }).then(x => { setAddGroupMemberDialogOpen(false); getGroupMembersResult.refetch(); setEmailGroupMemberText(""); }) }}
                            >Add Member</Button>
                        </DialogActions>
                    </Dialog>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                    />
                </React.Fragment>
                :
                <Paper className={classes.root}>
                    <Typography variant="h6">
                        <ErrorIcon />&nbsp;Access Denied
                    </Typography>
                </Paper>
            }
        </Container>
    );
}
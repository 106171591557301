import Axios from "axios";
import NavMenu from './NavMenu';
import classNames from 'classnames';
import { getBaseURL } from "../../baseURL";
import { ApolloProvider } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { GraphQlClient } from '../../apolloClient';
import Typography from '@mui/material/Typography';
import { RequestAccess } from "../auth/RequestAccess";
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from '@mui/material/styles';
import { ThemeProvider as FluentUiThemeProvider } from '@fluentui/react-theme-provider';

import withStyles from '@mui/styles/withStyles';

const drawerWidth = 360;
const theme = createTheme(adaptV4Theme({
	typography: {
		useNextVariants: true,
		fontFamily: "Arial, Helvetica, sans-serif"
	},
	palette: {
		primary: {
			main: "#140a9a"
		},
		secondary: {
			main: "#818386"
		}
	},
	overrides: {
		MuiTableRow: {
			root: { //for the body
				height: "100%"
			},
			head: { //for the head
				height: "100%"
			}
		}
	},
	drawerWidth: drawerWidth
}));


const styles = () => ({
	root: {
		display: 'flex',
		flexGrow: 1
	},
	main: {
		display: 'block'
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		marginLeft: 0
	},
	contentShift: {
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		"&::before": {
			content: `''`,
			background: "#0006",
			position: "fixed",
			top: "0",
			width: "100%",
			height: "100%",
			right: "0",
			zIndex: 9
		}
		//	marginLeft: drawerWidth - 150,
	},


	contentPaper: {
		...theme.mixins.gutters(),
		marginTop: 50,
		padding: theme.spacing(2)
	},
	grow: {
		flexGrow: 1
	},
	menuButton: {
		marginLeft: -12,
		marginRight: 20
	},
	appBar: {
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	cogContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row',
		flexWrap: 'wrap'
	}
});

const fluentUiTheme = {
		palette: {
			themePrimary: '#140a9a',
			themeLighterAlt: '#f2f1fb',
			themeLighter: '#cdcbef',
			themeLight: '#a5a1e0',
			themeTertiary: '#5c54c2',
			themeSecondary: '#261da5',
			themeDarkAlt: '#11088a',
			themeDark: '#0e0774',
			themeDarker: '#0b0556',
			neutralLighterAlt: '#f3f3f3',
			neutralLighter: '#efefef',
			neutralLight: '#e5e5e5',
			neutralQuaternaryAlt: '#d6d6d6',
			neutralQuaternary: '#cccccc',
			neutralTertiaryAlt: '#c4c4c4',
			neutralTertiary: '#595959',
			neutralSecondary: '#373737',
			neutralPrimaryAlt: '#2f2f2f',
			neutralPrimary: '#000000',
			neutralDark: '#151515',
			black: '#0b0b0b',
			white: '#fafafa'
		}
	};

const Layout = ({ children, classes}) => {
	const currentUser = useSelector(state => state.auth);
	const [isOpen, setOpen] = useState(false);
	const handleSetOpen = (open) => setOpen(open);

	const dispatch = useDispatch();

	const config = {
		headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken }
	};

	const [userAccess, setUserAccess] = useState(false);
	const [loadedUser, setLoadedUser] = useState(false);

	const [EdgeBrowser, setEdgeBrowser] = useState(false);
	const [loadUserError, setLoadUserError] = useState(false);
	const [featureControlList, setfeatureControlList] = useState([]);
	const [IsBrowserFeatureEnable, setIsBrowserFeature] = useState(false);
	const [currentUserWithPermissions, setCurrentUserWithPermissions] = useState({});
	
	useEffect(() => {
		setEdgeBrowser(window.navigator.userAgent.indexOf('Edg') != -1)

		GetFeatureControl()
		if ((!loadedUser && !currentUser.user.guid && !loadUserError)) {
			Axios.get(`${getBaseURL()}/users/login`, config)
				.then((response) => {
					dispatch({ type: "CURRENT_USER", payload: response.data });
					var currentUserWPerm = {
						permissions: response.data.permissions,
						guid: currentUser.user.guid
					}

					setCurrentUserWithPermissions(currentUserWPerm);

					try {
						if (response.data.permissions.find(p => p.permissionName === "Access State")) {
							if (response.data.permissions.find(p => p.permissionName === "Access State").value === true) {
								setUserAccess(true);
								setLoadedUser(true);
								setLoadUserError(false);

							}
							else {
								setUserAccess(false);
								setLoadedUser(true);
								setLoadUserError(false);
							}
						}
						else {
							setUserAccess(null);
							setLoadedUser(true);
							setLoadUserError(false);
						}
					}
					catch {
						setUserAccess(null);
						setLoadedUser(true);
						setLoadUserError(false);
					}

				})
				.catch((error) => {
					console.log("caught loading user error");
					setLoadUserError(true);
					setLoadedUser(false);

				})
		}
	}, []);
	const GetFeatureControl = () => {
		Axios.get(`${getBaseURL()}/admin/getfeaturecontrol`, config)
			.then(response => {
				dispatch({ type: "FEATURE_CONTROL_LIST", featurecontrollist: response.data });
				setfeatureControlList(response.data)
				setIsBrowserFeature(response.data.find(x => x.name == "Browser Message").grantByAdmin);


			})
			.catch(error => {
				console.log(error)
			})

	}
	return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
				<FluentUiThemeProvider theme={fluentUiTheme}>
					<span>
						<NavMenu isOpen={isOpen} handleSetOpen={handleSetOpen} currentUser={currentUserWithPermissions} hasAccess={userAccess} FeatureControlList={featureControlList} hasFeaturerbrowser={IsBrowserFeatureEnable} hasEdgeBrowser={EdgeBrowser} />

						{(loadedUser && userAccess && IsBrowserFeatureEnable == false) ?
							<ApolloProvider client={GraphQlClient(currentUser)}>
								<main
									className={classNames(classes.content, classes.main, {
										[classes.contentShift]: isOpen,
									})}
								>
									<div style={{ marginTop: "60px", marginLeft: "10px", marginRight: "0" }}>
										{children}
									</div>

								</main>
							</ApolloProvider> :
							(loadedUser && userAccess && IsBrowserFeatureEnable && EdgeBrowser === false) ?
								<div style={{ marginTop: "60px", marginLeft: "0", marginRight: "0" }}>
									<Typography variant="body1" className={classNames(classes.content, classes.main)}>

										Failed to load. you are not able to access the KTIP please use another browser.

									</Typography>
								</div>
								: (loadedUser && userAccess) && <ApolloProvider client={GraphQlClient(currentUser)}>
									<main
										className={classNames(classes.content, classes.main, {
											[classes.contentShift]: isOpen,
										})}
									>
										<div style={{ marginTop: "60px", marginLeft: "10px", marginRight: "0" }}>
											{children}
										</div>

									</main>
								</ApolloProvider>
						}
						{(loadedUser && (userAccess === undefined || userAccess === null || userAccess === false)) &&
							<div style={{ marginTop: '100px' }}>
								<RequestAccess />
							</div>
						}

						{/* {loadUserError && IsBrowserFeatureEnable && EdgeBrowser===true &&

						
							
						} */}
						{loadUserError &&

							<div style={{ marginTop: "60px", marginLeft: "0", marginRight: "0" }}>
								<Typography variant="body1" className={classNames(classes.content, classes.main)}>

									Failed to load. If you are not in the office, please make sure you are connected to the VPN.

								</Typography>
							</div>}

						{(!loadedUser && !loadUserError) &&
							<div style={{ marginTop: '80px', paddingLeft: '50px', paddingRight: '50px' }}>
								<div className={classes.cogContainer}>
									<div className="cogs" style={{ breakAfter: 'always' }}>
										<i className="fas fa-cog fa-4x fa-spin" data-fa-transform="down-5  right-5"></i>
										<i className="fas fa-cog fa-3x fa-spin" data-fa-transform="down-17 right-3" style={{ animationDirection: 'reverse' }}></i>
										<i className="fas fa-cog fa-5x fa-spin" data-fa-transform="left-7" style={{ animationDirection: 'reverse' }}></i>
									</div>
									<div style={{ width: '100%' }}></div>
									<div style={{ breakAfter: 'always', marginTop: '50px' }}>
										{/* Hang tight. Be right with ya' */}

										Processing........
									</div>
								</div>
							</div>
						}
					</span>
				</FluentUiThemeProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default withStyles(styles, { withTheme: true })(Layout);
import axios from "axios";
import { getBaseURL } from "../baseURL";

export const getPdfFiles = async (files, token, setDrawings) => {
  const promises = [];
  const config = {
    headers: { Authorization: "Bearer " + token },
    timeout: 240000,
  };

  var selectedDocs = files.filter(getCheckedDocs);

  const groupedData = selectedDocs.reduce((acc, obj) => {
    const key = obj.searchedPartNumber;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});

  console.log(groupedData);

  try {
    Object.keys(groupedData).forEach((key) => {
      const spl = groupedData[key];
      const promise = axios
        .post(`${getBaseURL()}/documents/download`, spl, config)
        .then((response) => ({ key, data: response.data }));
      promises.push(promise);
    });

    const responses = await Promise.all(promises);

    responses.forEach(({ key, data }) => {
      const byteArray = base64ToArrayBuffer(data);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      if (key === 'null') {
        key = "MultipleItemInquiry";
      }
      const isSingleFile = selectedDocs.length === 1;
      const fileName = isSingleFile
        ? `${selectedDocs[0].drawingNumber}_${selectedDocs[0].revision}.pdf`
        : `${key}.zip`; //"drawings.zip";

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    });

    setDrawings(responses);
  } catch (error) {
    console.error("Error downloading documents:", error);
    return false;
  }
};
export const ViewPdfFiles = (files, token, setDrawings) => {
  const config = {
    headers: { Authorization: "Bearer " + token },
    timeout: 240000,
  };
  console.log("view files file", files);
  return axios
    .post(`${getBaseURL()}/documents/ViewDrawings/${files[0].id}`, null, config)
    .then((response) => {
      var byteArray = base64ToArrayBuffer(response.data);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(
          blob,
          files.filter((x) => x.isChecked)[0].drawingNumber +
            "_" +
            files.filter((x) => x.isChecked)[0].revision +
            "_VIEW.pdf"
        );
      } else {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download =
          files.filter((x) => x.isChecked)[0].drawingNumber +
          "_" +
          files.filter((x) => x.isChecked)[0].revision +
          "_VIEW.pdf";
        a.click();
        window.URL.revokeObjectURL(url);
      }

      //This will open in new tab in Edge. This will not work until user accounts are fixed though
      // if(window.navigator && window.navigator.msSaveOrOpenBlob) {

      //     window.navigator.msSaveOrOpenBlob(blob, files.filter(x => x.isChecked)[0].drawingNumber + "_" + files.filter(x => x.isChecked)[0].revision + ".pdf");
      // }
      // else {
      //     var a = document.createElement("a");
      //     document.body.appendChild(a);
      //     a.style = "display: none";
      //     var url = window.URL.createObjectURL(blob);
      //   //  window.open(url+"#toolbar=0", "", "resizable=yes, scrollbars=yes, titlebar=yes, width=1024, height=900, top=10, left=10,status=no,toolbar=yes");
      //   window.open(url, "_blank");
      //   //window.location.href=url+"#toolbar=0";
      //     // const pdfWindow = window.open();
      //     // pdfWindow.location.href = url;
      // }
    });
};
function getCheckedDocs(docArray) {
  return docArray.isChecked;
}

// Functions for getting byte array back as PDF
function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);

  for (var i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}
